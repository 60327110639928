import type { TElement } from '@udecode/plate-common';

export const isEmptyValue = (value: TElement[]) => {
  if (!Array.isArray(value)) {
    return true;
  }

  if (value.length === 0) {
    return true;
  }

  if (
    value.every(
      (node) =>
        node.type === 'p' &&
        node.children.length <= 1 &&
        node.children.every((child) => child.text === ''),
    )
  ) {
    return true;
  }

  return false;
};
