import {
  componentsReady,
  PButton,
  PButtonPure,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { Column } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { ActionGroup, Popover } from '../..';

export const TextFilter = <T,>({
  column,
  i18n,
}: {
  column: Column<T>;
  i18n: {
    actionSearch: string;
    buttonReset: string;
    buttonFilter: string;
    filterLabel: string;
  };
}) => {
  const [isVisible, setVisibility] = useState(false);
  const filterValue = column.getFilterValue() as string;
  const [searchString, setSearchString] = useState(filterValue);
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchString(filterValue);
  }, [filterValue]);

  // Native autofocus does not work here
  useEffect(() => {
    const focus = async () => {
      await componentsReady();
      inputEl?.current?.focus();
    };
    focus();
  }, [isVisible]);

  const applyFilter = () => {
    column.setFilterValue(searchString || undefined);
    setVisibility(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && applyFilter();

  return (
    <Popover
      target={
        <PButtonPure
          type="button"
          active={!!filterValue}
          icon="search"
          size="x-small"
          onClick={() => setVisibility((isVisible) => !isVisible)}
          role="button"
          hideLabel
          aria-label={i18n.filterLabel}
        />
      }
      open={isVisible}
      onChange={setVisibility}
      placement="right-start"
    >
      <>
        <PTextFieldWrapper hideLabel label={i18n.actionSearch}>
          <input
            type="text"
            ref={inputEl}
            value={searchString || ''}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </PTextFieldWrapper>

        <ActionGroup spacing="medium">
          <PButton
            type="button"
            variant="secondary"
            icon="close"
            role="button"
            onClick={() => {
              setSearchString('');
              column.setFilterValue(undefined);
              setVisibility(false);
            }}
          >
            {i18n.buttonReset}
          </PButton>
          <PButton role="button" type="button" onClick={applyFilter}>
            {i18n.buttonFilter}
          </PButton>
        </ActionGroup>
      </>
    </Popover>
  );
};
