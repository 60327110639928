import { PLinkProps, PLinkPure } from '@porsche-design-system/components-react';
import { styled } from '../stitches.config';

export const InlineNavigation = styled('ul', {
  listStyleType: 'none',
  margin: 0,
  padding: 0,

  li: {
    display: 'block',
    padding: '0 0 $xSmall 0',
    margin: 0,
  },
});

export const Navigation = styled('ul', {
  listStyleType: 'none',
  margin: '0 0 $small 0',
  padding: 0,
});

export const NavigationItem = styled('li', {
  margin: 0,
  paddingLeft: '$small',
  paddingRight: '$small',
  display: 'inline-block',

  variants: {
    spacing: {
      medium: {
        paddingLeft: '$medium',
        paddingRight: '$medium',
      },
    },
    divider: {
      true: {
        paddingRight: '$medium',
        marginRight: '$small',
        paddingLeft: 0,
        borderRight: '1px solid $contrastHigh',
      },
    },
  },
});

const NavigationActionLink = styled('a', {
  borderRadius: '$small',
  border: '1px solid $text',
  padding: '$xSmall $medium',
  verticalAlign: 'baseline',
  color: '$text',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '$hover',
  },
});

const NavigationPureLink = styled(PLinkPure, {
  variants: {
    isActive: {
      true: {
        borderBottom: '2px solid $text',
        '&:hover': {
          borderBottomColor: 'transparent',
        },
      },
    },
  },
});

export const NavigationLink = (
  props: {
    isActive?: boolean;
    type?: 'action';
    as?: React.ComponentType | string;
  } & PLinkProps,
) => {
  if (props.type === 'action') {
    const { aria, ...rest } = props;
    return (
      <NavigationActionLink {...rest} {...(aria as Record<string, unknown>)} />
    );
  }

  return <NavigationPureLink {...props} />;
};
