import {
  PButton,
  PButtonGroup,
  PInlineNotification,
  PLink,
} from '@porsche-design-system/components-react';

import { gridGap } from '@porsche-design-system/components-react/styles';
import {
  ActionGroup,
  ConfirmationModal,
  Editor,
  isEmptyValue,
  Spacer,
  styled,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { ClientError } from 'graphql-request';
import { ParseKeys } from 'i18next';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../config/i18n';
import { seenSupportRequestsComments } from '../config/localStorageKeys';
import { Role } from '../config/role';
import { useAbilityContext, usePersonContext } from '../context';
import {
  SupportRequestQuery,
  SupportRequestsQuery,
  SupportRequestStatus,
  UploadReference,
  UploadType,
  useDeleteSupportRequestMutation,
  useSupportRequestQuery,
  useSupportRequestsQuery,
  useUpdateSupportRequestMutation,
} from '../graphql';
import { uploader, UploadProgress } from '../lib/uploader';
import { FileUploader } from './FileUploader';
import { SupportRequestStatusSelect } from './SupportRequestStatusSelect';

const HalfColumn = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: gridGap,

  '@s': {
    gridTemplateColumns: '1fr 1fr',
  },
});

type Form = {
  text: string;
  attachments: {
    id: string;
    name: string;
    size: number;
    type: string;
    file: File;
  }[];
  status: SupportRequestStatus;
};

export const SupportRequestCommentForm = ({
  id,
  title,
  status,
}: {
  id: string;
  title: string;
  status: SupportRequestStatus;
}) => {
  const { t } = useTranslation(NAMESPACES);
  const {
    state: { roles },
  } = usePersonContext();

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();
  const [uploadError, setUploadError] = useState<unknown | undefined>(
    undefined,
  );

  const [attachmentController, setAttachmentController] = useState(
    () => new AbortController(),
  );

  const queryClient = useQueryClient();

  const { mutateAsync: updateSupportRequest } = useUpdateSupportRequestMutation(
    {
      onSuccess: ({ updateSupportRequest }) => {
        queryClient.setQueryData<SupportRequestQuery>(
          useSupportRequestQuery.getKey({ id: updateSupportRequest.id }),
          (cache) =>
            cache?.supportRequest
              ? {
                  supportRequest: {
                    ...cache.supportRequest,
                    status: updateSupportRequest.status,
                    comments: updateSupportRequest.comments,
                  },
                }
              : undefined,
        );

        queryClient.setQueryData<SupportRequestsQuery>(
          useSupportRequestsQuery.getKey(),
          (cache) =>
            cache?.supportRequests
              ? {
                  supportRequests: cache.supportRequests.map(
                    (supportRequest) => {
                      if (supportRequest.id === updateSupportRequest.id) {
                        return updateSupportRequest;
                      }

                      return supportRequest;
                    },
                  ),
                }
              : undefined,
        );
      },
    },
  );

  const { control, handleSubmit, formState, reset, setValue, watch } =
    useForm<Form>({
      defaultValues: {
        status,
        attachments: [],
      },
    });

  return (
    <form
      onSubmit={handleSubmit(async ({ status, attachments, ...comment }) => {
        let uploadFailed = false;
        const uploadedAttachments =
          attachments.length > 0
            ? (
                await uploader(
                  attachments,
                  UploadType.Attachment,
                  UploadReference.Dashboard,
                  {
                    onProgress: (progress) => {
                      setUploadProgress({ ...progress });
                    },
                    onFailure: (_file, error) => {
                      uploadFailed = true;

                      // Don't show error if request was canceled by user
                      if (
                        (error as { code?: string })?.code === 'ERR_CANCELED'
                      ) {
                        return;
                      }

                      setUploadError(error);
                    },
                    signal: attachmentController.signal,
                  },
                )
              ).filter(<T,>(d: T | undefined): d is T => !!d)
            : [];

        if (uploadFailed) {
          return;
        }

        const result = await updateSupportRequest({
          input: {
            id,
            status,

            // only push new comment if text exists
            ...(comment.text && {
              comments: {
                _push: [
                  {
                    ...comment,
                    ...(uploadedAttachments.length > 0 && {
                      attachments: uploadedAttachments.map((attachment) => ({
                        id: attachment.id,
                        name: attachment.file.name,
                        size: attachment.file.size,
                        type: attachment.file.type,
                      })),
                    }),
                  },
                ],
              },
            }),
          },
        });

        const commentId = result.updateSupportRequest.comments.at(-1)?.id;
        if (commentId) {
          localStorage.setItem(
            seenSupportRequestsComments(id, commentId),
            dayjs().toISOString(),
          );
        }

        reset({ status }, { keepSubmitCount: true });
        setAttachmentController(() => new AbortController());
      })}
    >
      <>
        {!!uploadError && (
          <>
            <PInlineNotification
              heading={t('supportRequest.uploadError')}
              description={`${
                uploadError instanceof ClientError
                  ? uploadError.response.errors?.[0].message ??
                    uploadError.message
                  : `${uploadError}`
              }`}
              state="error"
              dismissButton={false}
            />

            <Spacer mb="$medium" />
          </>
        )}

        <Controller
          control={control}
          name="text"
          render={({ field, fieldState }) => (
            <Editor
              key={formState.submitCount}
              size="small"
              label={t('supportRequest.answer')}
              message={
                fieldState.error?.message
                  ? t(fieldState.error.message as ParseKeys)
                  : undefined
              }
              initialValue={field.value ? JSON.parse(field.value) : undefined}
              onChange={(value) => {
                field.onChange(JSON.stringify(value));

                // Set status to IN_PROGRESS if a SystemAdmin is answering on OPEN support request
                if (
                  roles?.includes(Role.SystemAdmin) &&
                  watch('status') === SupportRequestStatus.Open &&
                  !isEmptyValue(value)
                ) {
                  setValue('status', SupportRequestStatus.InProgress);
                }
              }}
              i18n={{
                gdprTooltip: t('common:editor.confidentialData'),
              }}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="attachments"
          render={({ field: { value, onChange } }) => (
            <FileUploader
              label={t('supportRequest.attachments')}
              value={{
                attachments: [],
                attachmentsToUpload: value ?? [],
              }}
              onChange={(value) => {
                onChange(value.attachmentsToUpload);
              }}
              progress={uploadProgress}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <HalfColumn>
              <SupportRequestStatusSelect
                onChange={field.onChange}
                value={field.value}
              />
            </HalfColumn>
          )}
        />
      </>

      <ActionGroup>
        <PLink
          onClick={() => {
            attachmentController.abort();
          }}
          icon="arrow-head-left"
          variant="secondary"
        >
          <Link to="/support">{t('common:action.back')}</Link>
        </PLink>

        <div>
          <PButtonGroup>
            <DeleteButton id={id} title={title} />

            <PButton role="button" loading={formState.isSubmitting}>
              {t('save')}
            </PButton>
          </PButtonGroup>
        </div>
      </ActionGroup>
    </form>
  );
};

const DeleteButton = ({ id, title }: { id: string; title: string }) => {
  const { t } = useTranslation(NAMESPACES);
  const ability = useAbilityContext();
  const canDelete = ability.can('delete', 'SupportRequest');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: deleteSupportRequest, isLoading } =
    useDeleteSupportRequestMutation({
      onSuccess: (data) => {
        queryClient.removeQueries({
          queryKey: useSupportRequestQuery.getKey({
            id: data.deleteSupportRequest.id,
          }),
          exact: true,
        });

        queryClient.setQueryData<SupportRequestsQuery>(
          useSupportRequestsQuery.getKey(),
          (cache) =>
            cache?.supportRequests
              ? {
                  supportRequests: cache.supportRequests.filter(
                    (supportRequest) =>
                      supportRequest.id !== data.deleteSupportRequest.id,
                  ),
                }
              : undefined,
        );

        navigate({
          to: '/support',
        });
      },
    });

  if (!canDelete) {
    return null;
  }

  return (
    <>
      <PButton
        type="button"
        role="button"
        icon="delete"
        variant="secondary"
        onClick={() => setIsModalOpen(true)}
      >
        {t('common:action.delete')}
      </PButton>

      <ConfirmationModal
        open={isModalOpen}
        description={t(
          'supportRequest.confirmEffectsOfSupportRequestDeletion',
          { title },
        )}
        heading={t('supportRequest.deleteSupportRequest')}
        onDismiss={() => setIsModalOpen(false)}
        i18n={{
          cancel: t('common:action.cancel'),
          confirm: t('common:action.delete'),
        }}
        isLoading={isLoading}
        onConfirm={async () => {
          await deleteSupportRequest(
            { id },
            { onSettled: () => setIsModalOpen(false) },
          );
        }}
      />
    </>
  );
};
