import { PSpinner } from '@porsche-design-system/components-react';
import { AsyncSelect, AsyncSelectProps } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { MultiValueGenericProps, components } from 'react-select';
import {
  OrganizationsQuery,
  OrganizationsQueryVariables,
  useOrganizationQuery,
} from '../../graphql';
import { organizations as organizationsGql } from '../../graphql/organization';
import { request } from '../../lib/graphqlRequest';

export type OrganizationSelectOption = {
  id: number;
  name: string;
};

type Organization = OrganizationsQuery['organizations']['docs'][0];

const mapToOptions = (organizations: Organization[]) =>
  organizations.map(({ id, name, partnerNumber, status }) => ({
    id,
    name,
    appendix: partnerNumber,
    status,
  }));

const MultiValueLabel = (
  props: MultiValueGenericProps<OrganizationSelectOption>,
) => {
  const { data, isLoading, fetchStatus } = useOrganizationQuery(
    { id: props.data.id },
    {
      enabled: props.children?.toString().length === 0,
    },
  );
  const { MultiValueLabel } = components;

  if (isLoading && fetchStatus !== 'idle') {
    return (
      <MultiValueLabel {...props}>
        <PSpinner size="inherit" style={{ height: '26px' }} />
      </MultiValueLabel>
    );
  }

  if (data?.organization?.name) {
    return (
      <MultiValueLabel {...props}>{data.organization.name}</MultiValueLabel>
    );
  }

  return <MultiValueLabel {...props} />;
};

export const OrganizationSelect = <IsMulti extends boolean = false>(
  props: AsyncSelectProps<OrganizationSelectOption, IsMulti>,
): JSX.Element => {
  const queryClient = useQueryClient();

  const defaultData = queryClient.getQueryData<OrganizationsQuery>([
    'organizations',
    { searchString: '' },
  ]);

  const loader = async (searchString: string) => {
    const {
      organizations: { docs },
    } = await queryClient.fetchQuery(['organizations', { searchString }], () =>
      request<OrganizationsQuery, OrganizationsQueryVariables>(
        organizationsGql,
        {
          searchString,
        },
      )(),
    );

    return mapToOptions(docs);
  };

  const debouncedLoader = debounce((inputValue, callback) => {
    loader(inputValue).then((options) => callback(options));
  }, 600);

  return (
    <AsyncSelect
      {...props}
      cacheOptions
      components={{
        MultiValueLabel,
      }}
      defaultOptions={
        defaultData ? mapToOptions(defaultData.organizations.docs) : true
      }
      getOptionValue={(option) => `${option['id']}`}
      getOptionLabel={(option) => option.name ?? ''}
      loadOptions={debouncedLoader}
    />
  );
};
