import {
  PButton,
  PButtonPure,
  PHeading,
  PIcon,
  PInlineNotification,
  PLinkPure,
  PSwitch,
  PTag,
} from '@porsche-design-system/components-react';
import {
  ActionGroup,
  ConfirmationModal,
  DataTable,
  Modal,
  Spacer,
  styled,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { ClientError } from 'graphql-request';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APP_FALLBACK,
  ApplicationTags,
  DateOutput,
  FeatureArticleForm,
  getAppName,
} from '../../../components';
import { COMMON_NAMESPACE, NAMESPACES } from '../../../config/i18n';
import {
  AdminFeatureArticlesQuery,
  FeatureArticleQuery,
  FeatureArticlesQuery,
  useAdminFeatureArticlesQuery,
  useCreateFeatureArticleMutation,
  useDeleteFeatureArticleMutation,
  useFeatureArticleQuery,
  useFeatureArticlesQuery,
  useUpdateFeatureArticleMutation,
} from '../../../graphql';

const PAGE_SIZE = 10;

export const FeatureArticlesList = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(NAMESPACES);

  const [error, setError] = useState<unknown>();

  const { isLoading, data } = useAdminFeatureArticlesQuery();

  const featureArticles = useMemo(
    () =>
      data?.featureArticles.map((feature) => ({
        ...feature,
        apps: feature.apps.length > 0 ? feature.apps : [APP_FALLBACK],
      })),
    [data],
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<NonNullable<typeof featureArticles>[number]>();

    return [
      columnHelper.accessor('publishedAt', {
        cell: (data) => {
          const publishDate = data.getValue();
          const isLive =
            !!data.row.original.isPublished &&
            !!publishDate &&
            new Date(publishDate) <= new Date();

          return (
            <PublishContainer>
              {isLive ? (
                <PIcon name="check" color="notification-success" />
              ) : (
                <PIcon name="clock" />
              )}
              {publishDate ? <DateOutput date={publishDate} /> : 'n/a'}
            </PublishContainer>
          );
        },
        header: () => t('publishDate'),
        enableColumnFilter: false,
      }),
      columnHelper.accessor('title', {
        cell: (data) => data.getValue(),
        header: () => t('featureArticle.title'),
      }),
      columnHelper.accessor('author.name', {
        cell: (data) => data.getValue(),
        header: () => t('author'),
      }),
      columnHelper.accessor('apps', {
        cell: (data) => (
          <ApplicationTags apps={data.getValue()} color="primary" />
        ),
        header: () => t('apps'),
        meta: {
          filterType: 'select',
          filterOptions: [
            ...new Set(featureArticles?.flatMap((article) => article.apps)),
          ].map((app) => ({
            label: getAppName(app, t),
            value: app,
          })),
        },
        filterFn: (row, columnId, filterValue) =>
          (row.getValue(columnId) as string[]).includes(filterValue),
      }),
      columnHelper.accessor('roles', {
        cell: (data) => (
          <RoleTagsContainer>
            {data.getValue().map((role) => (
              <PTag key={`${data.row.original.id}_${role}`}>{role}</PTag>
            ))}
          </RoleTagsContainer>
        ),
        header: () => t('roles'),
        meta: {
          filterType: 'select',
          filterOptions: [
            ...new Set(featureArticles?.flatMap((article) => article.roles)),
          ].map((role) => ({
            label: role,
            value: role,
          })),
        },
        filterFn: (row, columnId, filterValue) =>
          (row.getValue(columnId) as string[]).includes(filterValue),
      }),
      columnHelper.accessor(
        (row) => {
          const orgs = row.organizations.map(
            (organization) => organization.name,
          );

          if (orgs.length > 4) {
            orgs.splice(3, 0, t('xMore', { count: orgs.length - 3 }));
          }

          return new Intl.ListFormat(language, { type: 'conjunction' }).format([
            ...orgs.slice(0, 4),
          ]);
        },
        {
          id: 'organizations',
          header: () => t('organizations'),
          meta: {
            name: t('organizations'),
          },
          filterFn: 'includesString',
        },
      ),
      columnHelper.accessor('isPublished', {
        cell: (data) => (
          <PublishSwitch
            articleId={data.row.original.id}
            checked={!!data.getValue()}
          />
        ),
        header: () => t('published'),
        meta: {
          filterType: 'select',
          filterOptions: [true, false].map((value) => ({
            label: value ? t('published') : t('notPublished'),
            value,
          })),
        },
        filterFn: (row, columnId, filterValue) =>
          `${row.getValue(columnId)}` === filterValue,
      }),
      columnHelper.accessor('id', {
        header: () => <TableCellActions>{t('admin.actions')}</TableCellActions>,
        cell: (data) => {
          const id = data.getValue();
          return (
            <TableCellActions>
              <PLinkPure
                size="small"
                aria-label={t('previewArticle')}
                icon="view"
                hideLabel
              >
                <Link to={`/news/${id}`} search={{ preview: 1 }} />
              </PLinkPure>
              <EditButton articleId={id} onError={(error) => setError(error)} />
              <DeleteButton
                articleId={id}
                onError={(error) => setError(error)}
              />
            </TableCellActions>
          );
        },
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ];
  }, [t, language, featureArticles]);

  return (
    <>
      <PHeading role="heading" aria-level={1} tag="h1" size="large">
        {t('featureArticles')}
      </PHeading>

      <Spacer mb="$medium" />

      {error && (
        <>
          <PInlineNotification
            heading={t('featureArticle.error')}
            state="error"
            onDismiss={() => setError(undefined)}
          >
            {error instanceof ClientError
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                t((error.response.errors?.[0].message as any) ?? error.message)
              : `${error}`}
          </PInlineNotification>

          <Spacer mb="$medium" />
        </>
      )}

      <ActionGroup>
        <div />
        <CreateNewButton onError={(error) => setError(error)} />
      </ActionGroup>

      <Spacer mb="$medium" />

      <DataTable
        caption={t('featureArticles')}
        isLoading={isLoading}
        data={featureArticles ?? []}
        pagination={{
          pageSize: PAGE_SIZE,
        }}
        columns={columns}
        idAccessor="id"
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
      />
    </>
  );
};

const TableCellActions = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  gap: '$small',
});

const RoleTagsContainer = styled('div', {
  display: 'inline-grid',
  gap: '$small',
  gridAutoFlow: 'column',
  gridAutoColumns: 'max-content',
});

const PublishSwitch = ({
  articleId,
  checked,
}: {
  articleId: string;
  checked: boolean;
}) => {
  const { t } = useTranslation();

  const client = useQueryClient();
  const { mutateAsync: updateFeatureArticle, isLoading } =
    useUpdateFeatureArticleMutation({
      onSuccess: () => {
        client.setQueryData<AdminFeatureArticlesQuery>(
          useAdminFeatureArticlesQuery.getKey(),
          (cache) =>
            cache && {
              ...cache,
              featureArticles: cache.featureArticles.map((article) =>
                article.id === articleId
                  ? {
                      ...article,
                      isPublished: !article.isPublished,
                      publishedAt:
                        article.publishedAt ??
                        new Date().toISOString().substring(0, 10),
                    }
                  : article,
              ),
            },
        );
      },
    });

  return (
    <PSwitch
      loading={isLoading}
      checked={checked}
      onUpdate={(event) => {
        updateFeatureArticle({
          input: {
            id: articleId,
            isPublished: event.detail.checked,
            publishedAt: new Date().toISOString().substring(0, 10),
          },
        });
      }}
      hideLabel
      aria-label={t('published')}
    />
  );
};

const CreateNewButton = ({ onError }: { onError: (err: unknown) => void }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: createFeatureArticle, error } =
    useCreateFeatureArticleMutation({
      onSuccess: () =>
        queryClient.invalidateQueries(useAdminFeatureArticlesQuery.getKey()),
      onError,
    });

  const navigate = useNavigate();

  return (
    <>
      <PButton
        icon="add"
        variant="primary"
        role="button"
        onClick={() => setOpen(true)}
      >
        {t('addNewArticle')}
      </PButton>

      <Modal
        open={open}
        onDismiss={() => setOpen(false)}
        disableBackdropClick
        dismissButton={false}
        aria={{ 'aria-label': t('createNewArticle') }}
      >
        <Modal.Header>{t('createNewArticle')}</Modal.Header>

        <FeatureArticleForm
          error={error}
          onSubmit={async (article, navigateTo) => {
            await createFeatureArticle(
              { input: article },
              {
                onSuccess: (data) =>
                  navigateTo === 'preview'
                    ? navigate({
                        to: `/news/${data.createFeatureArticle.id}`,
                        search: { preview: 1 },
                      })
                    : setOpen(false),
              },
            );
          }}
          onCancel={() => setOpen(false)}
        />
      </Modal>
    </>
  );
};

const EditButton = ({
  articleId,
  disabled,
  onError,
}: {
  articleId: string;
  disabled?: boolean;
  onError: (error: unknown) => void;
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: updateFeatureArticle, error } =
    useUpdateFeatureArticleMutation({
      onSuccess: (result) => {
        queryClient.setQueryData<FeatureArticleQuery>(
          useFeatureArticleQuery.getKey({ id: articleId }),
          (cache) => ({
            ...cache,
            featureArticle: {
              ...cache?.featureArticle,
              ...result.updateFeatureArticle,
            },
          }),
        );
        queryClient.setQueryData<AdminFeatureArticlesQuery>(
          useAdminFeatureArticlesQuery.getKey(),
          (cache) =>
            cache && {
              ...cache,
              featureArticles: cache.featureArticles.map((article) =>
                article.id === articleId
                  ? {
                      ...article,
                      ...result.updateFeatureArticle,
                    }
                  : article,
              ),
            },
        );
      },
      onError,
    });

  const navigate = useNavigate();

  const { data, isLoading } = useFeatureArticleQuery(
    { id: articleId },
    {
      enabled: open,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <PButtonPure
        size="small"
        icon="edit"
        aria-label={t('editArticle')}
        data-article-id={articleId}
        role="button"
        onClick={() => setOpen(true)}
        disabled={disabled}
        hideLabel
        loading={open && isLoading}
      />

      <Modal
        open={open && !isLoading}
        onDismiss={() => setOpen(false)}
        disableBackdropClick
        dismissButton={false}
        aria={{ 'aria-label': t('editArticle') }}
      >
        <Modal.Header>{t('editArticle')}</Modal.Header>

        <FeatureArticleForm
          error={error}
          defaultValues={data?.featureArticle}
          onSubmit={async (article, navigateTo) => {
            await updateFeatureArticle(
              { input: { id: articleId, ...article } },
              {
                onSuccess: (data) =>
                  navigateTo === 'preview'
                    ? navigate({
                        to: `/news/${data.updateFeatureArticle.id}`,
                        search: { preview: 1 },
                      })
                    : setOpen(false),
              },
            );
          }}
          onCancel={() => setOpen(false)}
        />
      </Modal>
    </>
  );
};

const DeleteButton = ({
  articleId,
  disabled,
  onError,
}: {
  articleId: string;
  disabled?: boolean;
  onError: (error: unknown) => void;
}): JSX.Element => {
  const { t } = useTranslation(NAMESPACES);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const {
    mutateAsync: deleteFeatureArticle,
    isLoading: isDeletingFeatureArticle,
  } = useDeleteFeatureArticleMutation({
    onSuccess: () => {
      queryClient.setQueryData<FeatureArticlesQuery>(
        useFeatureArticlesQuery.getKey(),
        (cache) =>
          cache && {
            ...cache,
            featureArticles: cache.featureArticles.filter(
              (article) => article.id !== articleId,
            ),
          },
      );
      queryClient.setQueryData<AdminFeatureArticlesQuery>(
        useAdminFeatureArticlesQuery.getKey(),
        (cache) =>
          cache && {
            ...cache,
            featureArticles: cache.featureArticles.filter(
              (article) => article.id !== articleId,
            ),
          },
      );

      queryClient.removeQueries({
        queryKey: useFeatureArticleQuery.getKey({ id: articleId }),
        exact: true,
      });
    },
    onError,
  });

  return (
    <>
      <PButtonPure
        size="small"
        icon="delete"
        aria-label={t('deleteArticle')}
        data-article-id={articleId}
        role="button"
        onClick={() => setIsModalOpen(true)}
        disabled={disabled}
        hideLabel
      />

      <ConfirmationModal
        open={isModalOpen}
        description={t('confirmEffectsOfArticleDeletion')}
        heading={t('deleteArticle')}
        onDismiss={() => setIsModalOpen(false)}
        i18n={{
          cancel: t('action.cancel', { ns: COMMON_NAMESPACE }),
          confirm: t('action.remove', { ns: COMMON_NAMESPACE }),
        }}
        isLoading={isDeletingFeatureArticle}
        onConfirm={async () => {
          await deleteFeatureArticle(
            { id: articleId },
            { onSettled: () => setIsModalOpen(false) },
          );
        }}
      />
    </>
  );
};

const PublishContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '$small',
});
