import { focusEditor, TElement } from '@udecode/plate-common';
import {
  Plate,
  PlateProvider,
  useEventPlateId,
  usePlateEditorState,
} from '@udecode/plate-core';
import { useEffect } from 'react';
import { BaseEditor, Editor as SlateEditor } from 'slate';
import { styled } from '../../stitches.config';
import { Label } from '../Label';
import { Message } from '../Message';
import { isEmptyValue } from './isEmptyValue';
import { RichText } from './RichText';
import { Toolbar } from './Toolbar';
import { usePlateId } from './usePlateId';
import { usePlugins } from './usePlugins';
import { useSingleLinePlugins } from './useSingleLinePlugins';

const Wrapper = styled('div', {
  border: '2px solid $contrastMedium',
  borderRadius: '$small',
  padding: '13px $medium',
  position: 'relative',
});

export const SingleLineEditor = <
  TMentionComponentValue extends { type: string },
  TMentionComponentPropsExtension,
>({
  id: propsId,
  testId,
  label,
  required,
  autoFocus,
  message,
  initialValue,
  onChange,
  MentionLabel,
  mentionProps,
  onPasteMention,
}: {
  id?: string;
  testId?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  message?: string;
  initialValue?: TElement[];
  onChange?: (value: TElement[]) => void;
  MentionLabel?: React.FunctionComponent<
    { value: TMentionComponentValue } & TMentionComponentPropsExtension
  >;
  mentionProps?: TMentionComponentPropsExtension;
  onPasteMention?: (mention: TMentionComponentValue) => boolean;
}) => {
  const id = usePlateId(propsId);

  const plugins = useSingleLinePlugins({
    MentionLabel,
    mentionProps,
    onPasteMention,
  });

  return (
    <PlateProvider
      id={id}
      plugins={plugins}
      initialValue={initialValue}
      onChange={onChange}
    >
      {label && <Label label={label} isRequired={required} />}

      <Wrapper {...(testId && { 'data-testid': testId })}>
        {required && (
          <RequiredInput
            id={id}
            isEmpty={!initialValue || isEmptyValue(initialValue)}
            autoFocus={autoFocus}
          />
        )}
        <Plate id={id} />
      </Wrapper>
      <Message value={message} />
    </PlateProvider>
  );
};

export const Editor = <
  TMentionComponentValue extends { type: string },
  TMentionComponentPropsExtension,
>({
  id: propsId,
  testId,
  label,
  required,
  disabled,
  message,
  size,
  initialValue,
  onChange,
  hasCharactersCounter,
  onUploadImage,
  onCancelImageUpload,
  allowedImageTypes,
  MentionLabel,
  mentionProps,
  onPasteMention,
  i18n,
}: {
  id?: string;
  testId?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  message?: string;
  size?: 'small' | 'default';

  initialValue?: TElement[];
  onChange?: (value: TElement[]) => void;

  hasCharactersCounter?: boolean;

  onUploadImage?: (
    fileOrData: File | string,
    opt: { onProgress?: (value: number) => void },
  ) => Promise<string>;
  onCancelImageUpload?: () => void;
  allowedImageTypes?: { type: string; extension: string }[];

  MentionLabel?: React.FunctionComponent<
    { value: TMentionComponentValue } & TMentionComponentPropsExtension
  >;
  mentionProps?: TMentionComponentPropsExtension;
  onPasteMention?: (mention: TMentionComponentValue) => boolean;

  i18n: {
    gdprTooltip: string;
    captionPlaceholder?: string;
    dropzoneDescription?: string;
    imageUploadHeading?: string;
    dragDescription?: string;
    uploadButtonText?: string;
    resetButtonText?: string;
    uploadErrorHeading?: string;
    uploadErrorDescription?: string;
  };
}) => {
  const id = usePlateId(propsId);

  const plugins = usePlugins({
    onUploadImage,
    MentionLabel,
    mentionProps,
    onPasteMention,
    i18n,
    includeImages: true,
  });

  if (disabled && initialValue) {
    return <RichText value={initialValue} includeImages />;
  }

  return (
    <PlateProvider
      id={id}
      plugins={plugins}
      initialValue={initialValue}
      onChange={onChange}
    >
      {label && <Label label={label} isRequired={required} />}

      <Toolbar
        id={id}
        hasCharactersCounter={hasCharactersCounter}
        gdprTooltip={i18n.gdprTooltip}
        imageUpload={
          onUploadImage
            ? {
                onUploadImage,
                onCancelImageUpload,
                allowedImageTypes,
                i18n,
              }
            : undefined
        }
      />

      <Wrapper {...(testId && { 'data-testid': testId })}>
        {required && (
          <RequiredInput
            id={id}
            isEmpty={!initialValue || isEmptyValue(initialValue)}
          />
        )}
        <Plate
          id={id}
          editableProps={{
            style: { minHeight: size === 'small' ? '80px' : '200px' },
            'aria-label': label,
          }}
        />
      </Wrapper>
      <Message value={message} />
    </PlateProvider>
  );
};

const RequiredInput = ({
  id,
  isEmpty,
  autoFocus,
}: {
  id: string;
  isEmpty: boolean;
  autoFocus?: boolean;
}) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  useEffect(() => {
    if (autoFocus) {
      focusEditor(editor, {
        anchor: SlateEditor.end(editor as BaseEditor, []),
        focus: SlateEditor.end(editor as BaseEditor, []),
      });
    }
  }, [autoFocus, editor]);

  return (
    <RequiredInputFix
      tabIndex={-1}
      autoComplete="off"
      value={isEmpty ? '' : 'not-empty'}
      onChange={() => {
        // noop
      }}
      onFocus={(evt) => {
        evt.preventDefault();
        focusEditor(editor);
      }}
      required
    />
  );
};

const RequiredInputFix = styled('input', {
  opacity: 0,
  height: '100%',
  width: '100%',
  bottom: 0,
  position: 'absolute',
});

export type { TElement };
