const KEY = 'kado.devtools';

export const clearSettings = () => localStorage.removeItem(KEY);

export const setSettings = (values: Record<string, unknown>) =>
  localStorage.setItem(KEY, JSON.stringify(values));

export const getSettings = (): Record<'ppnuid', string> | undefined => {
  const data = localStorage.getItem(KEY);
  try {
    return data ? JSON.parse(data) : undefined;
  } catch {
    return undefined;
  }
};

export const getPpnuid = (): string | undefined => {
  const settings = getSettings();
  return settings?.ppnuid;
};

export const getPpnuidHeader = () => {
  const ppnuid = getPpnuid();
  return ppnuid ? { 'x-ppnuid': ppnuid } : undefined;
};

export const isDevToolsActive = () => {
  const data = localStorage.getItem(KEY);
  return !!data;
};
