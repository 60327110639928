import { PHeading } from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { Outlet, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { PersonSelect } from '../../../components';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonIdParam } from './usePersonIdParam';

export const PersonInfoOverview = () => {
  const { t } = useTranslation(NAMESPACES);
  const navigate = useNavigate();

  const personId = Number(usePersonIdParam());

  return (
    <>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {t('admin.personInfo.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <PersonSelect
        label={t('common:person')}
        aria-label={t('common:person')}
        {...(!Number.isNaN(personId) && { defaultValue: { id: personId } })}
        filter={{ pseudonymized: { _ne: false } }}
        isRequired
        isClearable
        onChange={(person) =>
          navigate({
            to: person?.id
              ? `/admin/person-info/${person.id}/account`
              : '/admin/person-info',
          })
        }
      />

      <Spacer mb="$large" />

      <Outlet />
    </>
  );
};
