import { PIcon, PLinkPure } from '@porsche-design-system/components-react';
import { Link, useRouter, useSearch } from '@tanstack/react-router';
import React from 'react';
import { styled } from '../stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xSmall',
  marginBottom: '$medium',
});

const Arrow = styled(PIcon, {
  height: '0.75em',
  width: '0.75em',
});

export const Breadcrumbs = ({
  kadoName,
  appName,
}: {
  kadoName: React.ReactNode;
  appName: React.ReactNode;
}) => {
  const router = useRouter();
  const search = useSearch({ strict: false });

  const crumbs = router.state.matches
    .map((match) =>
      match.routeContext && 'breadcrumb' in match.routeContext
        ? {
            match,
            breadcrumb: match.routeContext
              .breadcrumb as () => React.ReactElement,
          }
        : null,
    )
    .filter((d) => d !== null)
    // only keep the first breadcrumb, discard breadcrumbs that are only inherited
    .filter(
      (d, i, a) => i === a.findIndex((o) => o.breadcrumb === d.breadcrumb),
    );

  return (
    <Wrapper>
      <PLinkPure size="x-small" href="/" icon="none">
        {kadoName}
      </PLinkPure>
      <Arrow name="arrow-head-right" size="inherit" />
      <PLinkPure size="x-small" icon="none">
        <Link to="/" search={search}>
          {appName}
        </Link>
      </PLinkPure>
      {crumbs.map(({ match, breadcrumb }) => (
        <React.Fragment key={match.routeId}>
          <Arrow name="arrow-head-right" size="inherit" />
          <PLinkPure size="x-small" icon="none" data-testid="breadcrumb">
            <Link to={match.pathname} search={search}>
              {breadcrumb()}
            </Link>
          </PLinkPure>
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
