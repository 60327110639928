import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { getPpnuid } from '@porsche-kado/devtools';
import { useEffect } from 'react';

type AUTH_EVENT =
  | 'configured'
  | 'signIn'
  | 'signIn_failure'
  | 'signUp'
  | 'signUp_failure'
  | 'confirmSignUp'
  | 'completeNewPassword_failure'
  | 'autoSignIn'
  | 'autoSignIn_failure'
  | 'forgotPassword'
  | 'forgotPassword_failure'
  | 'forgotPasswordSubmit'
  | 'forgotPasswordSubmit_failure'
  | 'verify'
  | 'tokenRefresh'
  | 'tokenRefresh_failure'
  | 'cognitoHostedUI'
  | 'cognitoHostedUI_failure'
  | 'customOAuthState'
  | 'customState_failure'
  | 'parsingCallbackUrl'
  | 'userDeleted'
  | 'updateUserAttributes'
  | 'updateUserAttributes_failure'
  | 'signOut';

export const useAuth = (
  onSuccess: (user: { ppnuid: string }, event?: AUTH_EVENT) => void,
  onFailure: (error: unknown) => void,
) => {
  useEffect(() => {
    const checkUserAuth = async (event?: AUTH_EVENT) => {
      try {
        if (process.env.NODE_ENV === 'development') {
          const ppnuid = getPpnuid();
          if (ppnuid) {
            onSuccess({ ppnuid }, event);
            return;
          }
        }

        const signedInUser = await Auth.currentAuthenticatedUser();
        const ppnuid = signedInUser
          .getUsername()
          .replace(/^ppn(lite|dev|test)?_/, '');

        onSuccess({ ppnuid }, event);
      } catch (err) {
        onFailure(err);
      }
    };

    checkUserAuth();

    const unsubscribe = Hub.listen('auth', (data) => {
      checkUserAuth(data.payload.event as AUTH_EVENT);
    });
    return () => unsubscribe();
    // Need to disable this rule beacuse the required fix causes infinite loops (a function values alway changes)
    // See: https://github.com/facebook/react/issues/14920#issuecomment-471070149
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
