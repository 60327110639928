const DEFAULT_REGION = 'eu-central-1';
import { version } from '../../package.json';

const isPasswordFlowActive =
  process.env.REACT_APP_IS_PASSWORD_FLOW_ACTIVE === 'true';

const cookieDomain = process.env.REACT_APP_COGNITO_COOKIE_DOMAIN?.slice(4); // remove application prefix (kado) from the domain

export default {
  Auth: {
    region: DEFAULT_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    ...(!isPasswordFlowActive && {
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN?.slice(8), // remove protocol (https://) from the domain
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URI,
        responseType: 'code',
      },
    }),
    cookieStorage: {
      domain: cookieDomain ?? 'localhost',
      secure: !!cookieDomain,
      sameSite: 'strict',
      expires: undefined, // undefined = session
    },
  },
  Analytics: {
    autoSessionRecord: false,

    KadoAnalytics: {
      appName: 'dashboard',
      appVersion: version,
      region: DEFAULT_REGION,
      flushInterval: 500,
    },
  },
};
