import dayjs from 'dayjs';

type Announcement = {
  from?: string | null;
  id: string;
  isActive: boolean;
  message: string;
  to?: string | null;
};

export const isAnnouncementCurrentlyActive = (announcement: Announcement) =>
  announcement.isActive &&
  (dayjs(announcement.from).isBefore(new Date()) || !announcement.from) &&
  (dayjs(announcement.to).isAfter(new Date()) || !announcement.to);
