import { PIcon } from '@porsche-design-system/components-react';
// TODO: Update import after Jest update
// import { logger } from '@porsche-kado/logger/client';
import { logger, noticeError } from '@porsche-kado/logger/src/clientLogger';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { styled } from '../stitches.config';

export const ErrorBoundary = ({
  name,
  fallback = <Error />,
  children,
}: {
  name: string;
  fallback?: React.ReactElement;
  children: React.ReactNode;
}) => {
  return (
    <ReactErrorBoundary
      onError={(error, info) => {
        noticeError(error, { ...info, errorBoundary: name });
        logger.error({ name, error, info });
      }}
      fallback={fallback}
    >
      {children}
    </ReactErrorBoundary>
  );
};

const Error = () => {
  return (
    <Wrapper>
      <PIcon name="warning" size="x-large" />
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '9.375rem',
  color: '$contrastHigh',
  textAlign: 'center',
});
