import {
  PHeading,
  PLinkPure,
  PTextList,
  PTextListItem,
} from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { Paragraph } from '../components';

export const PrivacyPolicy = () => (
  <>
    <PHeading role="heading" size="large" aria-level={1} tag="h1">
      Privacy Policy
    </PHeading>
    <Spacer mb="$medium" />
    <PHeading size="medium" aria-level={2} tag="h2">
      KADO
    </PHeading>
    <Paragraph>
      We, Dr. Ing. h.c. F. Porsche AG (hereinafter referred to as &quot;we&quot;
      or &quot;Porsche AG&quot;), are happy about your interest in our online
      service Kado (hereinafter referred to as the &quot;Online Service&quot;).
      We take the protection of your personal data very seriously. Your personal
      data will be processed exclusively in accordance with the statutory
      provisions of data protection law, in particular the General Data
      Protection Regulation (hereinafter referred to as &quot;GDPR&quot;). With
      this Privacy Policy, we inform you about the processing of your personal
      data and about your rights as a data subject as affected in connection
      with the Online Service. For information on the processing of personal
      data in other areas, please refer to the respective specific privacy
      policies.
    </Paragraph>
    <Paragraph>
      If we refer to this Privacy Policy from external social media profiles,
      the following explanations apply only insofar as the processing takes
      place in our area of responsibility and insofar as no more specific and
      therefore prior information on data protection is provided in the context
      of such social media profiles.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      1. Controller and data protection officer
    </PHeading>
    <Paragraph>
      Responsible for the data processing as controller in terms of data
      protection law is:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
      Phone: +49 (0) 711 911-0
      <br />
      Email: info@porsche.de
    </Paragraph>
    <Paragraph>
      If you have any questions or suggestions regarding data protection, please
      feel free to contact us. You can reach our data protection officer as
      follows:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Data Protection Officer <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
    </Paragraph>
    <Paragraph>
      Contact:{' '}
      <PLinkPure
        icon="none"
        underline={true}
        href="https://www.porsche.com/privacy-contact/"
      >
        https://www.porsche.com/privacy-contact/
      </PLinkPure>
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      2. Subject of data protection
    </PHeading>
    <Paragraph>
      The subject of data protection is the protection of personal data. This is
      all information relating to an identified or identifiable natural person
      (so-called data subject). This includes information such as name, postal
      address, email address or telephone number, but also other information
      that may be generated when using the Online Service, in particular
      information about the beginning, end and extent of use as well as the
      transmission of your IP address.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      3. Purposes and legal basis of data processing
    </PHeading>
    <Paragraph>
      In the following, you will find an overview of the purposes and legal
      basis of data processing in connection with the Online Service. In any
      case, we process personal data in accordance with the legal requirements,
      even if in individual cases a different legal basis should be relevant
      than that stated below.
    </Paragraph>
    <Paragraph>
      The provision of personal data by you may be required by law or contract
      or may be necessary for the conclusion of a contract. We will point it out
      separately if you are obliged to provide personal data and what possible
      consequences the non-supply would then have (e.g. a loss of claims or our
      position not to provide the requested service without providing certain
      information). The use of the Online Service is generally possible without
      registration. The use of individual functions may require prior
      registration. Even if you use the Online Service without registration,
      personal data may still be processed.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.1 Performance of a contract and pre-contractual measures
    </PHeading>
    <Paragraph>
      We process your personal data if this is necessary for the performance of
      a contract to which you are a party or for the implementation of
      pre-contractual measures taken in response to your request. The data
      processing is based on Article 6 paragraph 1 letter b) GDPR. The purposes
      of processing include enabling the use of our specific products and
      services within the scope of the Online Service. Please also note the
      details in the respective documents describing our products and services
      further to this Privacy Policy.
    </Paragraph>
    <Paragraph>In particular, these are the following functions:</Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>Provision of the online</PTextListItem>
      </PTextList>
    </Paragraph>
    <Paragraph>
      service We process your personal data to the extent this is necessary for
      the provision of the online service. The data processing is based on
      Article 6 paragraph 1 letter b) GDPR or Section 26 par. 1 German Data
      Protection Act (Bundesdatenschutzgesetz) insofar as you are our
      contractual partner or employee, or is based on Article 6 paragraph 1
      letter f) GDPR insofar as we pursue the legitimate interest of supporting
      you in your tasks. Within the scope of the Online Service, you can plan
      and document appointments and tasks (Action Items) and search stored
      Action Items. Your personal user profile is based on your profile for the
      Porsche Partner Network (PPN). You can assign Action Items to yourself or
      other and track their processing and status.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.2 Compliance with legal obligations
    </PHeading>
    <Paragraph>
      We process your personal data to comply with legal obligations to which we
      are subject. The data processing is based on Article 6 paragraph 1 letter
      c) GDPR. These obligations may arise, for example, from commercial, tax,
      money laundering, financial or criminal law. The purposes of the
      processing result from the respective legal obligation; as a rule, the
      processing serves the purpose of complying with state control and
      information obligations.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.3 Safeguarding of legitimate interests
    </PHeading>
    <Paragraph>
      We also process your personal data to pursue the legitimate interests of
      ourselves or third parties, unless your rights, which require the
      protection of your personal data, outweigh these interests. The data
      processing is based on Article 6 paragraph 1 letter f) GDPR. The
      processing to safeguard legitimate interests is carried out for the
      following purposes or to safeguard the following interests.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Further development of products, services and support offers as well
          as other measures to control business transactions and processes;
        </PTextListItem>
        <PTextListItem>
          Improvement of product quality, elimination of errors and
          malfunctions, among other things by means of analysis of vehicle data
          and customer feedback;
        </PTextListItem>
        <PTextListItem>
          Processing of data in a central prospective customer and customer care
          platform as well as upstream and downstream systems for customer
          retention and sales purposes;
        </PTextListItem>
        <PTextListItem>
          Needs analysis and customer segmentation, e.g. calculation and
          evaluation of affinities, preferences and customer potential;
        </PTextListItem>
        <PTextListItem>
          Handling of non-contractual inquiries and concerns;
        </PTextListItem>
        <PTextListItem>Handling of warranty and goodwill cases;</PTextListItem>
        <PTextListItem>
          Risk management and coordination of recall actions;
        </PTextListItem>
        <PTextListItem>
          Credit assessment through data exchange with credit agencies (e.g.
          SCHUFA);
        </PTextListItem>
        <PTextListItem>
          Ensuring legally compliant actions, prevention of and protection
          against legal violations (especially criminal offences), assertion of
          and defense against legal claims, internal and external compliance
          measures;
        </PTextListItem>
        <PTextListItem>
          Ensuring availability, operation and security of technical systems as
          well as technical data management;
        </PTextListItem>
        <PTextListItem>
          Answering and evaluation of contact requests and feedback.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="small" aria-level={4} tag="h4">
      3.3.1 Retrieval of the online offer
    </PHeading>
    <Paragraph>
      When you call up the Online Service, data relating to your end device and
      your use of the online offer are processed and stored in a so-called log
      file. This concerns in particular technical data such as date and time of
      access, duration of the visit, type of terminal device, operating system
      used, functions used, amount of data sent, IP address and referrer URL. We
      process this data to ensure technical operation and to determine and
      eliminate faults. In doing so, we pursue the interest of permanently
      ensuring technical operability. We do not use this data for the purpose of
      drawing conclusions about your person.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.4 Consent
    </PHeading>
    <Paragraph>
      We process your personal data on the basis of corresponding consent. The
      data processing is based on Article 6 paragraph 1 letter a) GDPR. If you
      give your consent, it is always for a specific purpose; the purposes of
      processing are determined by the content of your declaration of consent.
      You may revoke any consent you have given at any time, without affecting
      the legality of the processing that has taken place on the basis of the
      consent until revocation.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.5 Change of purpose
    </PHeading>
    <Paragraph>
      If we process your personal data for a purpose other than that for which
      the data was collected, beyond the scope of a corresponding consent or a
      mandatory legal basis, we will take into account, in accordance with
      Article 6 paragraph 4 GDPR, the compatibility of the original and the now
      pursued purpose, the nature of the personal data, the possible
      consequences of further processing for you and the guarantees for the
      protection of the personal data.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      3.6 Profiling
    </PHeading>
    <Paragraph>
      We do not carry out automated decision making or profiling in accordance
      with Article 22 GDPR. Profiling is only carried out to protect our
      legitimate interests as described above.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      4. Access authorizations in the end device
    </PHeading>
    <Paragraph>
      To the extent functions of the Online Service require the granting of
      authorization to access your end device (e.g. access to location data or
      photos), the granting of these authorizations is voluntary. However, if
      you wish to use the corresponding functions, you must grant the
      appropriate authorizations, otherwise you will not be able to use these
      functions. The permissions remain active as long as you have not reset
      them in your device by deactivating the respective setting.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      5.Cookies and comparable technologies
    </PHeading>
    <Paragraph>
      We use cookies and comparable technologies in connection with the Online
      Service which serve to communicate with your end device and exchange
      stored information (hereinafter collectively referred to as
      &quot;Cookies&quot;). These Cookies are primarily used to make the
      functions of the Online Service usable. General examples in which the use
      of Cookies is technically required in this sense are the storage of a
      language selection, login data or a shopping or watch list. Accordingly,
      technically required Cookies may be used by us to enable the processing
      described in section 3.1 and to ensure the proper and secure operation of
      the Online Service. The data processing is then carried out on the basis
      of Article 6 paragraph 1 letters b) and f) GDPR, as it is necessary to
      implement the functions you have selected or to protect our legitimate
      interest in the functionality of the Online Service.
    </Paragraph>
    <Paragraph>
      Insofar as we should also use Cookies in order to analyse the use of the
      Online Service and to be able to target it to your interests and, if
      necessary, to provide you with interest-based content and advertisements,
      this is done exclusively on the basis of your voluntary consent in
      accordance with Article 6 paragraph 1 letter a) GDPR. You will then have
      the opportunity to make the appropriate settings within the Online Service
      via{' '}
      <PLinkPure icon="none" underline={true}>
        <Link to="/cookie-policy">the consent management</Link>
      </PLinkPure>
      . You may revoke any consent you have given at any time with effect for
      the future. Further information on Cookies and their function in detail as
      well as on setting and revocation options can be found directly in the
      corresponding areas of the consent management. Please note that we only
      make available{' '}
      <PLinkPure icon="none" underline={true}>
        <Link to="/cookie-policy">the consent management</Link>
      </PLinkPure>
      . in the context of the Online Service if, in addition to the
      above-mentioned technically required Cookies, consent- based Cookies are
      to be used.
    </Paragraph>
    <Paragraph>
      If you do not wish to use Cookies in general, you can also prevent their
      storage by adjusting the settings of your end device accordingly. Stored
      Cookies can be deleted at any time in the system settings of your terminal
      device. Please note that blocking certain types of Cookies can lead to
      impaired use of the Online Service.
    </Paragraph>
    <Paragraph>
      Furthermore, we use other technologies which, like cookies, serve to
      ensure the secure and user-friendly usability of the website (e.g. by
      protecting against misuse or evaluating usage). Technically, these
      additional technologies differ from cookies, as they do not store any
      information on your end device or access information already stored there.
      Insofar as these additional technologies process data that is subject to
      data protection law (e.g. IP addresses), we process this data on the basis
      of Article 6 paragraph 1 letters b) and f) GDPR to provide the website, to
      ensure technical operation and for the purpose of identifying and
      eliminating faults. In doing so, we also pursue the interest of
      permanently ensuring the technical functionality of the website, improving
      its performance and optimising the user experience. When you access our
      website, this data is automatically processed. Without the provision of
      this data, you will not be able to use our website. We do not use this
      data for the purpose of drawing conclusions about your person or identity.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      6. Integrated third-party services
    </PHeading>
    <Paragraph>
      Insofar as we integrate services of other providers within the scope of
      the Online Service in order to provide you with certain content or
      functions (e.g. playing videos or route planning) and we process personal
      data in the process, this is done on the basis of Article 6 paragraph 1
      letters b) and f) GDPR. This is because the data processing is then
      necessary to implement the functions you have selected or to protect our
      legitimate interest in an optimal range of functions of the Online
      Service. Insofar as Cookies may be used within the scope of these
      third-party services, the statements under Section 5 apply. Please also
      refer to the privacy policy of the respective third-party provider with
      regard to the third-party services.
    </Paragraph>
    <Paragraph>
      Services of other providers which we integrate or to which we refer are
      provided by the respective third parties. We have no influence on the
      content and function of the third-party services and are generally not
      responsible for the processing of your personal data by their providers,
      unless the third-party services are completely designed on our behalf and
      then integrated by us on our own responsibility. Insofar as the
      integration of a third-party service results in us establishing joint
      processes with its provider, we will define with this provider in an
      agreement on joint controllership pursuant to Article 26 GDPR how the
      respective tasks and responsibilities in the processing of personal data
      are structured and who fulfils which data protection obligations. Insofar
      as Cookies are to be set on the basis of your consent, you will receive
      further information on the responsibility for setting these Cookies and
      any associated third-party services in the corresponding area of the
      consent management.
    </Paragraph>
    <Paragraph>
      Unless otherwise stated, profiles on social media are generally only
      included in the Online Service as a link to the corresponding third-party
      services. After clicking on the integrated text/image link, you will be
      redirected to the offer of the respective social media provider. After the
      redirection, personal data may be collected directly by the third-party
      provider. If you are logged in to your user account of the respective
      social media provider, the provider may be able to assign the collected
      information of the specific visit to your personal user account. If you
      interact via a &quot;share&quot; button of the respective social media
      provider, this information can be stored in the personal user account and
      published if necessary. If you want to prevent the collected information
      from being assigned directly to your user account, you must log out before
      clicking the included text/image link.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      7. Sources and categories of data in case of third party collection
    </PHeading>
    <Paragraph>
      We also process personal data that we receive from third parties or from
      publicly accessible sources. Below you will find an overview of the
      corresponding sources and the categories of data obtained from these
      sources.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Group companies, Porsche dealers and service companies: specific data,
          e.g. information on the products you use and on your interests;
        </PTextListItem>
        <PTextListItem>
          Cooperation partners and service providers: specific data, e.g.
          creditworthiness data from credit agencies.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      8. Recipients of personal data
    </PHeading>
    <Paragraph>
      Within our company, only those persons who need your personal data for the
      respective purposes mentioned have access to it. Your personal data will
      only be passed on to external recipients if we have legal permission to do
      so or have your consent. Below you will find an overview of the
      corresponding recipients:
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Commissioned processors: Group companies or external service
          providers, for example in the areas of technical infrastructure and
          maintenance, which are carefully selected and reviewed. The processors
          may only use the data in accordance with our instructions.
        </PTextListItem>
        <PTextListItem>
          Public bodies: Authorities and state institutions, such as tax
          authorities, public prosecutors&#39; offices or courts, to which we
          (must) transfer personal data, e.g. to fulfil legal obligations or to
          protect legitimate interests.
        </PTextListItem>
        <PTextListItem>
          Private bodies: Group companies, Porsche sales companies (incl.
          companies offering services in the area of Porsche Connect and Smart
          Mobility), dealer and service operations, cooperation partners,
          service providers (not bound by instructions) or commissioned persons
          such as Porsche Centres and Porsche Service Centres, financing banks,
          credit agencies or transport service providers.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      9. Data processing in third countries
    </PHeading>
    <Paragraph>
      If a data transfer takes place to entities whose registered office or
      place of data processing is not located in a member state of the European
      Union, another state party to the Agreement on the European Economic Area
      or a state for which an adequate level of data protection has been
      determined by a decision of the European Commission, we will ensure prior
      to the transfer that either the data transfer is covered by a statutory
      permit, that guarantees for an adequate level of data protection with
      regard to the data transfer are in place (e.g., through the agreement of
      contractual warranties, officially recognized regulations or binding
      internal data protection regulations at the recipient), or that you have
      given your consent to the data transfer.
    </Paragraph>
    <Paragraph>
      If the data is transferred on the basis of Articles 46, 47 or 49 paragraph
      1, subparagraph 2 GDPR, you can obtain from us a copy or reference to the
      availability of the guarantees for an adequate level of data protection in
      relation to the data transfer. Please use the information provided under
      Section 1.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      10. Storage duration, erasure of data
    </PHeading>
    <Paragraph>
      We store your personal data, if there is legal permission to do so, only
      as long as necessary to achieve the intended purposes or as long as you
      have not revoked your consent. In the event of an objection to processing,
      we will delete your personal data, unless further processing is still
      permitted by law. We will also delete your personal data if we are obliged
      to do so for other legal reasons. Applying these general principles, we
      will usually delete your personal data immediately
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          after the legal permission has ceased to apply and provided that no
          other legal basis (e.g. commercial and tax law retention periods)
          intervenes. If the latter applies, we will delete the data after the
          other legal basis has ceased to apply;
        </PTextListItem>
        <PTextListItem>
          if your personal data is no longer required for the purposes we pursue
          and no other legal basis (e.g. commercial and tax law retention
          periods) intervenes. If the latter is the case, we will delete the
          data after the other legal basis has ceased to apply.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      11. Rights of data subjects
    </PHeading>
    <Paragraph>
      <strong>Right to access:</strong> You have the right to receive
      information about your personal data stored by u
    </Paragraph>
    <Paragraph>
      <strong>Right to rectification and erasure:</strong> You can demand that
      we correct incorrect data and, if the legal requirements are met, delete
      your data.
    </Paragraph>
    <Paragraph>
      <strong>Restriction of processing:</strong> You can demand that we
      restrict the processing of your data, provided that the legal requirements
      are met.
    </Paragraph>
    <Paragraph>
      <strong>Data portability:</strong>If you have provided us with data on the
      basis of a contract or consent, you may, if the legal requirements are
      met, demand that the data you have provided us with are handed over in a
      structured, common and machine-readable format or that we transfer it to
      another controller.
    </Paragraph>
    <Paragraph>
      <strong>Objection:</strong> You have the right to object at any time to
      data processing by us based on the safeguarding of legitimate interests
      for reasons arising from your particular situation. If you make use of
      your right to object, we will stop processing the data unless we can prove
      compelling reasons for further processing worthy of protection which
      outweigh your rights and interests.
    </Paragraph>
    <Paragraph>
      <strong>Objection to direct marketing:</strong> If we process your
      personal data for the purpose of direct marketing, you have the right to
      object to our processing of your data for this purpose at any time. If you
      exercise your right to object, we will stop processing your data for this
      purpose.
    </Paragraph>
    <Paragraph>
      <strong>Revocation of consent:</strong> If you have given us your consent
      to process your personal data, you can revoke it at any time with effect
      for the future. The legality of the processing of your data until
      revocation remains unaffected.
    </Paragraph>
    <Paragraph>
      <strong>Right to lodge a complaint with a supervisory authority:</strong>{' '}
      You can also lodge a complaint with the competent supervisory authority if
      you believe that the processing of your data violates applicable law. You
      can contact the supervisory authority responsible for your place of
      residence or your country or the supervisory authority responsible for us.
    </Paragraph>
    <Paragraph>
      <strong>Your contact with us and the exercise of your rights:</strong>{' '}
      Furthermore, you can contact us free of charge if you have questions
      regarding the processing of your personal data and your rights as a data
      subject. Please contact us at https://www.porsche.com/privacy-contact/ or
      by letter mail to the address provided under Section 1. Please make sure
      that we can definitely identify you. If you revoke your consent, you can
      alternatively choose the contact method that you used when you gave your
      consent.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      12. Effective date
    </PHeading>
    <Paragraph>
      The latest version of this Privacy Policy applies. Version 6 - This
      version dates from 30.08.2024.
    </Paragraph>
  </>
);
