import { PWordmark } from '@porsche-design-system/components-react';
import { styled } from '../stitches.config';

const WordmarkContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  alignItems: 'end',
  gap: '$medium',
  paddingTop: '$large',
  paddingBottom: '$large',

  '&:before': {
    content: '',
    display: 'block',
  },
  '&:after': {
    content: '',
    display: 'block',
  },
});

export const Header = () => {
  return (
    <WordmarkContainer>
      <PWordmark />
    </WordmarkContainer>
  );
};
