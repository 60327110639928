import { styled } from '../stitches.config';

export const Indicator = ({
  label,
  active,
  disabled,
  children,
  ...props
}: {
  label?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Container>
      <Dot disabled={disabled} active={active} {...props}>
        {label}
      </Dot>
      {children}
    </Container>
  );
};

const Container = styled('div', {
  position: 'relative',
});

const Dot = styled('div', {
  position: 'absolute',
  left: '60%',
  top: -6,
  minWidth: 12,
  fontSize: 11,
  lineHeight: 1.4,
  color: '$text',
  background: '$backgroundBase',
  border: '1px solid $backgroundShading',
  borderRadius: '50%',
  zIndex: 1,
  userSelect: 'none',
  pointerEvents: 'none',
  padding: '1px 3px',
  textAlign: 'center',

  '&:empty': {
    top: -4,
    right: -4,
    width: 14,
    height: 14,
    padding: 0,
  },

  variants: {
    disabled: {
      true: {
        visibility: 'hidden',
      },
    },

    active: {
      true: {
        color: '$highlightContrast',
        background: '$highlight',
        borderColor: '$highlightShading',
      },
    },
  },
});
