import { PHeading } from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import { ContactCard, Paragraph } from '../components';

const personsInCharge = [
  {
    name: 'Patrick Ott',
    role: 'KADO Project Manager',
    phone: '+49 170 911 – 8963',
    mail: 'patrick.ott@porsche.de',
  },
  {
    name: 'General KADO Support',
    mail: 'kado@porsche.de',
  },
];

export const Help = () => {
  const { t } = useTranslation();

  return (
    <>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {t('helpArea.title')}
      </PHeading>

      <Spacer mb="$medium" />

      <PHeading size="medium" tag="h2">
        {t('helpArea.personsInCharge')}
      </PHeading>
      <Paragraph>{t('helpArea.personsInChargeIntro')}</Paragraph>
      {personsInCharge.map((person) => (
        <ContactCard key={person.name} {...person} />
      ))}
    </>
  );
};
