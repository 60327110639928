import { PButtonPure } from '@porsche-design-system/components-react';
import { styled } from '@stitches/react';
import { Spacer } from './Spacer';

const Bar = styled('div', {
  display: 'grid',
  border: '2px solid $black',
  gridTemplateColumns: '1fr auto',
  overflow: 'hidden',

  variants: {
    isOpen: {
      true: {
        borderTopLeftRadius: '$small',
        borderTopRightRadius: '$small',
        borderBottomWidth: 0,
      },
      false: {
        borderRadius: '$small',
      },
    },
  },
});

const Toggle = styled('div', {
  padding: '$small $medium',
  cursor: 'pointer',
});

const Reset = styled('div', {
  padding: '$small $medium',
  cursor: 'pointer',
  borderRadius: '$medium',
});

const Content = styled('div', {
  padding: '$medium',
  marginBottom: '$medium',
  border: '2px solid $black',
  borderTopWidth: 0,
  borderBottomLeftRadius: '$small',
  borderBottomRightRadius: '$small',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$medium',

  '@s': {
    gridTemplateColumns: '1fr 1fr',
  },
});

export const FilterBar = ({
  isOpen,
  onToggle,
  onReset,
  activeFilterText,
  i18n,
  children,
}: {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  onReset: () => void;
  activeFilterText?: React.ReactNode;
  i18n: {
    add: string;
    reset: string;
  };
  children: React.ReactNode;
}) => {
  return (
    <>
      <Spacer mt="$medium" />

      <Bar isOpen={isOpen}>
        <Toggle data-testid="filter-toggle" onClick={() => onToggle(!isOpen)}>
          {activeFilterText ? (
            activeFilterText
          ) : (
            <PButtonPure icon="filter">{i18n.add}</PButtonPure>
          )}
        </Toggle>
        <Reset onClick={onReset}>
          <PButtonPure icon="none">{i18n.reset}</PButtonPure>
        </Reset>
      </Bar>

      {isOpen && <Content>{children}</Content>}

      <Spacer mb="$medium" />
    </>
  );
};
