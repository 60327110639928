import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import {
  PlateRenderElementProps,
  getPluginType,
  useEventPlateId,
  usePlateEditorState,
} from '@udecode/plate-common';
import BoldIcon from '../../../../icons/bold.svg?react';
import ItalicIcon from '../../../../icons/italic.svg?react';
import UnderlineIcon from '../../../../icons/underline.svg?react';
import { ToolbarMarkButton } from '../ToolbarMarkButton';

const Bold = ({ attributes, nodeProps, children }: PlateRenderElementProps) => (
  <strong {...attributes} {...nodeProps}>
    {children}
  </strong>
);

const Underline = ({
  attributes,
  nodeProps,
  children,
}: PlateRenderElementProps) => (
  <u {...attributes} {...nodeProps}>
    {children}
  </u>
);

const Italic = ({
  attributes,
  nodeProps,
  children,
}: PlateRenderElementProps) => (
  <em {...attributes} {...nodeProps}>
    {children}
  </em>
);

export const basicMarksComponents = {
  [MARK_BOLD]: Bold,
  [MARK_UNDERLINE]: Underline,
  [MARK_ITALIC]: Italic,
};

export const BasicMarksToolbarButtons = ({ id }: { id?: string }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <>
      <ToolbarMarkButton
        id={id}
        icon={BoldIcon}
        type={getPluginType(editor, MARK_BOLD)}
      />
      <ToolbarMarkButton
        id={id}
        icon={UnderlineIcon}
        type={getPluginType(editor, MARK_UNDERLINE)}
      />
      <ToolbarMarkButton
        id={id}
        icon={ItalicIcon}
        type={getPluginType(editor, MARK_ITALIC)}
      />
    </>
  );
};
