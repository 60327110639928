import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { Imprint } from '../pages';

export const Route = createFileRoute('/imprint')({
  component: () => <Imprint />,
  beforeLoad: () => {
    return {
      breadcrumb: () => (
        <Trans i18nKey="footer.imprint" ns={COMMON_NAMESPACE} />
      ),
    };
  },
});
