import {
  AnalyticsProvider,
  AWSKinesisFirehoseProvider,
} from '@aws-amplify/analytics';
import type { KinesisAnalyticsEvent } from '@aws-amplify/analytics/lib-esm/types';
// TODO: Update import after Jest update
// import { logger } from '@porsche-kado/logger/client';
import { logger } from '@porsche-kado/logger/src/clientLogger';

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

type ConfigureProps = {
  appName: string;
  region: string;
  appVersion: string;
  disabled?: boolean;
  bufferSize?: number;
  flushSize?: number;
  flushInterval?: number;
  resendLimit?: number;
};

type RecordProps = {
  event: KinesisAnalyticsEvent;
  provider: string;
};

export type KadoAnalyticsConfigureProps = {
  KadoAnalytics: ConfigureProps;
};

export default class KadoAnalyticsProvider
  extends AWSKinesisFirehoseProvider
  implements AnalyticsProvider
{
  public configure(config: ConfigureProps): object {
    return super.configure(config);
  }

  public record(props: RecordProps): Promise<boolean> {
    const { appName } = this._config;

    const params = {
      ...props,
      event: {
        ...props.event,
        data: {
          ...(typeof props.event.data === 'string'
            ? JSON.parse(props.event.data)
            : props.event.data),
          app_name: appName,
          event_timestamp: Date.now() / 1000,
        },
      },
    };

    if (process.env.NODE_ENV === 'development' || window.Cypress) {
      logger.debug(this.getProviderName(), params);
      return Promise.resolve(true);
    }

    return super.record(params);
  }

  public getCategory(): string {
    return 'Analytics';
  }

  public getProviderName(): string {
    return 'KadoAnalytics';
  }
}
