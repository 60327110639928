import { subject } from '@casl/ability';
import { PSelectWrapper } from '@porsche-design-system/components-react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../config/i18n';
import { useAbilityContext } from '../context';
import { SupportRequestStatus } from '../graphql';

export const SupportRequestStatusSelect = (
  props: React.SelectHTMLAttributes<HTMLSelectElement>,
) => {
  const { t } = useTranslation(NAMESPACES);
  const ability = useAbilityContext();

  const values = Object.values(SupportRequestStatus).filter(
    (status) =>
      props.value === status ||
      ability.can(
        'update',
        subject('SupportRequest', {
          statusFrom: props.value,
          statusTo: status,
        }),
      ),
  );

  return (
    <PSelectWrapper
      role="combobox"
      label={t('common:status')}
      aria-label={t('common:status')}
    >
      <select {...props}>
        {values.map((value) => (
          <option key={value} value={value}>
            {t(`supportRequest.statusValues.${value}`)}
          </option>
        ))}
      </select>
    </PSelectWrapper>
  );
};
