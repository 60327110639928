import { createBasicMarksPlugin } from '@udecode/plate-basic-marks';
import { createPlugins } from '@udecode/plate-core';
import { createListPlugin } from '@udecode/plate-list';
import { createImagePlugin } from '@udecode/plate-media';
import { createMentionPlugin, MentionPlugin } from '@udecode/plate-mention';
import { useMemo } from 'react';
import {
  basicMarksComponents,
  createPastePlugin,
  imageComponents,
  listComponents,
  mentionComponents,
} from './plugins';

export const usePlugins = <
  TMentionComponentValue extends { type: string },
  TMentionComponentPropsExtension,
>({
  onUploadImage,
  includeImages,
  includeImageDownloadLink,
  MentionLabel,
  mentionProps,
  onPasteMention,
  i18n,
}: {
  includeImages?: boolean;
  includeImageDownloadLink?: boolean;
  onUploadImage?: (
    fileOrData: File | string,
    opt: { onProgress?: (value: number) => void },
  ) => Promise<string>;

  MentionLabel?: React.FunctionComponent<
    { value: TMentionComponentValue } & TMentionComponentPropsExtension
  >;
  mentionProps?: TMentionComponentPropsExtension;
  onPasteMention?: (mention: TMentionComponentValue) => boolean;

  i18n?: {
    captionPlaceholder?: string;
    gdprTooltip?: string;
    dropzoneDescription?: string;
    imageUploadHeading?: string;
    dragDescription?: string;
    uploadButtonText?: string;
    resetButtonText?: string;
    uploadErrorHeading?: string;
    uploadErrorDescription?: string;
    imagePlaceholder?: string | ((url: string) => string);
  };
}) => {
  return useMemo(
    () =>
      createPlugins(
        [
          createBasicMarksPlugin(),
          createListPlugin(),
          createImagePlugin({
            options: {
              uploadImage: onUploadImage
                ? (data) => onUploadImage(data as string, {})
                : undefined,
            },

            props: {
              captionPlaceholder: i18n?.captionPlaceholder,
              includeImages,
              includeImageDownloadLink,
            },
          }),
          createMentionPlugin<
            MentionPlugin<undefined> & {
              MentionLabel?: React.FunctionComponent<
                {
                  value: TMentionComponentValue;
                } & TMentionComponentPropsExtension
              >;
              mentionProps?: TMentionComponentPropsExtension;
            }
          >({
            options: {
              trigger: '@',
              query: () => false,
              insertSpaceAfterMention: true,
              MentionLabel,
              mentionProps,
            },
          }),
          createPastePlugin({
            options: {
              onPasteMention,
            },
          }),
        ],
        {
          components: {
            ...basicMarksComponents,
            ...listComponents,
            ...imageComponents,
            ...mentionComponents,
          },
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
