import {
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  autoUpdate,
  flip,
  hide,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { dropShadowMediumStyle } from '@porsche-design-system/components-react/styles';
import { Fragment, useRef, useState } from 'react';
import { styled, theme } from '../stitches.config';

const Content = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$medium',
  zIndex: '$tooltip',
  maxWidth: '40%',
  ...dropShadowMediumStyle,
});

const Trigger = styled('span');

const Spacer = styled('div', {
  display: 'inline-block',
  padding: '$small $medium',
});

export const Tooltip = ({
  children,
  className,
  content,
  placement = 'top',
  safePolygonClose,
  disabled,
  withPortal,
}: {
  children: React.ReactNode;
  className?: string;
  content: React.ReactNode;
  placement?: Placement;
  safePolygonClose?: boolean;
  disabled?: boolean;
  withPortal?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement | null>(null);

  const {
    refs,
    floatingStyles,
    context,
    placement: floatingPlacement,
    middlewareData,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
      arrow({ element: arrowRef }),
      hide(),
    ],
  });

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygonClose ? safePolygon() : null,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context, {
    bubbles: {
      escapeKey: true,
    },
  });
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const MaybePortal = withPortal ? FloatingPortal : Fragment;

  return (
    <>
      <Trigger
        className={className}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </Trigger>

      {isOpen && !disabled && (
        <MaybePortal>
          <Content
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              visibility: middlewareData.hide?.referenceHidden
                ? 'hidden'
                : 'visible',
            }}
            {...getFloatingProps()}
          >
            <Spacer>{content}</Spacer>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill={theme.colors.white.toString()}
              staticOffset={
                floatingPlacement.endsWith('-start') ||
                floatingPlacement.endsWith('-end')
                  ? '10%'
                  : null
              }
            />
          </Content>
        </MaybePortal>
      )}
    </>
  );
};
