import {
  focusEditor,
  isMarkActive,
  toggleMark,
  ToggleMarkPlugin,
  useEventPlateId,
  usePlateEditorState,
  Value,
  WithPlatePlugin,
} from '@udecode/plate-common';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarMarkButton = <V extends Value>({
  id,
  icon: Icon,
  type,
  clear,
}: {
  id?: string;
  icon: React.ComponentType;
  type: WithPlatePlugin<V>['type'];
  clear?: ToggleMarkPlugin['clear'];
}) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <ToolbarButton
      active={!!editor?.selection && isMarkActive(editor, type)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        toggleMark(editor, { key: type, clear });
        focusEditor(editor);
      }}
    >
      <Icon />
    </ToolbarButton>
  );
};
