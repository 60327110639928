import type { TDescendant } from '@udecode/plate-common';
import { Node, Text } from 'slate';

export const transformRichTextToString = (
  nodes: TDescendant[],
  typeResolver?: { type: string; resolveTo: (node: TDescendant) => string }[],
): string =>
  nodes
    .map((n) => {
      if (Text.isText(n)) {
        return Node.string(n);
      }

      const resolver = typeResolver?.find(({ type }) => n.type === type);
      if (resolver) {
        return resolver.resolveTo(n);
      }

      return transformRichTextToString(n.children, typeResolver);
    })
    .join('');
