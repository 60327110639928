import { PSpinner } from '@porsche-design-system/components-react';
import { AsyncSelect, AsyncSelectProps } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import {
  MultiValueGenericProps,
  OnChangeValue,
  SingleValueProps,
  components,
} from 'react-select';
import {
  PersonsQuery,
  PersonsQueryVariables,
  usePersonByIdQuery,
  usePersonsQuery,
} from '../../graphql';

export type PersonSelectOption = {
  id: number;
  name?: string;
};

type Person = PersonsQuery['persons']['docs'][0];
type Filter = NonNullable<PersonsQueryVariables['filter']>;

const mapToOptions = (persons: Person[]) =>
  persons.map(({ id, name, organization }) => ({
    id,
    name,
    appendix: organization.name,
    organization: organization,
  }));

const MultiValueLabel = (props: MultiValueGenericProps<PersonSelectOption>) => {
  const { data, isLoading, fetchStatus } = usePersonByIdQuery(
    { id: props.data.id },
    {
      enabled: props.children?.toString().length === 0,
    },
  );
  const { MultiValueLabel } = components;

  if (isLoading && fetchStatus !== 'idle') {
    return (
      <MultiValueLabel {...props}>
        <PSpinner size="inherit" style={{ height: '26px' }} />
      </MultiValueLabel>
    );
  }

  if (data?.person?.name) {
    return <MultiValueLabel {...props}>{data.person.name}</MultiValueLabel>;
  }

  return <MultiValueLabel {...props} />;
};

const SingleValue = (props: SingleValueProps<PersonSelectOption>) => {
  const option = props.getValue()[0];

  const { data, isLoading, fetchStatus } = usePersonByIdQuery(
    { id: option.id },
    {
      enabled: props.children?.toString().length === 0,
    },
  );
  const { SingleValue } = components;

  if (isLoading && fetchStatus !== 'idle') {
    return (
      <SingleValue {...props}>
        <PSpinner size="inherit" style={{ height: '26px' }} />
      </SingleValue>
    );
  }

  if (data?.person?.name) {
    return <SingleValue {...props}>{data.person.name}</SingleValue>;
  }

  return <SingleValue {...props} />;
};

export const PersonSelect = <IsMulti extends boolean = false>({
  preselectSingleOption,
  filter,
  ...props
}: AsyncSelectProps<PersonSelectOption, IsMulti> & {
  preselectSingleOption?: boolean;
  filter?: Filter;
}): JSX.Element => {
  const queryClient = useQueryClient();

  const defaultData = queryClient.getQueryData<PersonsQuery>(
    usePersonsQuery.getKey({
      searchString: '',
      filter,
    }),
  );

  const loader = useCallback(
    async (searchString: string, filter?: Filter) => {
      const variables = { searchString, filter };

      const {
        persons: { docs },
      } = await queryClient.fetchQuery(
        usePersonsQuery.getKey(variables),
        usePersonsQuery.fetcher(variables),
      );

      return mapToOptions(docs);
    },
    [queryClient],
  );

  const debouncedLoader = debounce((inputValue: string, callback) => {
    loader(inputValue, filter).then((options) => {
      callback(options);

      if (
        preselectSingleOption &&
        !inputValue &&
        !props.value &&
        options.length === 1
      ) {
        props.onChange?.(
          (props.isMulti
            ? [options[0]]
            : options[0]) as unknown as OnChangeValue<
            PersonSelectOption,
            IsMulti
          >,
          {
            action: 'select-option',
            option: options[0],
          },
        );
      }
    });
  }, 600);

  return (
    <AsyncSelect
      {...props}
      cacheOptions
      components={{
        MultiValueLabel,
        SingleValue,
      }}
      defaultOptions={
        defaultData ? mapToOptions(defaultData.persons.docs) : true
      }
      getOptionValue={(option) => `${option['id']}`}
      getOptionLabel={(option) => option.name ?? ''}
      loadOptions={debouncedLoader}
    />
  );
};
