import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { Help } from '../pages';

export const Route = createFileRoute('/help')({
  component: () => <Help />,
  beforeLoad: () => {
    return {
      breadcrumb: () => <Trans i18nKey="footer.help" ns={COMMON_NAMESPACE} />,
    };
  },
});
