import { PButton, PHeading } from '@porsche-design-system/components-react';
import {
  ActionGroup,
  RichText,
  Spacer,
  Spinner,
  styled,
} from '@porsche-kado/ui';
import { useParams, useSearch } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { ApplicationTags } from '../components';
import { DateOutput } from '../components/Output';
import { useFeatureArticleQuery } from '../graphql';

export const FeatureArticle = () => {
  const { t } = useTranslation();

  const { featureArticleId } = useParams({ from: '/news/$featureArticleId' });
  const search: { preview?: boolean } = useSearch({
    from: '/news/$featureArticleId',
  });

  const { data, isLoading } = useFeatureArticleQuery(
    {
      id: featureArticleId,
    },
    {
      onSuccess: (data) => {
        if (data?.featureArticle?.id && !search.preview) {
          // assume it is read if loaded
          localStorage.setItem(
            `kado.readFeatureArticle.${data.featureArticle.id}`,
            'true',
          );
        }
      },
    },
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!data?.featureArticle) {
    return null;
  }

  return (
    <Content>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {data.featureArticle.title}
      </PHeading>
      <Subtitle>
        <span>
          {data.featureArticle.publishedAt ? (
            <DateOutput date={data.featureArticle.publishedAt} />
          ) : (
            'n/a'
          )}
        </span>
        <span>{data.featureArticle.author.name}</span>
        {data.featureArticle.apps.length === 0 ? null : (
          <ApplicationTags apps={data.featureArticle.apps} />
        )}
      </Subtitle>
      <Spacer mb="$small" />
      <div>
        <RichText
          value={JSON.parse(data.featureArticle.article)}
          includeImages
        />
      </div>
      <ActionGroup>
        <PButton type="button" onClick={() => history.back()}>
          {t('back')}
        </PButton>
      </ActionGroup>
    </Content>
  );
};

const Content = styled('div', {
  maxWidth: '$content',
});

const Subtitle = styled('div', {
  color: '$contrastMedium',
  display: 'grid',
  gridAutoColumns: 'max-content',
  gap: '$small',
  gridAutoFlow: 'column',
  marginBottom: '$medium',
});
