import {
  PButton,
  PHeading,
  PIcon,
  PInlineNotification,
  PSpinner,
} from '@porsche-design-system/components-react';
import { Spacer, Spinner, styled } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { useTranslation } from 'react-i18next';
import {
  PpnMasterdataImportStatusQuery,
  usePpnMasterdataImportStatusQuery,
  useStartPpnMasterdataImportMutation,
} from '../../../graphql';

export const PpnMasterdataImportStatus = () => {
  const { t } = useTranslation();

  const { data: ppnMasterdataImportStatus, isLoading } =
    usePpnMasterdataImportStatusQuery(undefined, {
      refetchInterval: 5000,
      select: (data) => data.ppnMasterdataImportStatus,
    });

  const queryClient = useQueryClient();
  const {
    mutate: startImport,
    isLoading: isStarting,
    error: startImportError,
    reset: resetStartImport,
  } = useStartPpnMasterdataImportMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<PpnMasterdataImportStatusQuery>(
        usePpnMasterdataImportStatusQuery.getKey(),
        {
          ppnMasterdataImportStatus: data.startPpnMasterdataImport,
        },
      );
    },
  });

  return (
    <>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {t('admin.ppnMasterdataImport.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {ppnMasterdataImportStatus && (
            <div>
              <ImportStep
                label={t('admin.ppnMasterdataImport.import')}
                state="success"
                startedAt={ppnMasterdataImportStatus.startedAt}
                error={ppnMasterdataImportStatus?.error}
              />
              <ImportStep
                label={t('admin.ppnMasterdataImport.importGroups')}
                state={
                  ppnMasterdataImportStatus.groupsFinishedAt
                    ? 'success'
                    : ppnMasterdataImportStatus.error
                    ? 'error'
                    : 'loading'
                }
                finishedAt={ppnMasterdataImportStatus.groupsFinishedAt}
                error={ppnMasterdataImportStatus?.error}
              />
              <ImportStep
                label={t('admin.ppnMasterdataImport.importOrganizations')}
                state={
                  ppnMasterdataImportStatus.organizationsFinishedAt
                    ? 'success'
                    : ppnMasterdataImportStatus.groupsFinishedAt
                    ? ppnMasterdataImportStatus.error
                      ? 'error'
                      : 'loading'
                    : 'waiting'
                }
                finishedAt={ppnMasterdataImportStatus.organizationsFinishedAt}
                error={ppnMasterdataImportStatus?.error}
              />
              <ImportStep
                label={t('admin.ppnMasterdataImport.importPersons')}
                state={
                  ppnMasterdataImportStatus.personsFinishedAt
                    ? 'success'
                    : ppnMasterdataImportStatus.organizationsFinishedAt
                    ? ppnMasterdataImportStatus.error
                      ? 'error'
                      : 'loading'
                    : 'waiting'
                }
                finishedAt={ppnMasterdataImportStatus.personsFinishedAt}
                error={ppnMasterdataImportStatus?.error}
              />
              {(ppnMasterdataImportStatus.finishedAt ||
                ppnMasterdataImportStatus.personsFinishedAt) && (
                <ImportStep
                  label={t('admin.ppnMasterdataImport.import')}
                  state={
                    ppnMasterdataImportStatus.finishedAt ? 'success' : 'error'
                  }
                  finishedAt={ppnMasterdataImportStatus.finishedAt}
                  error={ppnMasterdataImportStatus?.error}
                />
              )}
            </div>
          )}

          <Spacer mt="$medium" />

          {ppnMasterdataImportStatus?.isRunning &&
            ppnMasterdataImportStatus?.error && (
              <>
                <PInlineNotification
                  heading={t('admin.ppnMasterdataImport.error')}
                  description={t(
                    'admin.ppnMasterdataImport.importStuckRunning',
                  )}
                  state="error"
                  dismissButton={false}
                />

                <Spacer mt="$medium" />
              </>
            )}

          <PButton
            onClick={() => startImport({})}
            type="button"
            loading={isStarting}
            disabled={ppnMasterdataImportStatus?.isRunning || isLoading}
          >
            {t('admin.ppnMasterdataImport.triggerImportFromPPN')}
          </PButton>

          {startImportError instanceof ClientError && (
            <>
              <Spacer mt="$medium" />

              <PInlineNotification
                heading={t('admin.ppnMasterdataImport.error')}
                description={
                  startImportError.response.errors?.[0].message ??
                  startImportError.message
                }
                state="error"
                onDismiss={resetStartImport}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const ImportStep = ({
  label,
  state,
  startedAt,
  finishedAt,
  error,
}: {
  label: string;
  state: 'success' | 'loading' | 'waiting' | 'error';
  startedAt?: string;
  finishedAt?: string | null;
  error?: string | null;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dateFormat = new Intl.DateTimeFormat(language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return (
    <>
      {state === 'error' && error ? (
        <PInlineNotification
          heading={label}
          description={error}
          state="error"
          dismissButton={false}
        />
      ) : (
        <ImportStepWrapper>
          {state === 'success' && (
            <PIcon
              name="success-filled"
              size="medium"
              color="notification-success"
            />
          )}
          {state === 'loading' && <PSpinner />}
          {state === 'waiting' && (
            <PIcon name="clock" size="medium" color="contrast-low" />
          )}
          {state === 'error' && (
            <PIcon
              name="warning-filled"
              size="medium"
              color="notification-error"
            />
          )}
          {label}
          <span>
            {startedAt &&
              t('admin.ppnMasterdataImport.startedAt', {
                date: dateFormat.format(new Date(startedAt)),
              })}
            {finishedAt &&
              t('admin.ppnMasterdataImport.finishedAt', {
                date: dateFormat.format(new Date(finishedAt)),
              })}
          </span>
        </ImportStepWrapper>
      )}
    </>
  );
};

const ImportStepWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xSmall',
});
