import {
  PButton,
  PCheckboxWrapper,
  PText,
} from '@porsche-design-system/components-react';
import { ActionGroup, Modal, Spacer } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../config/i18n';
import { PersonActionKind, usePersonContext } from '../context';
import {
  PersonQuery,
  usePersonQuery,
  useUpdatePersonMutation,
} from '../graphql';

type FormValues = {
  agree: boolean;
};

export const UserDeletionConsent = () => {
  const { t } = useTranslation(NAMESPACES);
  const {
    dispatch: dispatchPerson,
    state: { consents },
  } = usePersonContext();
  const queryClient = useQueryClient();
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(
    !!consents?.userDeletion,
  );

  const { mutateAsync: updatePerson, isLoading } = useUpdatePersonMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<PersonQuery>(
        usePersonQuery.getKey({
          ppnuid: data.updatePerson.ppnuid,
        }),
        (cache) =>
          cache?.person
            ? {
                person: {
                  ...cache.person,
                  consents: {
                    ...cache.person?.consents,
                    ...data.updatePerson.consents,
                  },
                },
              }
            : undefined,
      );
    },
  });

  const { register, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = async () => {
    const date = new Date().toISOString();

    await updatePerson(
      { input: { consents: { userDeletion: date } } },
      {
        onSuccess: () =>
          dispatchPerson({
            type: PersonActionKind.SetConsent,
            payload: { userDeletion: date },
          }),
      },
    );
  };

  if (hasConfirmed) {
    return null;
  }

  return (
    <Modal
      open={!consents?.userDeletion}
      dismissButton={false}
      disableBackdropClick
      size="initial"
      backdrop="shading"
      onMotionHiddenEnd={() => {
        setHasConfirmed(true);
      }}
      aria-label={t('common:consent.title')}
    >
      <Modal.Header>{t('common:consent.title')}</Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <PText>{t('common:consent.userDeletion.description')}</PText>

        <Spacer mb="$large" />

        <PCheckboxWrapper label={t('common:consent.userDeletion.agree')}>
          <input type="checkbox" {...register('agree', { required: true })} />
        </PCheckboxWrapper>

        <ActionGroup>
          <div />

          <PButton
            type="submit"
            icon="arrow-right"
            role="button"
            disabled={!formState.isValid}
            loading={isLoading}
          >
            {t('common:consent.userDeletion.goTo', { app: t('common:title') })}
          </PButton>
        </ActionGroup>
      </form>
    </Modal>
  );
};
