import { createFileRoute, useParams } from '@tanstack/react-router';
import { useSupportRequestQuery } from '../../graphql';
import { SupportRequest } from '../../pages';

export const Route = createFileRoute('/support/$supportRequestId')({
  component: () => <SupportRequest />,
  beforeLoad: () => {
    return {
      breadcrumb: () => <SupportRequestBreadcrumb />,
    };
  },
});

const SupportRequestBreadcrumb = () => {
  const { supportRequestId } = useParams({
    from: '/support/$supportRequestId',
  });
  const { data } = useSupportRequestQuery(
    {
      id: supportRequestId,
    },
    {
      select: (data) => data.supportRequest,
    },
  );

  return <>{data?.title}</>;
};
