import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { FeatureArticlesList } from '../../../pages';

export const Route = createFileRoute('/admin/news/_auth/')({
  component: () => <FeatureArticlesList />,
  beforeLoad: () => {
    return { breadcrumb: () => <Trans i18nKey="featureArticles" /> };
  },
});
