import pino from 'pino';

export const logger = pino({ level: process.env.LOG_LEVEL || 'info' });

export const noticeError = (error: Error, additionalInfo: unknown) => {
  if (typeof window !== 'undefined' && 'newrelic' in window) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.newrelic as any).noticeError?.(error, additionalInfo);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
