import { PButton, PButtonPure } from '@porsche-design-system/components-react';
import { Column } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { ActionGroup } from '../../ActionGroup';
import { DayPicker } from '../../DayPicker';
import { Popover } from '../../Popover';

import { FilterFn } from '@tanstack/react-table';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateBetweenFilterFn: FilterFn<any> = (row, columnId, value) => {
  const from = dayjs(value.from).startOf('day').toDate();
  const to = dayjs(value.to).endOf('day').toDate();

  return dayjs(row.getValue(columnId)).isBetween(from, to, null, '[]');
};

dateBetweenFilterFn.autoRemove = (val: string) => !val;

export const DateRangeFilter = <T,>({
  column,
  i18n,
}: {
  column: Column<T>;
  i18n: {
    buttonReset: string;
    buttonFilter: string;
  };
}) => {
  const filterValue = column.getFilterValue() as DateRange;
  const [isVisible, setVisibility] = useState(false);
  const getInitialValue = () => ({
    from: undefined,
    to: undefined,
  });

  const [range, setRange] = useState<DateRange | undefined>(getInitialValue());

  useEffect(() => {
    setRange(filterValue);
  }, [filterValue]);

  return (
    <Popover
      target={
        <PButtonPure
          type="button"
          active={!!filterValue}
          icon="filter"
          size="small"
          onClick={() => setVisibility((isVisible) => !isVisible)}
          role="button"
          hideLabel
        />
      }
      open={isVisible}
      onChange={setVisibility}
      placement="right-start"
    >
      <DayPicker selected={range} mode="range" onSelect={setRange} />
      <ActionGroup>
        <PButton
          type="button"
          variant="secondary"
          icon="close"
          onClick={() => {
            setRange(getInitialValue());
            column.setFilterValue(undefined); // Set undefined to remove the filter entirely
            setVisibility(false);
          }}
        >
          {i18n.buttonReset}
        </PButton>

        <PButton
          type="button"
          onClick={() => {
            column.setFilterValue(range || undefined); // Set undefined to remove the filter entirely
            setVisibility(false);
          }}
        >
          {i18n.buttonFilter}
        </PButton>
      </ActionGroup>
    </Popover>
  );
};
