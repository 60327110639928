import { styled } from '../stitches.config';

const LabelWrapper = styled('div', {
  paddingBottom: '$xSmall',
});

const Required = styled('span', {
  color: '$text',
});

const Name = styled('span', {
  variants: {
    isDisabled: {
      true: {
        color: '$disabled',
      },
    },
  },
});

export const Label = ({
  label,
  isRequired,
  isDisabled,
}: {
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}) => {
  if (!label) return null;

  return (
    <LabelWrapper>
      <Name isDisabled={isDisabled}>{label}</Name>
      {isRequired && (
        <>
          {` `}
          <Required>*</Required>
        </>
      )}
    </LabelWrapper>
  );
};
