import {
  PHeading,
  PText,
  PTextList,
  PTextListItem,
} from '@porsche-design-system/components-react';
import { Spacer, Spinner } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonInfoQuery } from '../../../graphql';

export const NetworkPlanning = () => {
  const { t } = useTranslation(NAMESPACES);

  const { personId } = useParams({ from: '/admin/person-info/$personId/$app' });

  const { data: person, isLoading } = usePersonInfoQuery(
    {
      id: +personId,
      includeDashboardAbility: false,
      includeFieldForceManagementAbility: false,
      includeGreenlandAbility: false,
      includeMultisensoryExperienceAbility: false,
      includeMysteryShoppingAbility: false,
      includeNetworkPlanningAbility: true,
      includePorscheStrategy2030Ability: false,
      includeRetailDesignAbility: false,
      includeSurveyAbility: false,
    },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const featureFlags = person?.ability.networkPlanning?.filter(
    (ability) =>
      ability.action === 'use' && ability.subject === 'NetworkPlanningFeature',
  );
  const hasFeatureFlags = featureFlags && (featureFlags?.length ?? 0) > 0;

  return (
    <>
      {isLoading && personId && !person && <Spinner />}

      {person && !isLoading && (
        <>
          <PHeading size="small" tag="h3">
            {t('common:featureFlags')}
          </PHeading>

          <Spacer mb="$small" />

          {hasFeatureFlags ? (
            <PTextList>
              {featureFlags.map((ability) => (
                <PTextListItem key={ability.conditions.name}>
                  {ability.conditions.name}
                </PTextListItem>
              ))}
            </PTextList>
          ) : (
            <PText>{t('common:noData')}</PText>
          )}
        </>
      )}
    </>
  );
};
