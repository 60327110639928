import { Column } from '@tanstack/react-table';
import { OrganizationFilter, SelectFilter, TextFilter } from '.';
import { MultiOrganizationSelectProps } from '../../MultiOrganizationSelect';
import { DateRangeFilter } from './DateRangeFilter';

export const ColumnFilter = <
  T,
  TOrganization extends { id: number; name: string },
>({
  column,
  i18n,
  organizationSelect,
}: {
  column: Column<T>;
  i18n: {
    filterLabel: string;
    optionAll: string;
    buttonReset: string;
    buttonFilter: string;
    actionSearch: string;
  };
  organizationSelect?: Omit<
    MultiOrganizationSelectProps<TOrganization>,
    'value' | 'onChange'
  >;
}): JSX.Element => {
  const { filterOptions, filterType } = column.columnDef.meta || {};

  if (filterType === 'select') {
    const options =
      filterOptions === 'auto'
        ? Array.from(column.getFacetedUniqueValues().keys())
            // Filter out empty values => they represent "All" option
            .filter((s) => s !== '' && s !== null && s !== undefined)
            .sort()
            .map((key) => ({
              label: key,
              value: key,
            }))
        : filterOptions;

    return <SelectFilter column={column} options={options || []} i18n={i18n} />;
  }

  if (filterType === 'date') {
    return <DateRangeFilter column={column} i18n={i18n} />;
  }

  if (filterType === 'organization' && organizationSelect) {
    return (
      <OrganizationFilter
        column={column}
        i18n={i18n}
        organizationSelect={organizationSelect}
      />
    );
  }

  return <TextFilter column={column} i18n={i18n} />;
};
