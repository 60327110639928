import { useEventPlateId, usePlateEditorState } from '@udecode/plate-core';
import RedoIcon from '../../../icons/redo.svg?react';
import UndoIcon from '../../../icons/undo.svg?react';
import { ToolbarButton } from './ToolbarButton';

export const HistoryToolbarButtons = ({ id }: { id?: string }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <>
      <ToolbarButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          editor.undo();
        }}
      >
        <UndoIcon />
      </ToolbarButton>
      <ToolbarButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          editor.redo();
        }}
      >
        <RedoIcon />
      </ToolbarButton>
    </>
  );
};
