import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { PageNotAvailable } from '@porsche-kado/ui';
import { Outlet } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useAbilityContext } from '../context';

export const RouteGuard = ({
  can: action,
  a: subject,
  children = <Outlet />,
}: {
  can: string | string[];
  a: string;
  children?: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();
  const ability = useAbilityContext();

  const actions = typeof action === 'string' ? [action] : action;
  if (actions.some((action) => ability.can(action, subject))) {
    return <>{children} </>;
  }

  return (
    <PageNotAvailable text={t('pageNotAvailable', { ns: COMMON_NAMESPACE })} />
  );
};
