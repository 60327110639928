import {
  AnyAbility,
  createMongoAbility,
  SubjectRawRule,
  SubjectType,
} from '@casl/ability';
import { createContextualCan } from '@casl/react';
import React from 'react';

type AbilityProviderProps = { children: React.ReactNode };

export type Rule = SubjectRawRule<
  string,
  SubjectType,
  Record<string | number | symbol, unknown>
>;

const ability = createMongoAbility();

const AbilityContext = React.createContext<AnyAbility>(ability);
const Can = createContextualCan(AbilityContext.Consumer);

const useAbilityContext = () => {
  const context = React.useContext(AbilityContext);
  if (context === undefined) {
    throw new Error('useAbilityContext must be used within a AbilityContext');
  }

  return context;
};

const AbilityProvider = ({ children }: AbilityProviderProps) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
);

export { AbilityContext, AbilityProvider, Can, useAbilityContext };
