import { gql } from '../lib/graphqlRequest';

export const organizations = gql`
  query organizations($searchString: String, $filter: OrganizationFilter) {
    organizations(searchString: $searchString, filter: $filter) {
      docs {
        id
        organizationId: id
        name
        partnerNumber
        status
      }
    }
  }
`;

export const organization = gql`
  query organization($id: BigInt) {
    organization(id: $id) {
      organizationId: id
      name
    }
  }
`;
