import { PHeading, PText } from '@porsche-design-system/components-react';
import { UAParser } from 'ua-parser-js';

import {
  AttachmentList,
  Divider,
  FileSize,
  RichText,
  Spacer,
  Spinner,
  styled,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplicationTags,
  DateTimeOutput,
  SupportRequestCommentForm,
  SupportRequestStatusTag,
} from '../components';
import { Comments } from '../components/Comments';
import { fileTypes } from '../config/fileTypes';
import { NAMESPACES } from '../config/i18n';
import {
  seenSupportRequests,
  seenSupportRequestsComments,
} from '../config/localStorageKeys';
import {
  DownloadReference,
  DownloadType,
  SupportRequestQuery,
  useCreateDownloadUrlMutation,
  useSupportRequestIdsQuery,
  useSupportRequestQuery,
} from '../graphql';

const ContentWrapper = styled('div', {
  maxWidth: '$content',
});

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  variants: {
    gap: {
      small: {
        gridGap: '$small',
      },
      medium: {
        gridGap: '$medium',
      },
    },
  },

  defaultVariants: {
    gap: 'medium',
  },
});

export const SupportRequest = () => {
  const { t } = useTranslation(NAMESPACES);

  const queryClient = useQueryClient();

  const { supportRequestId } = useParams({
    from: '/support/$supportRequestId',
  });

  const { mutateAsync: createDownloadUrl } = useCreateDownloadUrlMutation();

  const { data: supportRequest, isLoading } = useSupportRequestQuery(
    {
      id: supportRequestId,
    },
    {
      select: (data) => data.supportRequest,
    },
  );

  useEffect(() => {
    const updateLocalStorage = async () => {
      if (supportRequest) {
        localStorage.setItem(
          seenSupportRequests(supportRequest.id),
          dayjs().toISOString(),
        );

        supportRequest.comments.map((comment) => {
          localStorage.setItem(
            seenSupportRequestsComments(supportRequest.id, comment.id),
            dayjs().toISOString(),
          );
        });

        await queryClient.refetchQueries(useSupportRequestIdsQuery.getKey());
      }
    };
    updateLocalStorage();
  }, [supportRequest, queryClient]);

  return (
    <ContentWrapper>
      <PHeading role="heading" aria-level={2} tag="h2" size="large">
        {t('supportRequest.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      {isLoading ? (
        <Spinner />
      ) : !supportRequest ? (
        <PText>{t('common:noData')}</PText>
      ) : (
        <>
          <Grid>
            <div>
              <strong>{t('supportRequest.ticketId')}</strong>
            </div>
            <div>{supportRequest.id}</div>
            <div>
              <strong>{t('common:status')}</strong>
            </div>
            <div>
              <SupportRequestStatusTag status={supportRequest.status} />
            </div>
            <div>
              <strong>{t('common:date')}</strong>
            </div>
            <div>
              <DateTimeOutput date={supportRequest.createdAt} />
            </div>
            <div>
              <strong>{t('supportRequest.application')}</strong>
            </div>
            <div>
              <ApplicationTags apps={[supportRequest.application]} />
            </div>
            <div>
              <strong>{t('common:organization')}</strong>
            </div>
            <div>{supportRequest.organization.name}</div>
            <div>
              <strong>{t('common:person')}</strong>
            </div>
            <div>{supportRequest.author.name}</div>
            {supportRequest.referencePerson && (
              <>
                <div>
                  <strong>{t('supportRequest.referencedPerson')}</strong>
                </div>
                <div>{supportRequest.referencePerson.name}</div>
              </>
            )}
            <div>
              <strong>{t('supportRequest.title')}</strong>
            </div>
            <div>{supportRequest.title}</div>
            <div>
              <strong>{t('supportRequest.description')}</strong>
            </div>
            <div>
              <RichText value={JSON.parse(supportRequest.description ?? '')} />
            </div>

            <TechicalDetails details={supportRequest.details} />

            <div>
              <strong>{t('common:attachments')}</strong>
            </div>
            <div>
              <AttachmentList>
                {supportRequest.attachments?.map((attachment) => (
                  <AttachmentList.Item
                    key={attachment.id}
                    onClick={() =>
                      createDownloadUrl({
                        id: attachment.id,
                        type: DownloadType.Attachment,
                        reference: DownloadReference.Dashboard,
                      }).then(async ({ createDownloadUrl }) => {
                        const response = await fetch(createDownloadUrl);
                        const blob = await response.blob();
                        saveAs(blob, attachment.name);
                      })
                    }
                    canPreview={attachment.type.startsWith('image/')}
                    previewHeading={attachment.name}
                    onPreview={() =>
                      createDownloadUrl({
                        id: attachment.id,
                        type: DownloadType.Attachment,
                        reference: DownloadReference.Dashboard,
                      }).then(
                        async ({ createDownloadUrl }) => createDownloadUrl,
                      )
                    }
                    i18n={{
                      cancel: t('common:action.cancel'),
                      download: t('common:download'),
                    }}
                  >
                    <AttachmentList.Item.Meta
                      label={t('common:upload.uploadDateShort')}
                      value={
                        dayjs(attachment.createdAt).isValid()
                          ? t('common:upload.uploadDateDate', {
                              date: new Date(attachment.createdAt),
                            })
                          : null
                      }
                    />
                    <AttachmentList.Item.Meta
                      label={t('common:upload.fileSize')}
                      value={<FileSize>{attachment.size}</FileSize>}
                    />
                    <AttachmentList.Item.Meta
                      label={t('common:upload.fileType')}
                      value={
                        fileTypes.find((t) => t.type === attachment.type)
                          ?.extension ?? attachment.type.split('/').at(-1)
                      }
                    />
                  </AttachmentList.Item>
                ))}
              </AttachmentList>
            </div>
          </Grid>

          <Divider />

          {supportRequest.comments.length > 0 && (
            <Comments comments={supportRequest.comments} />
          )}

          <Spacer mb="$xLarge" />

          <SupportRequestCommentForm
            id={supportRequest.id}
            title={supportRequest.title}
            status={supportRequest.status}
          />
        </>
      )}
    </ContentWrapper>
  );
};

const TechicalDetails = ({
  details,
}: {
  details?: NonNullable<SupportRequestQuery['supportRequest']>['details'];
}) => {
  const { t } = useTranslation();

  if (!details) return null;

  const { browser, device, os } = UAParser(details.userAgent);

  return (
    <>
      <div>
        <strong>{t('supportRequest.technicalDetails')}</strong>
      </div>
      <div>
        <Grid gap="small">
          <div>{t('supportRequest.browser')}</div>
          <div>
            {browser.name} ({browser.version})
          </div>

          <div>{t('supportRequest.operatingSystem')}</div>
          <div>
            {os.name} ({os.version})
          </div>

          <div>{t('supportRequest.device')}</div>
          <div>
            {device.vendor} {device.model} {device.type}
          </div>

          <div>{t('supportRequest.screenSize')}</div>
          <div>
            {details.screenSize.width} x {details.screenSize.height}
          </div>

          <div>{t('supportRequest.browserWindowSize')}</div>
          <div>
            {details.browserWindowSize.width} x{' '}
            {details.browserWindowSize.height}
          </div>
        </Grid>
      </div>
    </>
  );
};
