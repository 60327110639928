import { styled } from '@porsche-kado/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../config/i18n';
import { SupportRequestStatus } from '../graphql';

export const STATUS_COLOR = {
  [SupportRequestStatus.Closed]: '#27a951',
  [SupportRequestStatus.InProgress]: '#2ba0ca',
  [SupportRequestStatus.Open]: '#fcbe0e',
};

type SupportRequestStatusTagProps = {
  status: SupportRequestStatus;
} & React.HTMLAttributes<HTMLSpanElement>;

const Container = styled('span', {
  backgroundColor: 'inherit',
  borderRadius: '$small',
  color: '$white',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',

  variants: {
    status: Object.fromEntries(
      Object.entries(STATUS_COLOR).map(([key, value]) => [
        key,
        { backgroundColor: value },
      ]),
    ),
  },
});

export const SupportRequestStatusTag = ({
  status,
  ...props
}: SupportRequestStatusTagProps) => {
  const { t } = useTranslation(NAMESPACES);
  return (
    <Container status={status} {...props}>
      {t(`supportRequest.statusValues.${status}`)}
    </Container>
  );
};
