import {
  PSpinner,
  PSpinnerProps,
} from '@porsche-design-system/components-react';
import { styled } from '../stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Overlay = styled('div', {
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: '$default',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Spinner = (props: PSpinnerProps): JSX.Element => (
  <Wrapper>
    <PSpinner {...props} />
  </Wrapper>
);

export const SpinnerOverlay = (props: PSpinnerProps): JSX.Element => (
  <Overlay>
    <Spinner {...props} />
  </Overlay>
);
