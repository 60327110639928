import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { PpnMasterdataImportStatus } from '../../../pages';

export const Route = createFileRoute('/admin/ppn-masterdata-import/_auth/')({
  component: () => <PpnMasterdataImportStatus />,
  beforeLoad: () => {
    return {
      breadcrumb: () => <Trans i18nKey="admin.ppnMasterdataImport.headline" />,
    };
  },
});
