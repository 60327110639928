import { DataTable } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import { PersonInfoQuery, usePersonInfoQuery } from '../../../graphql';

export const Groups = () => {
  const { t } = useTranslation(NAMESPACES);

  const { personId } = useParams({
    from: '/admin/person-info/$personId/groups',
  });

  const { data: person, isLoading } = usePersonInfoQuery(
    {
      id: +personId,
      includeDashboardAbility: false,
      includeFieldForceManagementAbility: false,
      includeGreenlandAbility: false,
      includeMultisensoryExperienceAbility: false,
      includeMysteryShoppingAbility: false,
      includeNetworkPlanningAbility: false,
      includePorscheStrategy2030Ability: false,
      includeRetailDesignAbility: false,
      includeSurveyAbility: false,
    },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<
        NonNullable<NonNullable<PersonInfoQuery['person']>['groups']>[0]
      >();

    return [
      columnHelper.accessor('id', {
        cell: (data) => data.getValue(),
        header: () => t('common:id'),
      }),
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
    ];
  }, [t]);

  return (
    <DataTable
      isLoading={isLoading}
      caption={t('common:groups')}
      data={person?.groups ?? []}
      columns={columns}
      idAccessor="name"
      pagination={{
        active: false,
      }}
      i18n={{
        filterLabel: (columnName) =>
          t('common:iconLabel.filter', { columnName }),
        optionAll: t('common:all'),
        buttonReset: t('common:action.reset'),
        buttonFilter: t('common:action.filter'),
        actionSearch: t('common:action.search'),
        noData: t('common:noData'),
      }}
    />
  );
};
