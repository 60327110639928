import { styled } from '../../stitches.config';

export const ToolbarButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '$small',
  color: '$text',
  cursor: 'pointer',
  height: '1.5rem',
  width: '1.5rem',
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover:not([disabled])': {
    backgroundColor: '$backgroundSurface',
  },

  '&[disabled]': {
    color: '$contrastLow',
    cursor: 'unset',
  },

  variants: {
    active: {
      true: {
        backgroundColor: '$contrastLow',

        '&[disabled]': {
          backgroundColor: 'transparent',
          color: '$contrastLow',
          cursor: 'unset',
        },
      },
    },
  },
});
