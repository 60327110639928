/* eslint-disable react/no-children-prop */
import {
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useId,
  useInteractions,
  type MiddlewareState,
} from '@floating-ui/react';
import {
  PButtonPure,
  PCheckboxWrapper,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { dropShadowMediumStyle } from '@porsche-design-system/components-react/styles';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import ContentLoader from 'react-content-loader';
import treeSelectChildrenIconSrc from '../../icons/tree-select-children.svg';
import treeSelectParentIconSrc from '../../icons/tree-select-parent.svg';
import { styled, theme } from '../stitches.config';
import { Spinner, SpinnerOverlay } from './Spinner';
import { Tooltip } from './Tooltip';

export type MultiOrganizationSelectProps<
  TOrganization extends OrganizationProps,
> = {
  label: string;
  clearLabel: string;
  language: string;
  isLoading: boolean;
  organizations: TOrganization[];
  organizationIsVisible?: (org: TOrganization) => boolean;
  xMoreLabel: (count: number) => string;
  selectionCountLabel: (count: number) => string;
  value: number[];
  onChange: (value: number[]) => void;
  name?: string;
  inputId?: string;
  required?: boolean;
  errorMessage?: string;
  onBlur?: React.FocusEventHandler<unknown>;
  style?: React.CSSProperties;
  /**
   * @default popover
   */
  variant?: 'popover' | 'flat';
  withoutPortal?: boolean;
};

export const MultiOrganizationSelect = <
  TOrganization extends OrganizationProps,
>({
  label,
  name,
  required,
  isLoading,
  organizations,
  organizationIsVisible = () => true,
  language,
  xMoreLabel,
  clearLabel,
  selectionCountLabel,
  errorMessage,
  value: selectedIds,
  onChange: setSelectedIds,
  onBlur,
  inputId,
  style,
  variant = 'popover',
  withoutPortal,
}: MultiOrganizationSelectProps<TOrganization>) => {
  const [isPending, startTransition] = useTransition();
  const [searchText, setSearchText] = useState('');
  const organizationIsVisibleRef = useRef(organizationIsVisible);
  organizationIsVisibleRef.current = organizationIsVisible;

  const data = useMemo(() => {
    const organizationIsVisible = organizationIsVisibleRef.current;
    const organizationIsHidden = (org: TOrganization) =>
      !organizationIsVisible(org);

    if (!organizations) return null;

    const organizationsMap = new Map(
      organizations.map((org) => [
        org.id,
        {
          ...org,
          depth: 0,
          children: [] as (typeof org)[],
        },
      ]),
    );

    const organizationsToTop = (
      org?: TOrganization,
    ): (TOrganization & { children: TOrganization[] })[] => {
      const orgFromMap = org && organizationsMap.get(org.id);

      if (
        typeof orgFromMap?.parentId === 'number' &&
        orgFromMap.parentId !== orgFromMap.id
      ) {
        return [
          orgFromMap,
          ...organizationsToTop(organizationsMap.get(orgFromMap.parentId)),
        ];
      }

      if (orgFromMap) {
        return [orgFromMap];
      }

      return [];
    };

    const hasParent = (org: TOrganization) =>
      typeof org.parentId === 'number' && organizationsMap.has(org.parentId);

    const roots = [...organizationsMap.values()]
      .filter(
        (org) =>
          !hasParent(org) ||
          // or is its own parent
          org.id === org.parentId ||
          // or every parent is hidden
          organizationsToTop(org).slice(1).every(organizationIsHidden),
      )
      .filter(organizationIsVisible)
      .sort((a, b) => a.name.localeCompare(b.name));

    // connect children
    organizationsMap.forEach((org) => {
      if (org.parentId && organizationIsVisible(org)) {
        // find first visible parent
        const parent = organizationsToTop(org)
          .slice(1)
          .find(organizationIsVisible);

        if (parent && parent !== org) {
          parent.children.push(org);
          parent.children.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    });

    // calculate depth
    organizationsMap.forEach((org) => {
      let parent = org as typeof org | undefined;
      while (parent?.parentId && parent?.parentId !== parent?.id) {
        parent = organizationsMap.get(parent?.parentId);
        if (!parent || organizationIsVisible(parent)) {
          org.depth++;
        }
      }
    });

    return { roots, organizationsMap };
  }, [organizations]);

  const [expandedIds, setExpandedIds] = useState([] as number[]);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState(800);
  const valueText = useMemo(() => {
    if (!data || isLoading) return '';

    const organizationNames = selectedIds
      .map((id) => data.organizationsMap.get(id))
      .filter(<T,>(d: T | undefined): d is T => !!d)
      .sort((a, b) => a.depth - b.depth || a.name.localeCompare(b.name))
      .map((org) => org.name);

    const maxWidth = inputWidth / 8; // assume a char is about 8px wide
    const maxElements = Math.max(
      1,
      organizationNames.findIndex(
        (_, idx) =>
          organizationNames.slice(0, idx + 1).join(', ').length > maxWidth,
      ),
    );

    if (organizationNames.length > maxElements + 1) {
      organizationNames.splice(
        maxElements,
        0,
        xMoreLabel(organizationNames.length - maxElements),
      );
    }

    return new Intl.ListFormat(language, { type: 'conjunction' }).format([
      ...organizationNames.slice(0, maxElements + 1),
    ]);
  }, [data, isLoading, language, selectedIds, xMoreLabel, inputWidth]);

  useEffect(() => {
    // ideally this should be a dom-resize observer, but seems overkill for now
    setTimeout(() => {
      setInputWidth(inputRef.current?.offsetWidth ?? 800);
    }, 10);
  });

  // This effect checks for mismatch between selectedIds and ids present in organizations
  // if there are ids selected that are not present in the organization array
  // we have to clear them (this is the case e.g. when organizations are hidden), else
  // they will show as selected, but can not be deselected.
  useEffect(() => {
    const existingIds = selectedIds.filter((id) =>
      organizations.some((org) => org.id === id),
    );

    if (
      organizations.length > 0 &&
      !isLoading &&
      existingIds.length !== selectedIds.length
    ) {
      setSelectedIds(existingIds);
    }
  }, [organizations, selectedIds, setSelectedIds, isLoading]);

  const selectedOrganizations = selectedIds
    .map((id) => data?.organizationsMap.get(id))
    .filter(<T,>(d?: T): d is T => !!d);
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: selectedOrganizations.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
  });

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open, event, reason) => {
      if (
        reason === 'focus' &&
        event &&
        'relatedTarget' in event &&
        event.relatedTarget &&
        refs.floating.current?.parentNode?.contains(event.relatedTarget as Node)
      ) {
        // prevent closing if focus shifts to nested floating element
        return;
      }

      setIsOpen(open);

      if (open) {
        setSearchText('');
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      } else {
        inputRef.current?.blur();
      }
    },
    strategy: withoutPortal ? 'fixed' : undefined,
    placement: 'bottom-start',
    middleware: [
      offset(8),
      size({
        apply(state: MiddlewareState) {
          state.elements.floating.style.width = `${state.rects.reference.width}px`;
        },
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const click = useClick(context, { keyboardHandlers: false });
  const focus = useFocus(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    focus,
    dismiss,
  ]);

  const headingId = useId();

  const content = (
    <Content flat={variant === 'flat'}>
      {isLoading && <SpinnerOverlay />}

      <OrganizationTree>
        {data?.roots.map((org) => (
          <CheckboxTreeItem
            key={org.id}
            id={org.id}
            level={0}
            label={org.name}
            children={org.children}
            suffix={org.partnerNumber}
          />
        ))}
      </OrganizationTree>

      <Divider />

      <SelectionTree ref={parentRef}>
        <SelectionHeading>
          {selectionCountLabel(selectedIds.length)}
          <PButtonPure
            type="button"
            icon="none"
            onClick={() => setSelectedIds([])}
          >
            {clearLabel}
          </PButtonPure>
        </SelectionHeading>

        <div
          style={{
            height: rowVirtualizer.getTotalSize(),
            position: 'relative',
            width: '100%',
          }}
        >
          {rowVirtualizer
            .getVirtualItems()
            .map(({ index, size, start, key }) => {
              const { id, name } = selectedOrganizations[index];
              return (
                <SelectionItem
                  data-index={index}
                  ref={rowVirtualizer.measureElement}
                  key={key}
                  virtualized
                  style={{
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                >
                  {name}
                  <PButtonPure
                    type="button"
                    role="button"
                    icon="delete"
                    onClick={() =>
                      setSelectedIds(selectedIds.filter((i) => i !== id))
                    }
                    hideLabel
                  />
                </SelectionItem>
              );
            })}
        </div>
      </SelectionTree>
    </Content>
  );

  const FloatingContainer = withoutPortal ? Fragment : FloatingPortal;

  return (
    <>
      <InputLoadingContainer data-testid={inputId && `select-${inputId}`}>
        <PTextFieldWrapper
          ref={refs.setReference}
          {...getReferenceProps()}
          label={label}
          state={errorMessage ? 'error' : 'none'}
          message={errorMessage}
          style={style}
        >
          <input
            ref={inputRef}
            type="text"
            // this value is not controlled because large organization trees cause
            // visual studder, therefore we use useTransition, which prevents blocking
            // the UI thread with state updates, but also causes the input to only reflect
            // the last input character if it is controlled
            value={isOpen ? undefined : valueText}
            placeholder={isOpen ? valueText : undefined}
            name={name}
            required={required}
            autoComplete="off"
            onChange={(evt) =>
              startTransition(() => setSearchText(evt.currentTarget.value))
            }
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                const [match, ...others] = [
                  ...(data?.organizationsMap.values() ?? []),
                ].filter((org) =>
                  searchText
                    .split(/\s+/)
                    .every((word) => nameMatches(org.name, word)),
                );

                if (others.length === 0) {
                  setSelectedIds([
                    ...new Set([
                      ...selectedIds,
                      match.id,
                      ...match.children
                        .flatMap(flattenChildren)
                        .map((o) => o.id),
                    ]),
                  ]);

                  setExpandedIds([]);
                  setSearchText('');
                  evt.currentTarget.value = '';
                }
              }
            }}
            onBlur={onBlur}
          />
        </PTextFieldWrapper>
        {(isLoading || isPending) && (
          <InputLoadingContent>
            {isLoading ? (
              <ContentLoader
                backgroundColor={theme.colors.backgroundSurface.toString()}
                foregroundColor={theme.colors.contrastLow.toString()}
                width="90%"
                height="30"
              >
                {selectedIds.slice(0, 3).map((id, idx) => (
                  <rect key={id} x={175 * idx} y="0" width="160" height="30" />
                ))}
              </ContentLoader>
            ) : (
              <div />
            )}
            <Spinner />
          </InputLoadingContent>
        )}
      </InputLoadingContainer>

      <MultiOrganizationContext.Provider
        value={{
          expandedIds,
          setExpandedIds: (ids) => setExpandedIds([...new Set(ids)]),
          selectedIds,
          setSelectedIds: (ids) => {
            setSelectedIds([...new Set(ids)]);
            if (searchText) {
              setExpandedIds([]);
            }
          },
          searchText,
        }}
      >
        {variant === 'flat'
          ? content
          : isOpen && (
              <FloatingContainer>
                <FloatingFocusManager
                  context={context}
                  modal={false}
                  initialFocus={inputRef}
                  returnFocus={false}
                >
                  <FloatingContent
                    aria-labelledby={headingId}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                  >
                    {content}
                  </FloatingContent>
                </FloatingFocusManager>
              </FloatingContainer>
            )}
      </MultiOrganizationContext.Provider>
    </>
  );
};

const MultiOrganizationContext = createContext<{
  expandedIds: number[];
  setExpandedIds: (ids: number[]) => void;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  searchText: string;
}>({
  expandedIds: [],
  setExpandedIds: () => void 0,
  selectedIds: [],
  setSelectedIds: () => void 0,
  searchText: '',
});

type OrganizationProps = {
  id: number;
  name: string;
  parentId?: number | null | undefined;
  children?: OrganizationProps[];
  partnerNumber?: string | null;
};

const flattenChildren = (orga: OrganizationProps): OrganizationProps[] => [
  orga,
  ...(orga.children?.flatMap(flattenChildren) ?? []),
];

const nameMatches = (name: string, needle: string): boolean =>
  name.toLowerCase().includes(needle.toLowerCase());

const nameOrChildrenNamesMatchText = (
  obj: { name: string; children?: { name: string }[] },
  needle: string,
): boolean =>
  nameMatches(obj.name, needle) ||
  !!obj.children?.some((child) => nameOrChildrenNamesMatchText(child, needle));

const CheckboxTreeItem = ({
  level,
  id,
  label,
  suffix,
  children,
}: {
  level: number;
  id: number;
  label: string;
  suffix?: string | null;
  children?: OrganizationProps[];
}) => {
  const {
    expandedIds,
    setExpandedIds,
    selectedIds,
    setSelectedIds,
    searchText,
  } = useContext(MultiOrganizationContext);

  const descendants = children?.flatMap(flattenChildren) ?? [];

  const isSearching = !!searchText;

  const nameMatchesSearch = searchText
    .split(/\s+/)
    .every((word) => nameMatches(label, word));

  const nameOrChildrenMatchSearch = searchText
    .split(/\s+/)
    .every((word) =>
      nameOrChildrenNamesMatchText({ name: label, children }, word.trim()),
    );

  const collapsed =
    !expandedIds.includes(id) &&
    // if we are in search-mode, collapse this item only if no match is found
    (isSearching ? !nameOrChildrenMatchSearch : true);

  if (isSearching && !nameOrChildrenMatchSearch) {
    // hide element if in search-mode and no match found
    return null;
  }

  const showThisItem = !isSearching || nameMatchesSearch;

  const showCollapseButton = !isSearching && (children?.length ?? 0) > 0;

  return (
    <>
      {showThisItem && (
        <TreeItem style={{ paddingLeft: isSearching ? 0 : level * 24 }}>
          {showCollapseButton ? (
            <PButtonPure
              type="button"
              role="button"
              aria-label={label}
              icon={collapsed ? 'arrow-head-right' : 'arrow-head-down'}
              onClick={() =>
                setExpandedIds(
                  collapsed
                    ? [...expandedIds, id]
                    : expandedIds.filter((i) => i !== id),
                )
              }
              hideLabel
            />
          ) : (
            <div />
          )}

          {descendants.length > 0 ? (
            <Tooltip
              content={
                <ButtonGroup>
                  <PButtonPure
                    type="button"
                    iconSource={treeSelectChildrenIconSrc}
                    hideLabel
                    onClick={() => {
                      const hasDescendants = descendants.every(({ id }) =>
                        selectedIds.some((selectedId) => id === selectedId),
                      );

                      setSelectedIds(
                        hasDescendants
                          ? selectedIds.filter((selectedId) =>
                              descendants.every(({ id }) => id !== selectedId),
                            )
                          : [
                              ...new Set([
                                ...selectedIds,
                                ...descendants.map(({ id }) => id),
                              ]),
                            ],
                      );
                    }}
                  />
                  <PButtonPure
                    type="button"
                    iconSource={treeSelectParentIconSrc}
                    hideLabel
                    onClick={() => {
                      setSelectedIds(
                        selectedIds.includes(id)
                          ? selectedIds.filter(
                              (selectedId) => selectedId !== id,
                            )
                          : [...selectedIds, id],
                      );
                    }}
                  />
                </ButtonGroup>
              }
              placement="top-start"
              safePolygonClose
            >
              <PCheckboxWrapper label={label}>
                <input
                  type="checkbox"
                  checked={selectedIds.includes(id)}
                  onChange={({ target: { checked } }) =>
                    setSelectedIds(
                      checked
                        ? [
                            ...selectedIds,
                            id,
                            ...descendants.map((org) => org.id),
                          ]
                        : selectedIds.filter(
                            (i) =>
                              i !== id &&
                              !descendants.some((org) => org.id === i),
                          ),
                    )
                  }
                />
              </PCheckboxWrapper>
            </Tooltip>
          ) : (
            <PCheckboxWrapper label={label}>
              <input
                type="checkbox"
                checked={selectedIds.includes(id)}
                onChange={({ target: { checked } }) =>
                  setSelectedIds(
                    checked
                      ? [
                          ...selectedIds,
                          id,
                          ...descendants.map((org) => org.id),
                        ]
                      : selectedIds.filter(
                          (i) =>
                            i !== id &&
                            !descendants.some((org) => org.id === i),
                        ),
                  )
                }
              />
            </PCheckboxWrapper>
          )}
          {suffix ? <Suffix>{suffix}</Suffix> : <div />}
        </TreeItem>
      )}

      {!collapsed &&
        children?.map((org) => (
          <CheckboxTreeItem
            key={org.id}
            id={org.id}
            label={org.name}
            level={level + 1}
            children={org.children}
            suffix={org.partnerNumber}
          />
        ))}
    </>
  );
};

const FloatingContent = styled('div', {
  minWidth: 500,
  minHeight: 350,
  maxHeight: 350,
  backgroundColor: '$white',
  display: 'grid',
  borderRadius: '$medium',
  zIndex: '$popover',
  ...dropShadowMediumStyle,
});

const ButtonGroup = styled('div', {
  display: 'flex',
  gap: '$small',
});

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1px 1fr',
  minHeight: 350,
  maxHeight: 350,
  overflow: 'hidden',

  variants: {
    flat: {
      true: { height: 350, paddingTop: '$small' },
    },
  },
});

const Divider = styled('div', {
  backgroundColor: '$contrastLow',
});

const OrganizationTree = styled('div', {
  paddingInline: '$medium',
  overflowY: 'auto',
  height: '100%',
});

const SelectionTree = styled('div', {
  paddingInline: '$medium',
  overflowY: 'auto',
  height: '100%',
});

const SelectionHeading = styled('strong', {
  position: 'sticky',
  top: 0,
  background: '$white',
  marginInline: '-$medium',
  padding: '$medium',
  paddingBottom: '$small',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  zIndex: '$default',
});

const TreeItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: '24px 1fr auto',
  paddingBlock: '$small',
  gap: '$xSmall',
  '&:first-child': {
    marginTop: 'calc($medium - $small)',
  },
});

const SelectionItem = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  paddingBlock: '$small',
  gap: '$xSmall',

  variants: {
    virtualized: {
      true: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        boxSizing: 'border-box',
      },
    },
  },
});

const Suffix = styled('div', {
  color: '$contrastLow',
  '&:before': { content: '(' },
  '&:after': { content: ')' },
});

const InputLoadingContainer = styled('div', {
  position: 'relative',
});

const InputLoadingContent = styled('div', {
  width: 'calc(100% - $medium)',
  display: 'grid',
  gap: '$small',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  top: '$large',
  paddingLeft: '$small',
  pointerEvents: 'none',
});
