import { styled } from '../stitches.config';

export const ContentWrapper = styled('div', {
  paddingLeft: '4.2rem',
  paddingRight: '4.2rem',

  '@media print': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export const Page = styled('div', {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const Main = styled('main', {
  flex: '1 0 auto',
  position: 'relative',
  paddingBottom: '$xxLarge',
});
