import { DEFAULT_LOCALE, locales } from '@porsche-kado/i18n';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import {
  COMMON_NAMESPACE,
  DEFAULT_NAMESPACE,
  NAMESPACES,
} from '../config/i18n';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    supportedLngs: locales.map((locale) => locale.code),
    fallbackLng:
      locales.find((locale) => locale.isDefault)?.code ?? DEFAULT_LOCALE,
    ns: NAMESPACES,
    defaultNS: DEFAULT_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

if (process.env.NODE_ENV === 'development') {
  import('@porsche-kado/i18n').then(({ translations }) =>
    i18next.addResourceBundle(
      DEFAULT_LOCALE,
      COMMON_NAMESPACE,
      translations,
      true,
      false,
    ),
  );
  import('../locales/en-GB/translations.json').then((defaultTranslation) =>
    i18next.addResourceBundle(
      DEFAULT_LOCALE,
      DEFAULT_NAMESPACE,
      defaultTranslation,
      true,
      false,
    ),
  );
}

export default i18next;
