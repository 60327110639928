import {
  PInlineNotification,
  PTabsBar,
} from '@porsche-design-system/components-react';
import { Spacer, Spinner } from '@porsche-kado/ui';
import {
  Link,
  LinkProps,
  Outlet,
  useChildMatches,
} from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonContext } from '../../../context';
import { usePersonInfoQuery } from '../../../graphql';
import { Account } from './Account';
import { Consents } from './Consents';
import { FieldForceManagement } from './FieldForceManagement';
import { Greenland } from './Greenland';
import { Groups } from './Groups';
import { MultisensoryExperience } from './MultisensoryExperience';
import { MysteryShopping } from './MysteryShopping';
import { NetworkPlanning } from './NetworkPlanning';
import { Organizations } from './Organizations';
import { PersonInfoOverview } from './PersonInfoOverview';
import { PorscheStrategy2030 } from './PorscheStrategy2030';
import { RedirectToTab } from './RedirectToTab';
import { RetailDesign } from './RetailDesign';
import { Settings } from './Settings';
import { Survey } from './Survey';
import { apps } from './helper';
import { usePersonIdParam } from './usePersonIdParam';

export const PersonInfo = () => {
  const { t } = useTranslation(NAMESPACES);
  const { state: personState } = usePersonContext();
  const dateFormat = personState.settings?.dateFormat || 'YYYY/MM/DD';

  const personId = usePersonIdParam()!;

  const { data: person, isLoading } = usePersonInfoQuery(
    {
      id: +personId,
      includeDashboardAbility: true,
      includeFieldForceManagementAbility: false,
      includeGreenlandAbility: false,
      includeMultisensoryExperienceAbility: false,
      includeMysteryShoppingAbility: false,
      includeNetworkPlanningAbility: false,
      includePorscheStrategy2030Ability: false,
      includeRetailDesignAbility: false,
      includeSurveyAbility: false,
    },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const availableApps = apps(person?.ability.dashboard ?? []);

  const tabs: {
    routeId: LinkProps['to'];
    label: string;
    params?: { app: string };
  }[] = [
    {
      routeId: '/admin/person-info/$personId/account',
      label: t('common:account'),
    },
    {
      routeId: '/admin/person-info/$personId/settings',
      label: t('common:settings'),
    },
    {
      routeId: '/admin/person-info/$personId/consents',
      label: t('admin.personInfo.consents'),
    },
    {
      routeId: '/admin/person-info/$personId/groups',
      label: t('common:groups'),
    },
    {
      routeId: '/admin/person-info/$personId/organizations',
      label: t('common:organizations'),
    },
    ...(availableApps.includes('FieldForceManagement')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.ffm'),
            params: { app: 'field-force-management' },
          },
        ]
      : []),

    ...(availableApps.includes('Greenland')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.greenland'),
            params: { app: 'greenland' },
          },
        ]
      : []),

    ...(availableApps.includes('PorscheStrategy2030')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.porscheStrategy2030'),
            params: { app: 'porsche-strategy-2030' },
          },
        ]
      : []),

    ...(availableApps.includes('NetworkPlanning')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.networkPlanning'),
            params: { app: 'network-planning' },
          },
        ]
      : []),

    ...(availableApps.includes('RetailDesign')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.retailDesign'),
            params: { app: 'retail-design' },
          },
        ]
      : []),

    ...(availableApps.includes('Survey')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.survey'),
            params: { app: 'survey' },
          },
        ]
      : []),

    ...(availableApps.includes('MysteryShopping')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.mysteryShopping'),
            params: { app: 'mystery-shopping' },
          },
        ]
      : []),

    ...(availableApps.includes('MultisensoryExperience')
      ? [
          {
            routeId: '/admin/person-info/$personId/$app' as const,
            label: t('common:app.multisensoryExperience'),
            params: { app: 'multisensory-experience' },
          },
        ]
      : []),
  ];
  const childMatches = useChildMatches();
  const activeTabIndex = tabs.findIndex(
    (tab) =>
      tab.routeId === childMatches[0]?.routeId &&
      (!tab.params ||
        !('app' in childMatches[0].params) ||
        tab.params.app === childMatches[0].params.app),
  );

  return (
    <>
      <Spacer mb="$medium" />

      {isLoading && personId && !person && <Spinner />}

      {person && !isLoading && (
        <>
          {person.deletedAt && (
            <>
              <PInlineNotification
                heading={t(
                  'admin.personInfo.notification.deletedPersonHeadline',
                )}
                description={t(
                  'admin.personInfo.notification.deletedPersonDescription',
                  {
                    name: person.name,
                    date: dayjs(person.deletedAt).format(dateFormat),
                  },
                )}
                state="warning"
                dismissButton={false}
              />
              <Spacer mb="$medium" />
            </>
          )}

          {!person.roles.length && (
            <>
              <PInlineNotification
                heading={t(
                  'admin.personInfo.notification.personWithoutRoleHeadline',
                )}
                description={t(
                  'admin.personInfo.notification.personWithoutRoleDescription',
                  { name: person.name },
                )}
                state="info"
                dismissButton={false}
              />
              <Spacer mb="$medium" />
            </>
          )}

          <PTabsBar activeTabIndex={activeTabIndex}>
            {tabs.map((tab) => (
              <Link
                key={tab.routeId}
                to={tab.routeId}
                params={{ personId, ...tab.params }}
              >
                {tab.label}
              </Link>
            ))}
          </PTabsBar>

          <Spacer mb="$medium" />

          <Outlet />
        </>
      )}
    </>
  );
};

PersonInfo.Overview = PersonInfoOverview;
PersonInfo.Account = Account;
PersonInfo.RedirectToTab = RedirectToTab;
PersonInfo.Settings = Settings;
PersonInfo.Consents = Consents;
PersonInfo.Groups = Groups;
PersonInfo.Organizations = Organizations;
PersonInfo.FieldForceManagement = FieldForceManagement;
PersonInfo.Greenland = Greenland;
PersonInfo.PorscheStrategy2030 = PorscheStrategy2030;
PersonInfo.NetworkPlanning = NetworkPlanning;
PersonInfo.RetailDesign = RetailDesign;
PersonInfo.Survey = Survey;
PersonInfo.MysteryShopping = MysteryShopping;
PersonInfo.MultisensoryExperience = MultisensoryExperience;
