import { DayPickerInput } from '@porsche-kado/ui';
import dayjs from 'dayjs';
import { usePersonContext } from '../context';

export const DateInput = ({
  value,
  onChange,
  ...props
}: {
  name: string;
  label: string;
  disabled?: boolean;
  inputRef: React.Ref<HTMLInputElement>;
  value?: string | null;
  onChange: (value?: string) => void;
}) => {
  const { state: personState } = usePersonContext();
  const format = personState.settings?.dateFormat || 'YYYY/MM/DD';

  return (
    <DayPickerInput
      {...props}
      format={format}
      selected={(value && dayjs(value).format(format)) ?? undefined}
      onSelect={(day) => onChange(day && dayjs(day).format('YYYY-MM-DD'))}
    />
  );
};
