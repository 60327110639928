import {
  PHeading,
  PIcon,
  PSpinner,
  PTag,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { Spacer, styled } from '@porsche-kado/ui';
import { ClientError } from 'graphql-request';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../../config/i18n';
import { useCheckMeetingAbilitiesQuery } from '../../../../graphql';

export const MeetingAbilityInfo = ({ personId }: { personId: number }) => {
  const { t } = useTranslation(NAMESPACES);

  const [meetingId, setMeetingId] = useState('');

  const {
    data: checks,
    isSuccess,
    isLoading,
    error,
  } = useCheckMeetingAbilitiesQuery(
    { meetingId, personId },
    {
      enabled: !!meetingId,
      select: (d) => d.person?.ability.fieldForceManagementSubset.checkMeeting,
    },
  );

  return (
    <>
      <PTextFieldWrapper>
        <input
          type="text"
          value={meetingId}
          onChange={(evt) => {
            const [, meetingId] =
              /field-force-management\/meetings\/(\w+)$/.exec(
                evt.currentTarget.value,
              ) ?? [null, evt.currentTarget.value];

            setMeetingId(meetingId);
          }}
          placeholder={t('admin.personInfo.insertMeetingId')}
        />
      </PTextFieldWrapper>

      <Spacer mb="$medium" />

      {isLoading && meetingId ? (
        <PSpinner />
      ) : isSuccess ? (
        <>
          <Subject>
            <PTag color="primary">M</PTag>
            <PHeading size="small" tag="h4">
              {checks?.subject}
            </PHeading>
          </Subject>
          <Result label="Meeting" action="read" {...checks?.read} />
          <Result label="Meeting" action="update" {...checks?.update} />
          <Result label="Meeting" action="delete" {...checks?.delete} />
          {(checks?.actionItems?.length ?? 0) > 0 && (
            <Indent>
              {checks?.actionItems?.map((checks, idx) => (
                <div key={idx}>
                  <Subject>
                    <PTag color="primary">A</PTag>
                    <PHeading size="small" tag="h4">
                      {checks.subject}
                    </PHeading>
                  </Subject>
                  <Result label="Action Item" action="read" {...checks.read} />
                  <Result
                    label="Action Item"
                    action="update"
                    {...checks.update}
                  />
                  <Result
                    label="Action Item"
                    action="delete"
                    {...checks.delete}
                  />
                </div>
              ))}
            </Indent>
          )}
        </>
      ) : error ? (
        error instanceof ClientError ? (
          error.response.errors?.[0].message ?? error.message
        ) : (
          `${error}`
        )
      ) : null}
    </>
  );
};

const Result = ({
  label,
  action,
  allowed,
  reason,
}: {
  label: string;
  action: string;
  allowed?: boolean;
  reason?: string | null;
}) => {
  const { t } = useTranslation();
  return (
    <Indent>
      {allowed ? (
        <PIcon name="success-filled" color="notification-success" />
      ) : (
        <PIcon name="warning-filled" color="notification-error" />
      )}{' '}
      <code>{action}</code>{' '}
      {reason && <PTag color="notification-warning">{t(reason as never)}</PTag>}
    </Indent>
  );
};

const Indent = styled('div', { paddingLeft: '$large', marginBlock: '$xSmall' });

const Subject = styled('div', {
  display: 'flex',
  gap: '$small',
  alignItems: 'center',
  marginTop: '$small',
});
