import { focusEditor } from '@udecode/plate-common';
import {
  PlateRenderElementProps,
  useEventPlateId,
  usePlateEditorState,
} from '@udecode/plate-core';
import {
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  toggleList,
} from '@udecode/plate-list';
import OrderedListIcon from '../../../../icons/ordered-list.svg?react';
import UnorderedListIcon from '../../../../icons/unordered-list.svg?react';
import { ToolbarBlockButton } from '../ToolbarBlockButton';

const Ul = ({ nodeProps, attributes, children }: PlateRenderElementProps) => (
  <ul style={{ paddingLeft: 20 }} {...attributes} {...nodeProps}>
    {children}
  </ul>
);

const Ol = ({ nodeProps, attributes, children }: PlateRenderElementProps) => (
  <ol style={{ paddingLeft: 20 }} {...attributes} {...nodeProps}>
    {children}
  </ol>
);

const Li = ({ nodeProps, attributes, children }: PlateRenderElementProps) => (
  <li {...attributes} {...nodeProps}>
    {children}
  </li>
);

export const listComponents = {
  [ELEMENT_UL]: Ul,
  [ELEMENT_OL]: Ol,
  [ELEMENT_LI]: Li,
};

export const ListToolbarButtons = ({ id }: { id?: string }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <>
      <ToolbarBlockButton
        id={id}
        icon={UnorderedListIcon}
        type={ELEMENT_UL}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          toggleList(editor, { type: ELEMENT_UL });
          focusEditor(editor);
        }}
      />
      <ToolbarBlockButton
        id={id}
        icon={OrderedListIcon}
        type={ELEMENT_OL}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          toggleList(editor, { type: ELEMENT_OL });
          focusEditor(editor);
        }}
      />
    </>
  );
};
