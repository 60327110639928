import { PHeading } from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { Paragraph } from '../components';

export const Imprint = () => (
  <>
    <PHeading role="heading" size="large" aria-level={1} tag="h1">
      Imprint
    </PHeading>

    <Spacer mb="$medium" />

    <Paragraph>
      <strong>Dr. Ing. h.c. F. Porsche AG</strong>
      <br />
      Porscheplatz 1<br />
      D-70435 Stuttgart
      <br />
      <br />
      E-Mail: info@porsche.de
      <br />
      Phone: (+49) 0711 911-0
      <br />
      <br />
      represented by the executive board:
      <br />
      Oliver Blume, Chairman
      <br />
      Lutz Meschke, deputy Chairman
      <br />
      Andreas Haffner
      <br />
      Detlev von Platen
      <br />
      Albrecht Reimold
      <br />
      Uwe-Karsten Städter
      <br />
      Michael Steiner
      <br />
      <br />
      Register court: Amtsgericht Stuttgart
      <br />
      HRB-Nr. 730623
      <br />
      USt.-Id.-No. DE 147 799 625
    </Paragraph>
    <PHeading size="medium" tag="h2">
      Legal notice
    </PHeading>
    <Paragraph>
      Porsche, the Porsche emblem, 718, 911, Carrera, 918 Spyder, Boxster,
      Cayman, Cayenne, Macan, Panamera, Targa, PDK, PCCB, PCM, PSM, Tequipment,
      Tiptronic are registered trade marks of Dr. Ing. h.c. F. Porsche AG.
    </Paragraph>
    <Paragraph>
      Vehicles shown here are German models equipped with optional
      additional-cost extras. All models are not available in all countries due
      to nation-specific restrictions. The Porsche Center and your local
      importer can provide detailed information about technical specifications
      and availability of various models.
    </Paragraph>
    <Paragraph>
      Porsche reserves the right to change vehicle specifications, supplied
      equipment, delivery dates, and available colors. Listed prices are not
      binding.
    </Paragraph>
    <Paragraph>
      Please use only original Porsche spare parts for your vehicle. These can
      be obtained from authorized Porsche dealers where you will find complete
      information about approved parts and their availability.
    </Paragraph>
    <Paragraph>
      Porsche accepts liability for all parts it lists and approves. No
      responsibility is accepted for the use of any non- Porsche part or
      accessory that may cause technical problems or injury.
    </Paragraph>
    <Paragraph>
      Vehicle safety may be adversely affected by the use of non-Porsche
      accessories and parts. Although approved by outside agencies. Porsche does
      not test the multitude of products available on the accessories market.
      Please note as well, the use of parts not approved by Porsche can
      adversely affect your vehicle warranty.
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Porscheplatz 1<br />
      D-70435 Stuttgart
    </Paragraph>
    <PHeading size="medium" tag="h2">
      Personal privacy
    </PHeading>
    <Paragraph>
      Your personal privacy is held in the highest regard by Porsche AG. For
      this reason, we abide by all related mandates and regulations.
    </Paragraph>
    <Paragraph>
      When you visit our web site, our server will automatically save the name
      of your internet provider, the web site from which you visit us, the pages
      that you visit on our site, as well as the date and times of your visit.
      Data remain anonymous and are used solely for statistical purposes. When
      personal information is requested of you on any of our web site pages, you
      will be told how that information will be used.
    </Paragraph>
    <Paragraph>
      Here you can find detailed information:{' '}
      <Link to="/privacy-policy">Privacy Policy</Link>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      Copyright information
    </PHeading>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG, Germany, retains the copyright on all text,
      pictures, audio files and any other information contained herein, except
      where specifically noted. The reproduction or sharing of this information,
      in whole or part, requires explicit written permission from Dr. Ing. h.c.
      F. Porsche AG. The Porsche logo and font, as well as the Porsche emblem,
      are protected by patents. Dr. Ing. h.c. F. Porsche AG is registered in
      Stuttgart, Germany, under the number HRB-Nr.5211.
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Porscheplatz 1<br />
      D-70435 Stuttgart
    </Paragraph>
  </>
);
