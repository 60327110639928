import type { TDescendant } from '@udecode/plate-common';
import { useEventPlateId, usePlateStore } from '@udecode/plate-core';
import { useMemo } from 'react';
import { styled } from '../../stitches.config';
import { transformRichTextToString } from './transformRichTextToString';

const Container = styled('div', {
  color: '$contrastMedium',
});

export const getRichTextCharacterCount = (nodes: TDescendant[]): number =>
  transformRichTextToString(nodes).length ?? 0;

export const CharacterCounter = ({ id }: { id?: string }) => {
  const store = usePlateStore(useEventPlateId(id));

  const value = store.get.value();
  const count = useMemo(
    () => getRichTextCharacterCount(value as TDescendant[]),
    [value],
  );

  return <Container>{count}</Container>;
};
