import { PIcon } from '@porsche-design-system/components-react';
import { dropShadowHighStyle } from '@porsche-design-system/components-react/styles';
import { ReactNode } from 'react';
import { Spacer } from '.';
import { styled } from '../stitches.config';

const InlineSidebarWrapper = styled('div', {
  boxSizing: 'border-box',
  position: 'absolute',
  pointerEvents: 'all',
  right: 0,
  top: '-1rem',
  bottom: '0',
  width: '380px',
  backgroundColor: '$white',
  padding: '$medium',
  zIndex: '$sidebar',
  overflowY: 'auto',

  variants: {
    isOpen: {
      true: {
        padding: '$large',
        ...dropShadowHighStyle,
      },
      false: {
        borderLeft: '1px solid $contrastLow',
        width: '60px',
      },
    },
  },
});

const ToggleIcon = styled(PIcon, {
  marginBottom: '$small',
  borderRadius: '$small',
  marginRight: '$small',

  '&:hover': {
    backgroundColor: '$hover',
  },
});

const ToggleButton = styled('div', {
  cursor: 'pointer',
  fontSize: '1.2em',

  variants: {
    isVertical: {
      true: {
        'text-orientation': 'sideways',
        'writing-mode': 'vertical-lr',

        [`& ${ToggleIcon}`]: {
          marginRight: 0,
        },
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

export const InlineSidebar = ({
  titleClosed,
  titleOpen,
  isOpen = false,
  onToggle,
  toggleSpacing,
  children,
}: {
  titleClosed: string;
  titleOpen: string;
  isOpen?: boolean;
  toggleSpacing?: string;
  onToggle: (isOpen: boolean) => void;
  children: ReactNode;
}) => (
  <InlineSidebarWrapper isOpen={isOpen} data-cy="sidebar" data-testid="sidebar">
    {isOpen ? (
      <>
        <ToggleButton
          role="button"
          onClick={() => {
            onToggle(!isOpen);
          }}
        >
          <ToggleIcon name="arrow-head-right" />
          {titleOpen}
        </ToggleButton>

        <Spacer mt="$medium" />

        {children}
      </>
    ) : (
      <ToggleButton
        role="button"
        isVertical={true}
        css={{ paddingTop: toggleSpacing }}
        onClick={() => {
          onToggle(!isOpen);
        }}
      >
        <ToggleIcon name="arrow-head-left" />
        {titleClosed}
      </ToggleButton>
    )}
  </InlineSidebarWrapper>
);
