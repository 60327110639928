import React from 'react';
import { styled } from '../stitches.config';

const Component = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$medium',
  background: '$white',

  variants: {
    spacing: {
      none: {},
      large: {
        marginTop: '$large',
      },
      medium: {
        marginTop: '$medium',
      },
    },
    centered: {
      true: {
        justifyContent: 'center',
      },
    },
    fullWidth: {
      true: {
        '& > *': {
          flex: '1 1 100%',
        },
      },
    },
  },
});

export const ActionGroup = ({
  spacing = 'large',
  fullWidth,
  centered,
  children,
  ...props
}: {
  spacing?: 'none' | 'large' | 'medium';
  fullWidth?: boolean;
  centered?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Component
      spacing={spacing}
      centered={centered}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </Component>
  );
};
