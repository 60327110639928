import {
  PButton,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { ActionGroup } from '@porsche-kado/ui';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { clearSettings, getSettings, setSettings } from './helper';

type Inputs = {
  ppnuid: string;
};

export const AssumeUser = ({ onClose }: { onClose: () => void }) => {
  const settings = getSettings();

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      ppnuid: settings?.ppnuid,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    data.ppnuid.length > 0 ? setSettings(data) : clearSettings();
    onClose();
    window.location.reload();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PTextFieldWrapper label="PPNUID" submitButton={false}>
        <input
          type="search"
          placeholder="mustermannma"
          {...register('ppnuid')}
        />
      </PTextFieldWrapper>

      <ActionGroup>
        <PButton
          type="button"
          variant="secondary"
          icon="close"
          onClick={onClose}
        >
          Close
        </PButton>
        <PButton type="submit">Save</PButton>
      </ActionGroup>
    </form>
  );
};
