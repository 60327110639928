import type * as Stitches from '@stitches/react';
import { config, styled } from '../stitches.config';

type Scale = Stitches.ScaleValue<'space', typeof config>;

const Wrapper = styled('div');

const predefinedSpacers = {
  vertical: {
    form: {
      marginBottom: '$medium',
    },
  },
} satisfies Record<string, Record<string, Stitches.CSS<typeof config>>>;

export const Spacer = ({
  vertical,
  pt,
  pb,
  mt,
  mb,
  css,
}: {
  vertical?: keyof (typeof predefinedSpacers)['vertical'];
  pt?: Scale;
  pb?: Scale;
  mt?: Scale;
  mb?: Scale;
  css?: Stitches.CSS<typeof config>;
}) => (
  <Wrapper
    css={{
      ...(vertical && predefinedSpacers.vertical[vertical]),

      ...(pt && { paddingTop: pt }),
      ...(pb && { paddingBottom: pb }),
      ...(mt && { marginTop: mt }),
      ...(mb && { marginBottom: mb }),

      ...css,
    }}
  />
);
