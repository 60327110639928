import { useMatch } from '@tanstack/react-router';

export const usePersonIdParam = () => {
  return [
    useMatch({
      from: '/admin/person-info/$personId/account',
      shouldThrow: false,
    }),
    useMatch({
      from: '/admin/person-info/$personId/settings',
      shouldThrow: false,
    }),
    useMatch({
      from: '/admin/person-info/$personId/consents',
      shouldThrow: false,
    }),
    useMatch({
      from: '/admin/person-info/$personId/groups',
      shouldThrow: false,
    }),
    useMatch({
      from: '/admin/person-info/$personId/organizations',
      shouldThrow: false,
    }),
    useMatch({
      from: '/admin/person-info/$personId/$app',
      shouldThrow: false,
    }),
  ]
    .map((match) => match?.params.personId)
    .find((p) => p !== undefined);
};
