import { getPluginOptions, PlateRenderElementProps } from '@udecode/plate-core';
import { ELEMENT_MENTION, ELEMENT_MENTION_INPUT } from '@udecode/plate-mention';
import { useSelected } from 'slate-react';
import { styled } from '../../../stitches.config';

const Container = styled('span', {
  paddingInline: '$xSmall',
  background: '$contrastLow',
  color: '$black',

  variants: {
    selected: {
      true: {
        background: '$contrastLow',
      },
    },
  },
});

const Mention = ({
  attributes,
  nodeProps,
  element,
  editor,
  children,
}: PlateRenderElementProps & {
  prefix?: string;
  element: { value: { type: string } };
}) => {
  const selected = useSelected();

  const { MentionLabel, mentionProps } = getPluginOptions<{
    MentionLabel?: React.FunctionComponent<{ value: { type: string } }>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mentionProps?: any;
  }>(editor, ELEMENT_MENTION);

  return (
    <Container
      {...attributes}
      {...nodeProps}
      contentEditable={false}
      selected={selected}
    >
      {MentionLabel ? (
        <MentionLabel {...mentionProps} value={element.value} />
      ) : (
        JSON.stringify(element.value)
      )}
      {children}
    </Container>
  );
};

const MentionInput = ({
  attributes,
  nodeProps,
  children,
}: PlateRenderElementProps) => (
  <span {...attributes} {...nodeProps}>
    {children}
  </span>
);

export const mentionComponents = {
  [ELEMENT_MENTION]: Mention,
  [ELEMENT_MENTION_INPUT]: MentionInput,
};
