import {
  PIcon,
  PTabs,
  PTabsItem,
} from '@porsche-design-system/components-react';
import { Modal, styled } from '@porsche-kado/ui';
import { CSSProperties, useCallback, useState } from 'react';
import { AssumeUser } from './AssumeUser';
import { isDevToolsActive } from './helper';
import kadoFillIconSrc from './static/kado-fill.svg';
import kadoOutlineIconSrc from './static/kado-outline.svg';

const Icon = styled(PIcon, {
  bottom: 0,
  margin: '$medium',
  position: 'fixed',
  right: 0,
  zIndex: '$devTools',
  cursor: 'pointer',
});

export const DevTools = ({ style }: { style?: CSSProperties }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <Icon
        size="medium"
        color="inherit"
        style={style}
        onClick={handleModalOpen}
        source={isDevToolsActive() ? kadoFillIconSrc : kadoOutlineIconSrc}
      />

      <Modal
        open={isModalOpen}
        onDismiss={handleModalClose}
        aria={{ 'aria-label': 'DevTools' }}
      >
        <Modal.Header>DevTools</Modal.Header>

        <PTabs>
          <PTabsItem label="Assume User">
            <AssumeUser onClose={handleModalClose} />
          </PTabsItem>
        </PTabs>
      </Modal>
    </>
  );
};
