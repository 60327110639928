export const fileTypes = [
  { type: 'text/plain', extension: 'txt' },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx',
  },
  { type: 'application/msword', extension: 'doc' },
  { type: 'text/csv', extension: 'csv' },
  { type: 'application/pdf', extension: 'pdf' },
  { type: 'message/rfc822', extension: 'eml' },
  { type: 'application/vnd.ms-outlook', extension: 'msg' },
  { type: 'application/vnd.ms-excel', extension: 'xls' },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
  },
  { type: 'application/vnd.ms-excel.sheet.macroenabled.12', extension: 'xlsm' },
  { type: 'application/vnd.ms-powerpoint', extension: 'ppt' },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: 'pptx',
  },
  { type: 'image/png', extension: 'png' },
  { type: 'image/bmp', extension: 'bmp' },
  { type: 'image/gif', extension: 'gif' },
  { type: 'image/jpeg', extension: 'jpg' },
  { type: 'image/pjpeg', extension: 'jpeg' },
  { type: 'video/x-ms-wmv', extension: 'wmv' },
  { type: 'video/mpeg', extension: 'mpeg' },
  { type: 'video/mp4', extension: 'mp4' },
  { type: 'video/ogg', extension: 'ogg' },
  { type: 'video/x-msvideo', extension: 'avi' },
  { type: 'video/webm', extension: 'webm' },
  { type: 'video/quicktime', extension: 'mov' },
];

export const imageTypes = [
  { type: 'image/png', extension: 'png' },
  { type: 'image/bmp', extension: 'bmp' },
  { type: 'image/gif', extension: 'gif' },
  { type: 'image/jpeg', extension: 'jpg' },
  { type: 'image/pjpeg', extension: 'jpeg' },
];
