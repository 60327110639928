import { PInlineNotification } from '@porsche-design-system/components-react';
import { styled } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import { useAnnouncementsQuery } from '../graphql';
import { isAnnouncementCurrentlyActive } from '../helpers';

const Container = styled('div', {
  marginTop: '$large',
});

export const Announcement = () => {
  const { t } = useTranslation();

  const { data: announcement } = useAnnouncementsQuery(undefined, {
    select: (data) => data.announcements[0],
  });

  return (
    announcement &&
    isAnnouncementCurrentlyActive(announcement) && (
      <Container>
        <PInlineNotification
          heading={t('admin.announcement.title')}
          description={announcement.message}
          state="info"
          dismissButton={false}
        />
      </Container>
    )
  );
};
