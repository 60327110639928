import { Indicator, NavigationItem, NavigationLink } from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  seenSupportRequests,
  seenSupportRequestsComments,
} from '../../config/localStorageKeys';
import { useAbilityContext } from '../../context';
import { useSupportRequestIdsQuery } from '../../graphql';

export const SupportNavigationItem = () => {
  const { t } = useTranslation();
  const ability = useAbilityContext();
  const [unreadCount, setUnreadCount] = useState(0);
  const { data, isFetching } = useSupportRequestIdsQuery();

  // Rerender and check against localStorage on every re-fetch, even if query result is the same.
  useEffect(() => {
    const unreadSupportRequets =
      data?.supportRequests.filter(
        ({ id }) => !localStorage.getItem(seenSupportRequests(id)),
      ) ?? [];

    const unreadComments =
      data?.supportRequests
        .flatMap(({ id, comments }) =>
          comments.map((comment) => ({
            id,
            commentId: comment.id,
          })),
        )
        .filter(
          ({ id, commentId }) =>
            !localStorage.getItem(seenSupportRequestsComments(id, commentId)),
        ) ?? [];

    setUnreadCount(unreadSupportRequets.length + unreadComments.length);
  }, [isFetching, data]);

  if (ability.can('read', 'SupportRequest')) {
    return (
      <NavigationItem>
        <Indicator
          label={unreadCount}
          disabled={(unreadCount ?? 0) === 0}
          active={(unreadCount ?? 0) > 0}
          aria-label={t('unread', { count: unreadCount ?? 0 })}
        >
          <NavigationLink icon="chat" hideLabel>
            <Link to="/support">{t('supportRequest.headline')}</Link>
          </NavigationLink>
        </Indicator>
      </NavigationItem>
    );
  }

  return null;
};
