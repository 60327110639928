import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { usePersonContext } from '../context';
import { isAnnouncementCurrentlyActive } from '../helpers/announcement';

type Announcement = {
  from?: string | null;
  id: string;
  isActive: boolean;
  message: string;
  to?: string | null;
};

export const useAnnouncementMessage = () => {
  const { t } = useTranslation();
  const dateFormat =
    usePersonContext().state.settings?.dateFormat || 'YYYY/MM/DD';

  const message = (announcement: Announcement) => {
    if (!announcement.isActive) return;

    if (isAnnouncementCurrentlyActive(announcement)) {
      return t('admin.announcement.currentlyActive');
    }

    if (dayjs(announcement.from).isAfter(new Date())) {
      return t('admin.announcement.willBeActiveAtDate', {
        date: dayjs(announcement.from).format(dateFormat),
      });
    }

    if (dayjs(announcement.to).isBefore(new Date())) {
      return t('admin.announcement.isNotActiveAnymore', {
        date: dayjs(announcement.to).format(dateFormat),
      });
    }
  };

  return { message };
};
