import { Analytics as AmplifyAnalytics } from '@aws-amplify/analytics';
import { sha256 } from 'crypto-hash';
// TODO: Update import after Jest update
// import { logger } from '@porsche-kado/logger/client';
import { logger } from '@porsche-kado/logger/src/clientLogger';
import KadoAnalyticsProvider, {
  KadoAnalyticsConfigureProps,
} from './KadoAnalyticsProvider';

type ConfigProps = {
  autoSessionRecord?: boolean;
  disabled?: boolean;
} & KadoAnalyticsConfigureProps;

type PersonProps = {
  id: number;
  organizationId?: number;
  role?: string;
};

export enum AnalyticsTrackEvent {
  Login = 'LOGIN',
  OfflineMode = 'OFFLINE_MODE',
  OpenChangeHistory = 'OPEN_CHANGE_HISTORY',
}
export type AnalyticsTrackEventProperties = Record<string, string>;
export type AnalyticsTrackConfig = { immediate?: boolean };

class AnalyticsClass {
  private person: PersonProps;

  get userHash() {
    return sha256(this.person.id.toString());
  }

  constructor() {
    this.person = {} as PersonProps;
  }

  public configure(config: ConfigProps) {
    logger.debug('Analytics#configure', config);

    AmplifyAnalytics.configure(config);
    AmplifyAnalytics.addPluggable(new KadoAnalyticsProvider());

    if ('newrelic' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newrelic = window.newrelic as any;

      if (config.KadoAnalytics.appVersion) {
        newrelic.setCustomAttribute(
          'appVersion',
          config.KadoAnalytics.appVersion,
        );
      }
    }
  }

  public identify(person: PersonProps) {
    this.person = person;

    this.userHash.then((hash) => {
      if ('newrelic' in window) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newrelic = window.newrelic as any;
        newrelic.setUserId(hash);

        if (person.organizationId) {
          newrelic.setCustomAttribute('organizationId', person.organizationId);
        }

        if (person.role) {
          newrelic.setCustomAttribute('role', person.role);
        }
      }
    });
  }

  public async track(
    event: AnalyticsTrackEvent,
    eventProperties?: AnalyticsTrackEventProperties,
    config?: AnalyticsTrackConfig,
  ) {
    return await AmplifyAnalytics.record(
      {
        partitionKey: '', // Not necessary for AWSKinesisFirehose
        data: {
          event,
          properties: eventProperties,
          user_hash: await this.userHash,
          ...(this.person.organizationId && {
            organization_id: this.person.organizationId,
          }),
          ...(this.person.role && { role: this.person.role }),
        },

        streamName: `analytics-stream-${process.env.REACT_APP_ENVIRONMENT_SLUG}`,
        immediate: config?.immediate ?? false,
      },
      'KadoAnalytics',
    );
  }
}

export const Analytics = new AnalyticsClass();
