import { gql } from '../lib/graphqlRequest';

export const uploadUrls = gql`
  query uploadUrls(
    $filenames: [String!]!
    $type: UploadType!
    $reference: UploadReference!
  ) {
    uploadUrls(filenames: $filenames, type: $type, reference: $reference) {
      urls
      kmsKeyId
    }
  }
`;

export const createDownloadUrl = gql`
  mutation createDownloadUrl(
    $id: ObjectID!
    $type: DownloadType!
    $reference: DownloadReference!
  ) {
    createDownloadUrl(id: $id, type: $type, reference: $reference)
  }
`;
