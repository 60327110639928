export const apps = (
  rules: Record<'action' | 'subject' | 'fields', unknown>[],
): string[] => {
  const apps = rules.filter(
    (rule) => rule.action === 'access' && rule.subject === 'App',
  );

  return (
    apps.flatMap((app) => {
      const fields = app.fields as string | string[];
      return Array.isArray(fields) ? fields : [fields];
    }) ?? []
  );
};
