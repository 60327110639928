import {
  PHeading,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { styled, theme } from '@porsche-kado/ui';
import KadoLogo from '@porsche-kado/ui/icons/kado.svg?react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { Announcement, FeatureArticleTable } from '../components';
import { NAMESPACES } from '../config/i18n';
import { useAbilityContext } from '../context';
import porscheLogoSrc from '../static/porsche.svg';

const TableWrapper = styled('div', {
  maxWidth: '1390px',
  margin: '$large auto 0',
});

const Apps = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: '1420px',
  margin: '$large auto',
});

const Tile = styled('a', {
  border: '2px solid $contrastLow',
  borderRadius: '$medium',
  boxSizing: 'border-box',
  color: '$text',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 700,
  height: '100px',
  justifyContent: 'center',
  margin: '$medium',
  padding: '$medium $medium',
  textDecoration: 'none',
  transition: 'background 0.3s ease-in-out',
  width: '250px',

  '&[href]': {
    borderColor: '$contrastHigh',
  },
  '&[href]:hover': {
    backgroundColor: '$contrastLow',
  },
});

export const Dashboard = (): JSX.Element => {
  const { t } = useTranslation(NAMESPACES);
  const ability = useAbilityContext();

  const appCount =
    ability.rulesFor('access', 'App').flatMap((rules) => rules.fields).length ??
    0;

  return (
    <>
      <PHeading
        align="center"
        aria-level={1}
        role="heading"
        tag="h1"
        size="xx-large"
      >
        <KadoLogo aria-hidden style={{ verticalAlign: 'middle' }} />
        <span style={{ verticalAlign: 'middle' }}>{t('common:title')}</span>
      </PHeading>

      <Announcement />

      <Apps>
        {ability.can('access', 'App', 'FieldForceManagement') && (
          <Tile href="/field-force-management/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.ffm')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'Greenland') && (
          <Tile href="/retail-data-access/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.greenland')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'PorscheStrategy2030') && (
          <Tile href="/porsche-strategy-2030/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.porscheStrategy2030')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'NetworkPlanning') && (
          <Tile href="/network-planning/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.networkPlanning')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'RetailDesign') && (
          <Tile href="/retail-design/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.retailDesign')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'Survey') && (
          <Tile href="/survey/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.survey')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'MysteryShopping') && (
          <Tile href="/mystery-shopping/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">{t('common:app.mysteryShopping')}</PText>
          </Tile>
        )}

        {ability.can('access', 'App', 'MultisensoryExperience') && (
          <Tile href="/multisensory-experience/">
            <PIcon
              source={porscheLogoSrc}
              size="inherit"
              color="inherit"
              style={{ width: '100%' }}
            />
            <PText weight="bold">
              {t('common:app.multisensoryExperience')}
            </PText>
          </Tile>
        )}

        {[...Array(4 - (appCount > 4 ? 4 : appCount)).keys()].map((key) => (
          <Tile as="div" key={`tile_${key}`}>
            <ContentLoader
              title=""
              animate={false}
              backgroundColor={theme.colors.contrastLow.toString()}
              foregroundColor={theme.colors.contrastLow.toString()}
              viewBox="0 0 214 54"
            >
              <rect x="0" y="0" width="190" height="20" />
              <rect x="0" y="25" width="214" height="5" />
              <rect x="0" y="39" width="150" height="15" />
            </ContentLoader>
          </Tile>
        ))}
      </Apps>

      <PHeading
        align="center"
        role="heading"
        aria-level={2}
        tag="h2"
        size="large"
      >
        {t('featureArticles')}
      </PHeading>

      <TableWrapper>
        <FeatureArticleTable />
      </TableWrapper>
    </>
  );
};
