import { PTag, PTagProps } from '@porsche-design-system/components-react';
import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { styled } from '@porsche-kado/ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { App } from '../config/app';
import { NAMESPACES } from '../config/i18n';

export const APP_FALLBACK = 'kado';

export const getAppName = (app: string, t: TFunction) => {
  if (app === APP_FALLBACK) {
    return t('title', { ns: COMMON_NAMESPACE });
  }

  const name = Object.keys(App)[Object.values(App).indexOf(app as App)];

  return t(
    `app.${
      app === App.FieldForceManagement
        ? 'ffm'
        : ((name[0].toLowerCase() + name.slice(1)) as 'dashboard')
    }`,
    { ns: COMMON_NAMESPACE },
  );
};

export const ApplicationTags = ({
  apps,
  color,
}: {
  apps: string[];
  color?: PTagProps['color'];
}) => {
  const { t } = useTranslation(NAMESPACES);

  return (
    <ApplicationTagsContainer>
      {apps.map((app) => (
        <PTag key={app} color={color}>
          {getAppName(app, t)}
        </PTag>
      ))}
    </ApplicationTagsContainer>
  );
};

const ApplicationTagsContainer = styled('div', {
  display: 'inline-grid',
  gap: '$small',
  gridAutoFlow: 'column',
  gridAutoColumns: 'max-content',
});
