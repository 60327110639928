import { createFileRoute, useParams } from '@tanstack/react-router';
import { useFeatureArticleQuery } from '../../graphql';
import { FeatureArticle } from '../../pages';

export const Route = createFileRoute('/news/$featureArticleId')({
  component: () => <FeatureArticle />,
  beforeLoad: () => {
    return {
      breadcrumb: () => <FeatureArticleBreadcrumb />,
    };
  },
  validateSearch: (input) => ({
    ...(input.preview ? { preview: Number(input.preview) } : {}),
  }),
});

const FeatureArticleBreadcrumb = () => {
  const { featureArticleId: id } = useParams({
    from: '/news/$featureArticleId',
  });
  const { data } = useFeatureArticleQuery({ id });

  return <>{data?.featureArticle?.title}</>;
};
