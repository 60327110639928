import {
  insertNodes,
  insertText,
  moveSelection,
  withoutNormalizing,
} from '@udecode/plate-common';
import {
  createPluginFactory,
  getPlugin,
  getPluginOptions,
} from '@udecode/plate-core';
import {
  ELEMENT_MENTION,
  MentionPlugin,
  TMentionElement,
} from '@udecode/plate-mention';

export const createPastePlugin = createPluginFactory<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPasteMention?: (data: any) => boolean;
  removeFormattings?: boolean;
}>({
  key: 'paste',
  options: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPasteMention: (_data: any) => true,
    removeFormattings: false,
  },
  handlers: {
    onPaste: (editor) => (e) => {
      try {
        const clipboardData = e.clipboardData.getData('text/plain');

        if (clipboardData.includes('$KADORichTextEditor')) {
          const data = JSON.parse(clipboardData);

          if (data['$KADORichTextEditor']) {
            e.preventDefault();

            const value = data['$KADORichTextEditor'];

            const {
              type,
              options: { insertSpaceAfterMention },
            } = getPlugin<MentionPlugin>(editor, ELEMENT_MENTION);

            const { onPasteMention } = getPluginOptions<{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onPasteMention: (data: any) => boolean;
            }>(editor, 'paste');

            if (!onPasteMention(value)) {
              return true;
            }

            withoutNormalizing(editor, () => {
              // Selectors are sensitive to operations, it's better to create everything
              // before the editor state is changed. For example, asking for text after
              // removeNodes below will return null.

              insertNodes<TMentionElement>(editor, {
                type,
                children: [{ text: '' }],
                value,
              } as TMentionElement);

              // move the selection after the element
              moveSelection(editor, { unit: 'offset' });

              if (insertSpaceAfterMention) {
                insertText(editor, ' ');
              }
            });

            return true;
          }
        }

        const { removeFormattings } = getPluginOptions<{
          removeFormattings?: boolean;
        }>(editor, 'paste');

        if (removeFormattings) {
          withoutNormalizing(editor, () => {
            e.preventDefault();
            editor.insertText(
              e.clipboardData.getData('text/plain').replace(/\n|\r/g, ''),
            );

            return true;
          });
        }
      } catch {
        // ignore
      }
    },
  },
});
