import { PIcon } from '@porsche-design-system/components-react';
import { styled } from '../stitches.config';

const MessageWrapper = styled('div', {
  marginTop: '$xSmall',

  variants: {
    state: {
      info: {
        color: '$default',
      },
      error: {
        color: '$error',
      },
    },
  },

  defaultVariants: {
    state: 'error',
  },
});

const Icon = styled(PIcon, {
  marginRight: '$xSmall',
});

export const Message = ({
  value,
  state = 'error',
}: {
  value?: string;
  state?: 'error' | 'info';
}) => {
  if (!value) return null;

  return (
    <MessageWrapper state={state}>
      <Icon
        name="information"
        {...(state === 'error' && { color: 'notification-error' })}
      />
      {value}
    </MessageWrapper>
  );
};
