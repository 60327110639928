import { PHeading } from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import { FeatureArticleTable } from '../components';

export const FeatureArticles = () => {
  const { t } = useTranslation();

  return (
    <>
      <PHeading role="heading" aria-level={2} tag="h2" size="large">
        {t('featureArticles')}
      </PHeading>

      <Spacer mb="$medium" />

      <FeatureArticleTable />
    </>
  );
};
