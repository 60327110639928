import ContentLoader from 'react-content-loader';
import { theme } from '../stitches.config';

export const TableCellLoader = () => (
  <ContentLoader
    backgroundColor={theme.colors.backgroundSurface.toString()}
    foregroundColor={theme.colors.contrastLow.toString()}
    height={8}
    width={150}
  >
    <rect x="0" y="0" width="150" height="8" />
  </ContentLoader>
);
