import {
  PButton,
  PButtonPure,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { useEffect, useState } from 'react';
import { styled } from '../stitches.config';
import { ActionGroup } from './ActionGroup';
import { Modal } from './Modal';

const ItemWrapper = styled('div', {
  display: 'inline-flex',
  maxWidth: 250,
  marginBottom: '$small',

  variants: {
    download: {
      true: {
        cursor: 'pointer',
      },
    },
  },
});

const InfoWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  whiteSpace: 'nowrap',
  columnGap: 8,
});

const LabelWrapper = styled('div', {
  gridColumn: 'span 2',
  display: 'grid',
  gridTemplateColumns: 'calc(100% - 40px) auto',
});

const MetaText = styled(PText, { overflow: 'hidden' });

export const AttachmentList = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return <div>{children}</div>;
};

export const AttachmentListItem = ({
  children,
  onClick,
  canPreview,
  previewHeading,
  onPreview,
  i18n: { cancel, download },
}: {
  children: React.ReactNode;
  onClick?: () => void;
  canPreview?: boolean;
  previewHeading?: string;
  onPreview?: () => Promise<string>;
  i18n: { cancel: string; download: string };
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  useEffect(() => {
    if (showPreview) {
      setPreviewUrl(undefined);
      onPreview?.().then((url) => setPreviewUrl(url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPreview]);

  return (
    <>
      <ItemWrapper
        onClick={canPreview ? () => setShowPreview(true) : onClick}
        download={!!onClick || canPreview}
      >
        <PIcon
          size="large"
          name="document"
          aria={{ 'aria-label': 'Attachment' }}
        />
        <InfoWrapper>{children}</InfoWrapper>
      </ItemWrapper>

      <Modal
        open={showPreview}
        size="large"
        onDismiss={() => setShowPreview(false)}
        aria={{ 'aria-label': previewHeading }}
      >
        <Modal.Header>{previewHeading}</Modal.Header>

        <img width="100%" src={previewUrl} />

        <ActionGroup>
          <PButton
            type="button"
            variant="secondary"
            onClick={() => setShowPreview(false)}
          >
            {cancel}
          </PButton>
          <PButton type="button" onClick={onClick}>
            {download}
          </PButton>
        </ActionGroup>
      </Modal>
    </>
  );
};

export const AttachmentListItemLabel = ({
  children,
  actions,
  onDelete,
  i18n: { deleteHeading, yes, cancel } = {},
}: {
  children: string;
  actions?: React.ReactNode;
  onDelete?: () => void;
  i18n?: {
    deleteHeading?: string;
    yes?: string;
    cancel?: string;
  };
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (process.env.NODE_ENV !== 'production') {
    if (onDelete && !(deleteHeading && yes && cancel)) {
      console.warn(
        'You have to provide i18n props when using onDelete prop on AttachmentListItemLabel',
      );
    }
  }

  return (
    <>
      <LabelWrapper>
        <PText ellipsis title={children}>
          {children}
        </PText>
        <div>
          {onDelete && (
            <PButtonPure
              icon="delete"
              type="button"
              hideLabel
              onClick={(evt) => {
                evt.stopPropagation();
                setShowDeleteModal(true);
              }}
            />
          )}
          {actions}
        </div>
      </LabelWrapper>

      {onDelete && (
        <Modal
          open={showDeleteModal}
          size="small"
          onClick={(evt) => {
            // Avoid downloading the attachment when clicking on any place other than the buttons
            evt.stopPropagation();
          }}
          onDismiss={(evt) => {
            evt.stopPropagation();
            setShowDeleteModal(false);
          }}
          aria={{ 'aria-label': deleteHeading }}
        >
          <Modal.Header>{deleteHeading}</Modal.Header>

          <ActionGroup spacing="none">
            <PButton
              type="button"
              variant="secondary"
              onClick={(evt) => {
                evt.stopPropagation();
                setShowDeleteModal(false);
              }}
            >
              {cancel}
            </PButton>
            <PButton
              type="button"
              variant="primary"
              onClick={(evt) => {
                evt.stopPropagation();
                onDelete();
              }}
            >
              {yes}
            </PButton>
          </ActionGroup>
        </Modal>
      )}
    </>
  );
};

export const AttachmentListItemMeta = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => {
  return (
    <>
      <MetaText size="x-small" color="contrast-medium" ellipsis>
        {label}:
      </MetaText>
      <MetaText size="x-small" color="contrast-medium" ellipsis>
        {value}
      </MetaText>
    </>
  );
};

AttachmentList.Item = AttachmentListItem;
AttachmentListItem.Label = AttachmentListItemLabel;
AttachmentListItem.Meta = AttachmentListItemMeta;
