/* eslint-disable react-hooks/rules-of-hooks */
import {
  Breadcrumbs,
  ContentWrapper,
  Divider,
  Main,
  Page,
  PageNotAvailable,
} from '@porsche-kado/ui';
import {
  AnyRouteMatch,
  createRootRouteWithContext,
} from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Footer, Header } from '../components';
import { COMMON_NAMESPACE, NAMESPACES } from '../config/i18n';
import { usePersonContext } from '../context';
import { SignIn } from '../pages';

const TanStackRouterDevtools = lazy(() =>
  import('@tanstack/router-devtools').then((module) => ({
    default: module.TanStackRouterDevtools,
  })),
);

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then((module) => ({
    default: module.ReactQueryDevtools,
  })),
);

const DevTools = lazy(() =>
  import('@porsche-kado/devtools').then((module) => ({
    default: module.DevTools,
  })),
);

interface KadoRouterContext {
  breadcrumb?: (match: AnyRouteMatch) => React.ReactElement;
}

export const Route = createRootRouteWithContext<KadoRouterContext>()({
  component: () => {
    const { t } = useTranslation(NAMESPACES);
    const { state: personState } = usePersonContext();

    return (
      <>
        <Page>
          <Header />
          {personState.isAuthenticated && <Divider />}
          <Main>
            <ContentWrapper>
              {personState.isAuthorized && (
                <Breadcrumbs
                  kadoName={t('common:title')}
                  appName={t('common:app.dashboard')}
                />
              )}

              <SignIn />
            </ContentWrapper>
          </Main>
          <Footer />
        </Page>

        {process.env.NODE_ENV === 'development' && (
          <Suspense fallback={<></>}>
            {/* Workaround for https://github.com/TanStack/react-location/issues/219 */}
            <TanStackRouterDevtools
              toggleButtonProps={{ style: { marginLeft: '4rem' } }}
              closeButtonProps={{ style: { marginLeft: '4rem' } }}
            />
            <ReactQueryDevtools />
            <DevTools />
          </Suspense>
        )}
      </>
    );
  },
  notFoundComponent: () => (
    <PageNotAvailable
      text={<Trans i18nKey="pageNotAvailable" ns={COMMON_NAMESPACE} />}
    />
  ),
});
