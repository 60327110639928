import {
  PButtonPure,
  PButtonPureProps,
  PIcon,
  PIconProps,
} from '@porsche-design-system/components-react';
import {
  motionDurationShort,
  motionEasingBase,
} from '@porsche-design-system/components-react/styles';
import { styled } from '@stitches/react';
import { SortDirection } from '@tanstack/react-table';

const SortIcon = styled(PIcon, {
  marginLeft: '$small',
  opacity: 0,

  variants: {
    isActive: {
      true: {
        opacity: 1,
      },
    },
  },

  transition: `opacity ${motionDurationShort} ${motionEasingBase}`,
});

const SortButton = styled(PButtonPure, {
  fontWeight: 'bold',

  '& > span': {
    fontWeight: 600,
  },

  '&:hover': {
    [`& ${SortIcon}`]: {
      opacity: 1,
    },
  },
});

export const TableHeadFilter = styled('div', {
  display: 'inline-block',
  marginLeft: '$small',
});

export const TableHeadSortButton = ({
  children,
  direction = 'asc',
  isActive,
  ...props
}: PButtonPureProps & { direction?: SortDirection; isActive?: boolean }) => {
  const iconName: Record<SortDirection, PIconProps['name']> = {
    asc: 'arrow-up',
    desc: 'arrow-down',
  };

  return (
    <SortButton {...props} icon="none" size="x-small">
      <span>{children}</span>
      <SortIcon isActive={isActive} name={iconName[direction]} size="x-small" />
    </SortButton>
  );
};
