import {
  ToastMessage,
  useToastManager,
} from '@porsche-design-system/components-react';

export const useNotification = () => {
  const { addMessage } = useToastManager();

  const addToast = (toast: ToastMessage) => {
    addMessage(toast);
  };

  return { addToast };
};
