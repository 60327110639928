import { PLinkPure, PTag } from '@porsche-design-system/components-react';
import { DataTable } from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../config/i18n';
import { useFeatureArticlesQuery } from '../graphql';
import { APP_FALLBACK, ApplicationTags, getAppName } from './ApplicationTags';
import { DateOutput } from './Output';

const PAGE_SIZE = 5;

export const FeatureArticleTable = () => {
  const { t } = useTranslation(NAMESPACES);

  const { data, isLoading } = useFeatureArticlesQuery();

  const filteredArticles = useMemo(
    () =>
      data?.featureArticles
        .filter(
          (feature) =>
            feature.isPublished &&
            dayjs(feature.publishedAt, 'YYYY-MM-DD').isBefore(dayjs()),
        )
        .map((feature) => ({
          ...feature,
          apps: feature.apps.length > 0 ? feature.apps : [APP_FALLBACK],
        })),
    [data],
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<NonNullable<typeof filteredArticles>[number]>();

    return [
      columnHelper.accessor('title', {
        cell: (data) => (
          <PLinkPure icon="none" stretch>
            <Link to={`/news/${data.row.original.id}`}>{data.getValue()}</Link>
          </PLinkPure>
        ),
        header: () => t('featureArticle.title'),
      }),
      columnHelper.accessor('apps', {
        cell: (data) => <ApplicationTags apps={data.getValue()} />,
        header: () => t('apps'),
        meta: {
          filterType: 'select',
          filterOptions: [
            ...new Set(filteredArticles?.flatMap((article) => article.apps)),
          ].map((app) => ({
            label: getAppName(app, t),
            value: app,
          })),
        },
        filterFn: (row, columnId, filterValue) =>
          (row.getValue(columnId) as string[]).includes(filterValue),
      }),
      columnHelper.accessor('publishedAt', {
        cell: (data) => {
          const publishDate = data.getValue();

          return publishDate ? <DateOutput date={publishDate} /> : 'n/a';
        },
        header: () => t('publishDate'),
        enableColumnFilter: false,
      }),
      columnHelper.accessor('id', {
        cell: (data) =>
          !localStorage.getItem(
            `kado.readFeatureArticle.${data.row.original.id}`,
          ) &&
          dayjs(data.row.original.publishedAt).isAfter(dayjs('2023-08-22')) ? (
            <PTag color="notification-info-soft">{t('unread')}</PTag>
          ) : null,
        header: () => null,
        enableSorting: false,
        enableColumnFilter: false,
      }),
    ];
  }, [filteredArticles, t]);

  return (
    <DataTable
      caption={t('featureArticles')}
      isLoading={isLoading}
      data={filteredArticles ?? []}
      columns={columns}
      idAccessor="id"
      pagination={{
        position: 'bottom',
        pageSize: PAGE_SIZE,
      }}
      i18n={{
        filterLabel: (columnName) =>
          t('common:iconLabel.filter', { columnName }),
        optionAll: t('common:all'),
        buttonReset: t('common:action.reset'),
        buttonFilter: t('common:action.filter'),
        actionSearch: t('common:action.search'),
        noData: t('common:noData'),
      }}
    />
  );
};
