import { subject } from '@casl/ability';
import {
  PButton,
  PCheckboxWrapper,
  PHeading,
  PInlineNotification,
  PLinkPure,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import {
  ActionGroup,
  DataTable,
  dateBetweenFilterFn,
  Editor,
  Modal,
  Spacer,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ClientError } from 'graphql-request';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApplicationSelect,
  DateTimeOutput,
  OrganizationSelect,
  PersonSelect,
  SupportRequestStatusTag,
} from '../components';
import { FileUploader } from '../components/FileUploader';
import { NAMESPACES } from '../config/i18n';
import {
  seenSupportRequests,
  seenSupportRequestsComments,
} from '../config/localStorageKeys';
import { useAbilityContext } from '../context';
import {
  SupportRequestsQuery,
  SupportRequestStatus,
  UploadReference,
  UploadType,
  useCreateSupportRequestMutation,
  useSupportRequestsQuery,
} from '../graphql';
import { useNotification } from '../hooks';
import { uploader, UploadProgress } from '../lib/uploader';

const columnHelper = createColumnHelper<
  SupportRequestsQuery['supportRequests'][0] & { unread: boolean }
>();

export const SupportRequests = () => {
  const { t } = useTranslation(NAMESPACES);
  const ability = useAbilityContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: supportRequests, isLoading } = useSupportRequestsQuery(
    undefined,
    {
      select: (data) => {
        return data.supportRequests.map((supportRequest) => ({
          ...supportRequest,
          unread:
            !localStorage.getItem(seenSupportRequests(supportRequest.id)) ||
            supportRequest.comments.some(
              ({ id }) =>
                !localStorage.getItem(
                  seenSupportRequestsComments(supportRequest.id, id),
                ),
            ),
        }));
      },
    },
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: t('supportRequest.ticketId'),
        enableSorting: false,
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.unread}
            value={data.getValue()}
          />
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: t('common:date'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.unread}
            value={<DateTimeOutput date={data.getValue()} />}
          />
        ),
        filterFn: dateBetweenFilterFn,
        meta: {
          filterType: 'date',
        },
      }),
      columnHelper.accessor('title', {
        header: t('supportRequest.title'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.unread}
            value={data.getValue()}
          />
        ),
      }),
      // Only show author column if user can see other items than their own
      ...(ability.can(
        'read',
        subject('SupportRequest', {
          author: 0,
        }),
      )
        ? [
            columnHelper.accessor('author.name', {
              header: t('common:person'),
              cell: (data) => (
                <LinkedCell
                  id={data.row.original?.id}
                  unread={data.row.original.unread}
                  value={data.getValue()}
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor('status', {
        header: t('common:status'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            value={<SupportRequestStatusTag status={data.getValue()} />}
          />
        ),
        meta: {
          name: t('common:status'),
          filterType: 'select',
          filterOptions: Object.values(SupportRequestStatus).map((status) => ({
            label: t(`supportRequest.statusValues.${status}`),
            value: status,
          })),
        },
        filterFn: 'equalsString',
      }),
    ],
    [t, ability],
  );

  return (
    <>
      <PHeading role="heading" aria-level={2} tag="h2" size="large">
        {t('supportRequest.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <DataTable
        caption={t('supportRequest.requests')}
        data={supportRequests ?? []}
        isLoading={isLoading}
        columns={columns}
        pagination={{
          pageSize: 10,
        }}
        idAccessor="id"
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
        actionButtons={{
          bottom: ability.can('create', 'SupportRequest') && (
            <PButton
              aria={{ 'aria-haspopup': 'dialog' }}
              role="button"
              type="button"
              name={t('supportRequest.createRequest')}
              icon="add"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t('supportRequest.createRequest')}
            </PButton>
          ),
        }}
      />

      <SupportRequestForm
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
      />
    </>
  );
};

const SupportRequestForm = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation(NAMESPACES);
  const { addToast } = useNotification();
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();
  const [uploadError, setUploadError] = useState<unknown | undefined>(
    undefined,
  );

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<{
    title: string;
    application: string;
    organization: {
      id: number;
      name: string;
    };
    referencePerson?: {
      id: number;
      name: string;
    };
    description: string;
    attachments: {
      id: string;
      file: File;
    }[];
    refuseTechnicalDetails: boolean;
  }>({
    defaultValues: {
      refuseTechnicalDetails: false,
      attachments: [],
    },
  });

  const [attachmentController, setAttachmentController] = useState(
    () => new AbortController(),
  );

  const handleDismiss = () => {
    attachmentController.abort;
    setAttachmentController(() => new AbortController());

    onDismiss();
  };

  const { mutateAsync: createSupportRequest } = useCreateSupportRequestMutation(
    {
      onSuccess: (data) => {
        queryClient.setQueryData<SupportRequestsQuery>(
          useSupportRequestsQuery.getKey(),
          (cache) =>
            cache?.supportRequests
              ? {
                  supportRequests: [
                    data.createSupportRequest,
                    ...cache.supportRequests,
                  ],
                }
              : undefined,
        );
      },
    },
  );

  return (
    <Modal
      open={isOpen}
      onDismiss={handleDismiss}
      onMotionHiddenEnd={() => {
        reset();
      }}
    >
      <form
        onSubmit={handleSubmit(async (values) => {
          const {
            organization,
            attachments,
            referencePerson,
            refuseTechnicalDetails,
            ...input
          } = values;

          let uploadFailed = false;
          const uploadedAttachments =
            attachments.length > 0
              ? (
                  await uploader(
                    attachments ?? [],
                    UploadType.Attachment,
                    UploadReference.Dashboard,
                    {
                      onProgress: (progress) => {
                        setUploadProgress({ ...progress });
                      },
                      onFailure: (_file, error) => {
                        uploadFailed = true;

                        // Don't show error if request was canceled by user
                        if (
                          (error as { code?: string })?.code === 'ERR_CANCELED'
                        ) {
                          return;
                        }

                        setUploadError(error);
                      },
                      signal: attachmentController.signal,
                    },
                  )
                ).filter(<T,>(d: T | undefined): d is T => !!d)
              : [];

          if (uploadFailed) {
            return;
          }

          const result = await createSupportRequest({
            input: {
              ...input,
              organizationId: organization.id,
              ...(referencePerson && {
                referencePersonId: referencePerson.id,
              }),

              ...(uploadedAttachments.length > 0 && {
                attachments: uploadedAttachments.map((attachment) => ({
                  id: attachment.id,
                  name: attachment.file.name,
                  size: attachment.file.size,
                  type: attachment.file.type,
                })),
              }),

              ...(!refuseTechnicalDetails && {
                details: {
                  userAgent: navigator.userAgent,
                  browserWindowSize: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                  },
                  screenSize: {
                    width: window.screen.width,
                    height: window.screen.height,
                  },
                },
              }),
            },
          });

          localStorage.setItem(
            seenSupportRequests(result.createSupportRequest.id),
            dayjs().toISOString(),
          );

          addToast({
            text: t('supportRequest.createdSuccessfully'),
            state: 'success',
          });

          onDismiss();
        })}
      >
        <PHeading size="large">{t('supportRequest.createRequest')}</PHeading>

        <Spacer mb="$medium" />

        {!!uploadError && (
          <>
            <PInlineNotification
              heading={t('supportRequest.uploadError')}
              description={`${
                uploadError instanceof ClientError
                  ? uploadError.response.errors?.[0].message ??
                    uploadError.message
                  : `${uploadError}`
              }`}
              state="error"
              dismissButton={false}
            />

            <Spacer mb="$medium" />
          </>
        )}

        <Controller
          control={control}
          name="application"
          rules={{ required: true }}
          render={({
            field: { ref, value, onChange, ...field },
            fieldState,
          }) => (
            <ApplicationSelect
              isRequired
              label={t('supportRequest.application')}
              aria-label={t('supportRequest.application')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={(option) => {
                onChange(option?.value ?? undefined);
              }}
              value={{ value }}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="organization"
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <OrganizationSelect
              label={t('common:organization')}
              aria-label={t('common:organization')}
              isRequired
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={onChange}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="referencePerson"
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <PersonSelect
              label={t('supportRequest.referencedPerson')}
              aria-label={t('supportRequest.referencedPerson')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={onChange}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <PTextFieldWrapper label={t('supportRequest.title')}>
          <input
            type="text"
            required
            {...register('title', { required: true })}
          />
        </PTextFieldWrapper>

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <Editor
              required
              label={t('supportRequest.description')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              initialValue={field.value && JSON.parse(field.value)}
              onChange={(value) => field.onChange(JSON.stringify(value))}
              i18n={{
                gdprTooltip: t('common:editor.confidentialData'),
                captionPlaceholder: t('common:editor.captionPlaceholder'),
                imageUploadHeading: t('common:editor.insertImage'),
                dropzoneDescription: t('common:editor.description'),
                dragDescription: t('common:editor.dragDescription'),
                uploadButtonText: t('common:editor.uploadImage'),
                resetButtonText: t('common:action.reset'),
              }}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="attachments"
          render={({ field: { value, onChange } }) => (
            <FileUploader
              label={t('supportRequest.attachments')}
              value={{
                attachments: [],
                attachmentsToUpload: value ?? [],
              }}
              onChange={(value) => {
                onChange(value.attachmentsToUpload);
              }}
              progress={uploadProgress}
            />
          )}
        />

        <Spacer mb="$medium" />

        <PCheckboxWrapper label={t('supportRequest.refuseTechnicalDetails')}>
          <input type="checkbox" {...register('refuseTechnicalDetails')} />
        </PCheckboxWrapper>

        <ActionGroup>
          <PButton
            variant="secondary"
            type="button"
            role="button"
            disabled={isSubmitting}
            onClick={handleDismiss}
          >
            {t('common:action.cancel')}
          </PButton>

          <PButton
            type="submit"
            role="button"
            variant="primary"
            loading={isSubmitting}
          >
            {t('common:action.save')}
          </PButton>
        </ActionGroup>
      </form>
    </Modal>
  );
};

const LinkedCell = ({
  id,
  unread,
  value,
}: {
  id?: string;
  unread?: boolean;
  value: React.ReactNode;
}) => (
  <PLinkPure icon="none" stretch>
    <Link to={`/support/${id}`}>
      {unread ? <strong>{value}</strong> : value}
    </Link>
  </PLinkPure>
);
