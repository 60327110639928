import {
  focusEditor,
  someNode,
  toggleNodeType,
  useEventPlateId,
  usePlateEditorState,
  Value,
  WithPlatePlugin,
} from '@udecode/plate-common';
import { ToolbarButton } from './ToolbarButton';

export const ToolbarBlockButton = <V extends Value>({
  id,
  icon: Icon,
  type,
  inactiveType,
  disabledOnActive,
  onClick,
}: {
  id?: string;
  icon: React.ComponentType;
  type: WithPlatePlugin<V>['type'];
  inactiveType?: string;
  disabledOnActive?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const active = !!editor?.selection && someNode(editor, { match: { type } });

  return (
    <ToolbarButton
      active={active}
      disabled={disabledOnActive && active}
      onClick={
        onClick
          ? onClick
          : (e) => {
              e.preventDefault();
              e.stopPropagation();

              toggleNodeType(editor, { activeType: type, inactiveType });
              focusEditor(editor);
            }
      }
    >
      <Icon />
    </ToolbarButton>
  );
};
