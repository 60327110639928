import { styled } from '../stitches.config';

const Wrapper = styled('div', {
  backgroundColor: '$darkBackgroundBase',
  paddingTop: '$xLarge',
  paddingBottom: '$xLarge',
});

const StyledFooter = styled('footer', {
  flexShrink: 0,
});

export const Footer = ({ children }: { children: React.ReactNode }) => (
  <StyledFooter>
    <Wrapper>{children}</Wrapper>
  </StyledFooter>
);
