import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { AnalyticsDashboard } from '../../../pages';

export const Route = createFileRoute('/admin/analytics/_auth/')({
  component: () => <AnalyticsDashboard />,
  beforeLoad: () => {
    return { breadcrumb: () => <Trans i18nKey="admin.analytics.headline" /> };
  },
});
