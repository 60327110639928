import { Auth } from '@aws-amplify/auth';
import { gql, GraphQLClient, Variables } from 'graphql-request';
import { version } from '../../package.json';

const endpoint = process.env.REACT_APP_GRAPHQL_URL ?? '/graphql';
const graphQLClient = new GraphQLClient(endpoint);

/**
 * Returns current Cognito Token
 */
const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    return `Bearer ${session.getIdToken().getJwtToken()}`;
  } catch {
    console.error('No token found');
    return '';
  }
};

/**
 * Send a GraphQL Document to the GraphQL server for exectuion.
 */
const graphQLRequest =
  <TData, TVariables extends Variables>(
    query: string,
    variables?: TVariables,
    options?: HeadersInit,
  ): (() => Promise<TData>) =>
  async () => {
    const headers: Headers = new Headers(options);

    if (process.env.NODE_ENV === 'development') {
      const { getPpnuidHeader } = await import('@porsche-kado/devtools');
      const ppnuidHeader = getPpnuidHeader();
      if (ppnuidHeader) {
        const [key, value] = Object.entries(ppnuidHeader)[0];
        headers.set(key, value);
      }
    }

    const token = await getIdToken();

    return graphQLClient.request<TData>(query, variables, {
      'X-App-Name': 'dashboard',
      'X-App-Version': version,
      authorization: token,
      ...Object.fromEntries(headers),
    });
  };

export { getIdToken, gql, graphQLRequest as request };
