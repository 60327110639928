import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';

export const Route = createFileRoute('/news')({
  beforeLoad: () => {
    return {
      breadcrumb: () => <Trans i18nKey="featureArticles" />,
    };
  },
});
