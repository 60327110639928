import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useId,
  useInteractions,
  useRole,
  type MiddlewareState,
  type Placement,
} from '@floating-ui/react';
import { dropShadowMediumStyle } from '@porsche-design-system/components-react/styles';
import { Fragment } from 'react';
import { styled } from '../stitches.config';

const Content = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$medium',
  zIndex: '$popover',
  ...dropShadowMediumStyle,
});

const Trigger = styled('span', {
  display: 'inline-block',
});

const Spacer = styled('div', {
  padding: '$large',
  whiteSpace: 'nowrap',

  variants: {
    spacing: {
      large: {
        padding: '$medium $large',
      },
      normal: {
        padding: '$small $medium',
      },
    },
  },
});

export const Popover = ({
  children,
  offset: offsetAxis = [0, 0],
  onChange,
  open,
  placement,
  spacing = 'normal',
  style,
  target,
  sameWidthAsTarget,
  minWidth,
  maxWidth,
  withPortal,
}: {
  children: React.ReactNode;
  offset?: [number, number];
  onChange: (open: boolean) => void;
  open: boolean;
  placement?: Placement;
  spacing?: 'normal' | 'large';
  style?: React.CSSProperties;
  target: React.ReactNode;
  sameWidthAsTarget?: boolean;
  minWidth?: number;
  maxWidth?: number;
  withPortal?: boolean;
}) => {
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: onChange,
    placement,
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: offsetAxis[1], crossAxis: offsetAxis[0] }),
      flip({
        fallbackAxisSideDirection: 'end',
      }),
      shift(),
      sameWidthAsTarget &&
        size({
          apply(state: MiddlewareState) {
            state.elements.floating.style.width = `${state.rects.reference.width}px`;
          },
        }),
      typeof minWidth === 'number' &&
        size({
          apply(state: MiddlewareState) {
            state.elements.floating.style.minWidth = `${minWidth}px`;
          },
        }),
      typeof maxWidth === 'number' &&
        size({
          apply(state: MiddlewareState) {
            state.elements.floating.style.maxWidth = `${maxWidth}px`;
          },
        }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    focus,
    dismiss,
    role,
  ]);

  const headingId = useId();

  const MaybePortal = withPortal ? FloatingPortal : Fragment;

  return (
    <>
      <Trigger ref={refs.setReference} style={style} {...getReferenceProps()}>
        {target}
      </Trigger>

      {open && (
        <MaybePortal>
          <FloatingFocusManager context={context} modal={false}>
            <Content
              aria-labelledby={headingId}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <Spacer spacing={spacing}>{children}</Spacer>
            </Content>
          </FloatingFocusManager>
        </MaybePortal>
      )}
    </>
  );
};
