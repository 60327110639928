import { Divider } from '@porsche-kado/ui';
import { Fragment } from 'react';
import { Attachment } from '../../graphql';
import { Comment } from './Comment';

export const Comments = ({
  comments,
}: {
  comments: {
    id: string;
    author: { id: number; name: string };
    createdAt: string;
    text: string;
    attachments?: Attachment[] | null;
  }[];
}) => (
  <>
    {comments.map((comment) => (
      <Fragment key={comment.id}>
        <Comment comment={comment} />
        <Divider />
      </Fragment>
    ))}
  </>
);
