import {
  PButton,
  PInlineNotification,
  PLinkPure,
} from '@porsche-design-system/components-react';
import { focusEditor } from '@udecode/plate-common';
import {
  PlateRenderElementProps,
  useEventPlateId,
  usePlateEditorState,
} from '@udecode/plate-core';
import {
  Caption,
  ELEMENT_IMAGE,
  Image,
  Media,
  TMediaElement,
  insertImage,
} from '@udecode/plate-media';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageIcon from '../../../../icons/image.svg?react';
import { styled } from '../../../stitches.config';
import { ActionGroup } from '../../ActionGroup';
import { DropZoneFiles, Dropzone, DropzoneInner } from '../../Dropzone';
import { Modal } from '../../Modal';
import { Spacer } from '../../Spacer';
import { ToolbarBlockButton } from '../ToolbarBlockButton';

const DownloadOverlay = styled('div', {
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, .3)',
  left: 0,
  right: 0,
  bottom: 0,
  padding: '$medium',
  boxSizing: 'border-box',
  transition: '.3s ease',
  opacity: 0,
});

const Figure = styled('figure', {
  position: 'relative',
  textAlign: 'center',

  [`&:hover ${DownloadOverlay}`]: {
    opacity: 1,
  },

  '&:focus': {
    border: '1px solid $contrastLow',
  },
  // styled cannot have normal properties and references to other styled components same
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

const CaptionWrapper = styled('div', {
  '& textarea': {
    border: 'none',
    width: '100%',
    resize: 'none',
    outline: 'none',
    fontFamily: 'unset',
    textAlign: 'center',
    '&::placeholder': {
      color: '$contrastMedium',
    },
  },
});

const Img = ({
  nodeProps,
  attributes,
  element,
  editor,
  captionPlaceholder,
  includeImages,
  imagePlaceholder,
  includeImageDownloadLink,
  children,
}: PlateRenderElementProps & {
  element: TMediaElement;
  captionPlaceholder?: string;
  includeImages?: boolean;
  includeImageDownloadLink?: boolean;
  imagePlaceholder?: string | ((url: string) => string);
}) => {
  if (!includeImages) {
    const placeholder =
      typeof imagePlaceholder === 'function'
        ? imagePlaceholder(element.url)
        : imagePlaceholder;

    return <>{`<<${element.caption?.[0].text || placeholder || 'Image'}>>`}</>;
  }

  return (
    <>
      <Media.Root editor={editor} attributes={attributes} element={element}>
        <Figure contentEditable={false} tabIndex="0">
          <Image
            {...nodeProps}
            style={{ maxWidth: '100%', display: 'inline-block' }}
          />
          <CaptionWrapper>
            <Caption.Root style={{ textAlign: 'center' }}>
              <Caption.Textarea placeholder={captionPlaceholder} />
            </Caption.Root>
          </CaptionWrapper>
          {includeImageDownloadLink && (
            <DownloadOverlay>
              <PLinkPure
                className="download"
                icon="download"
                href={element.url}
                theme="dark"
                download={element.url.substring(
                  element.url.lastIndexOf('/') + 1,
                )}
              />
            </DownloadOverlay>
          )}
        </Figure>
        {children}
      </Media.Root>
    </>
  );
};

export const imageComponents = {
  [ELEMENT_IMAGE]: Img,
};

export const ImageToolbarButtons = ({
  id,
  onUploadImage,
  onCancelImageUpload,
  allowedImageTypes,
  i18n,
}: {
  id?: string;
  onUploadImage: (
    fileOrData: File | string,
    opt: { onProgress?: (value: number) => void },
  ) => Promise<string>;
  onCancelImageUpload?: () => void;
  allowedImageTypes?: { type: string; extension: string }[];
  i18n?: {
    imageUploadHeading?: string;
    uploadButtonText?: string;
    resetButtonText?: string;
    uploadErrorHeading?: string;
    uploadErrorDescription?: string;
    dropzoneDescription?: string;
    dragDescription?: string;
  };
}) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
  const [uploadImageProgress, setUploadImageProgress] = useState(0);

  return (
    <>
      <ToolbarBlockButton
        id={id}
        icon={ImageIcon}
        type={ELEMENT_IMAGE}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setImageUploadModalOpen(true);
        }}
        disabledOnActive
      />
      <ImageUploadModal
        open={imageUploadModalOpen}
        onClose={() => {
          if (onCancelImageUpload) {
            onCancelImageUpload();
          }
          setImageUploadModalOpen(false);
        }}
        onSubmit={(file) =>
          onUploadImage(file, { onProgress: setUploadImageProgress })
            .then((url) => {
              setImageUploadModalOpen(false);
              setUploadImageProgress(0);

              insertImage(editor, url);
              focusEditor(editor);
            })
            .catch(() => {
              setImageUploadModalOpen(false);
              setUploadImageProgress(0);

              // TODO: maybe display this error
            })
        }
        uploadProgress={uploadImageProgress}
        allowedTypes={allowedImageTypes ?? []}
        heading={i18n?.imageUploadHeading ?? 'UPLOAD_IMAGE_HEADING'}
        uploadButtonText={i18n?.uploadButtonText}
        resetButtonText={i18n?.resetButtonText}
        uploadErrorHeading={i18n?.uploadErrorHeading ?? 'Upload failed'}
        uploadErrorDescription={i18n?.uploadErrorDescription}
        dropzoneDescription={
          i18n?.dropzoneDescription ?? 'UPLOAD_IMAGE_DROPZONE'
        }
        dragDescription={i18n?.dragDescription ?? 'UPLOAD_IMAGE_DRAGGING'}
      />
    </>
  );
};

const ImageUploadModal = ({
  open,
  onClose,
  onSubmit,
  uploadProgress,
  heading,
  dropzoneDescription,
  dragDescription,
  uploadButtonText,
  resetButtonText,
  uploadErrorHeading,
  uploadErrorDescription,
  allowedTypes,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
  uploadProgress: number;
  heading: string;
  dropzoneDescription: string;
  dragDescription: string;
  uploadButtonText: React.ReactNode;
  resetButtonText: React.ReactNode;
  uploadErrorHeading: string;
  uploadErrorDescription: React.ReactNode;
  allowedTypes: { type: string; extension: string }[];
}) => {
  const [file, setFile] = useState<File | undefined>();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
  }, []);

  const onDropRejected = useCallback(() => {
    setIsErrorVisible(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxFiles: 1,
    multiple: false,
    useFsAccessApi: false,
    accept: allowedTypes.reduce(
      (memo, { type, extension }) => ({ ...memo, [type]: [`.${extension}`] }),
      {},
    ),
  });

  useEffect(() => {
    if (!open) {
      setFile(undefined);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onDismiss={onClose}
      disableBackdropClick
      aria={{ 'aria-label': heading }}
    >
      <Modal.Header>{heading}</Modal.Header>

      {isErrorVisible && (
        <>
          <PInlineNotification
            state="error"
            heading={uploadErrorHeading}
            onDismiss={() => setIsErrorVisible(false)}
          >
            {uploadErrorDescription}
          </PInlineNotification>

          <Spacer mb="$small" />
        </>
      )}
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <DropzoneInner
          isDragActive={isDragActive}
          description={dropzoneDescription}
          dragDescription={dragDescription}
        />
      </Dropzone>
      {file && (
        <DropZoneFiles
          files={[
            {
              id: file.name,
              file,
            },
          ]}
          progress={[uploadProgress]}
          onDelete={() => {
            setFile(undefined);
          }}
        />
      )}

      <ActionGroup>
        <PButton
          type="button"
          variant="secondary"
          icon="close"
          onClick={() => onClose()}
        >
          {resetButtonText}
        </PButton>
        <PButton
          type="button"
          disabled={!file}
          onClick={() => file && onSubmit(file)}
        >
          {uploadButtonText}
        </PButton>
      </ActionGroup>
    </Modal>
  );
};
