import dayjs from 'dayjs';
import { usePersonContext } from '../../context';

export const DateTimeOutput = ({ date }: { date: Date | string }) => {
  const { state: personState } = usePersonContext();
  const dateFormat = personState.settings?.dateFormat || 'YYYY/MM/DD';
  const timeFormat = personState.settings?.is24HourFormat ? 'HH:mm' : 'hh:mm A';

  return <>{dayjs(date).format(`${dateFormat} ${timeFormat}`)}</>;
};
