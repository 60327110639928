import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { Announcement } from '../../../pages';

export const Route = createFileRoute('/admin/announcements/_auth/')({
  component: () => <Announcement />,
  beforeLoad: () => {
    return { breadcrumb: () => <Trans i18nKey="admin.announcement.title" /> };
  },
});
