import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';

export const Route = createFileRoute('/support')({
  beforeLoad: () => {
    return {
      breadcrumb: () => <Trans i18nKey="supportRequest.headline" />,
    };
  },
});
