import { PLinkPure, PText } from '@porsche-design-system/components-react';
import { styled } from '@porsche-kado/ui';

const Card = styled('div', {
  backgroundColor: '$backgroundSurface',
  padding: '$medium',
  marginBottom: '$medium',
  maxWidth: '380px',
});

const Header = styled('div', {
  paddingBottom: '$medium',
});

const Link = styled(PLinkPure, {
  width: '100%',
});

export const ContactCard = ({
  name,
  mail,
  role,
  phone,
}: {
  name: string;
  mail: string;
  role?: string;
  phone?: string;
}) => (
  <Card>
    <Header>
      <PText weight="bold" size="medium">
        {name}
      </PText>
      {role && <PText>{role}</PText>}
    </Header>
    <Link href={`mailto:${mail}`} icon="email">
      {mail}
    </Link>
    {phone && (
      <Link href={`tel:${phone}`} icon="phone">
        {phone}
      </Link>
    )}
  </Card>
);
